<template>
  <Modal @close="close()">
    <form>
      <div class="form-content">
        <header>
          <Dropdown
            placeholder="Клиенти..."
            icon="search.svg"
            class="client-search"
            :dataArray="this.clients"
            :formatter="(client) => client.name"
            :typing="true"
            :invalid="invalid.client"
            @input="invalid.client = false"
            @search="getFilterClients"
            v-model="contract.client"
          />

          <section>
            <Datepicker
              icon="Calendar.svg"
              class="datepicker reportDatepicker"
              :invalid="invalid.fromDate"
              :disabledDates="disabledDatesFrom"
              @input="invalid.fromDate = false"
              v-model="contract.fromDate"
            />
            <Datepicker
              icon="Calendar.svg"
              class="datepicker reportDatepicker"
              :invalid="invalid.toDate"
               :disabledDates="disabledDatesTo"
              @input="invalid.toDate = false"
              v-model="contract.toDate"
            />

            <Button @click="close" class="close"
              ><img class="icons" src="@/assets/icons/close.svg" alt="close"
            /></Button>
          </section>
        </header>

        <hr class="break" />

        <main class="inputs--wrapper">
          <section class="form-inputs">
            <div class="row">
              <Input
                placeholder="Номер на договор"
                type="text"
                icon="contract-number.svg"
                :invalid="invalid.contractNumber"
                @input="invalid.contractNumber = false"
                v-model="contract.contractNumber"
              />
            </div>
            <div class="row">
              <Input
                placeholder="Наименование на договор"
                type="text"
                icon="contract-info.svg"
                :invalid="invalid.contractName"
                @input="invalid.contractName = false"
                v-model="contract.contractName"
              />
            </div>
            <div class="row">
              <InputButtons icon="contract-type.svg">
                <main>
                  <Button
                    :class="{
                      maintenance: contract.contractType === 'maintenance',
                    }"
                    @click="selectContract('maintenance')"
                  >
                    <img
                      src="@/assets/icons/maintenance.svg"
                      alt="maintenance"
                      class="icons"
                    />
                    <span class="type">ПОДДРЪЖКА</span>
                  </Button>
                </main>
                <main>
                  <Button
                    :class="{
                      development: contract.contractType === 'development',
                    }"
                    @click="selectContract('development')"
                  >
                    <img
                      src="@/assets/icons/development.svg"
                      alt="development"
                      class="icons"
                    />
                    <span class="type">РАЗРАБОТКА</span>
                  </Button>
                </main>
                <main>
                  <Button
                    :class="{ question: contract.contractType === 'question' }"
                    @click="selectContract('question')"
                  >
                    <img
                      src="@/assets/icons/question.svg"
                      alt="question"
                      class="icons"
                    />
                    <span class="type">ЗАПИТВАНЕ</span>
                  </Button>
                </main>
              </InputButtons>
            </div>
            <div class="row">
              <InputButtons
                icon="contract-hours.svg"
                :invalid="invalid.time"
                v-if="contract.contractType === 'maintenance'"
              >
                <div>
                  <p>Включени часове</p>
                  <BoxInput
                    :input="(invalid.time = false)"
                    v-model="contract.hoursPod.hours"
                  />
                </div>
                <div>
                  <p>Цена на вкл. час</p>
                  <BoxInput
                    :input="(invalid.time = false)"
                    v-model="contract.hoursPod.priceForHour"
                  />
                </div>
                <div>
                  <p>Цена допълнителен час</p>
                  <BoxInput
                    :input="(invalid.time = false)"
                    v-model="contract.hoursPod.priceExtraHour"
                  />
                </div>
              </InputButtons>
            </div>
            <div class="row">
              <InputButtons
                icon="contract-hours.svg"
                :invalid="invalid.time"
                v-if="contract.contractType === 'development'"
              >
                <div>
                  <p>Общо часове</p>
                  <BoxInput
                    :input="(invalid.time = false)"
                    v-model="contract.hoursRaz.hours"
                  />
                </div>
                <div>
                  <p>Общ бюджет</p>
                  <BoxInput
                    :input="(invalid.time = false)"
                    v-model="contract.hoursRaz.budjet"
                  />
                </div>
                <div>
                  <p>Средна цена на час</p>
                  <p
                    v-if="
                      contract.hoursRaz.hours > 0 &&
                      contract.hoursRaz.budjet > 0
                    "
                    class="input"
                  >
                    {{
                      (contract.hoursRaz.avgPriceHour = (
                        contract.hoursRaz.budjet / contract.hoursRaz.hours
                      ).toFixed(2))
                    }}
                  </p>
                  <p class="input" v-else>
                    {{ (contract.hoursRaz.avgPriceHour = 0) }}
                  </p>
                </div>
              </InputButtons>
            </div>
            <div class="row">
              <InputButtons
                icon="contract-hours.svg"
                :invalid="invalid.time"
                v-if="contract.contractType === 'question'"
              >
                <div>
                  <p>Цена на час</p>
                  <BoxInput
                    @input="invalid.time = false"
                    v-model="contract.hoursZap.priceForHour"
                  />
                </div>
              </InputButtons>
            </div>
            <div class="row">
              <InputButtons
                icon="contract-data.svg"
                :invalid="invalid.hosting"
                v-if="contract.contractType !== 'development'"
              >
                <div>
                  <p>Хостинг цена</p>
                  <BoxInput
                    @input="invalid.hosting = false"
                    v-model="contract.hosting.price"
                  />
                </div>
                <div>
                  <p>Хостинг пространство</p>
                  <BoxInput
                    @input="invalid.hosting = false"
                    v-model="contract.hosting.hostingSpace"
                  />
                </div>
              </InputButtons>
            </div>
            <div class="row">
              <InputButtons
                icon="contract-travel.svg"
                :invalid="invalid.distance"
              >
                <div>
                  <p>Пропътуване</p>
                  <BoxInput
                    @input="invalid.distance = false"
                    v-model="contract.travel.distance"
                  />
                </div>
                <div>
                  <p>Цена на км.</p>
                  <BoxInput
                    @input="invalid.distance = false"
                    v-model="contract.travel.pricePerKm"
                  />
                </div>

                <div>
                  <img
                    src="@/assets/icons/greenzone.svg"
                    alt="greenzone"
                    class="new"
                  />
                  <BoxInput v-model="contract.travel.greenZone" />
                </div>

                <div>
                  <img
                    src="@/assets/icons/bluezone.svg"
                    alt="bluezone"
                    class="new"
                  />
                  <BoxInput v-model="contract.travel.blueZone" />
                </div>
              </InputButtons>
            </div>
            <div class="row">
              <Input
                placeholder="Забележка"
                type="text"
                icon="contract-notes.svg"
                :maxlength="500"
                v-model="contract.note"
              />
            </div>
            <div class="row">
              <Input
                placeholder="Е-поща за нотификации"
                type="text"
                icon="contract-mails.svg"
                :invalid="invalid.mailTo"
                @input="invalid.mailTo = false"
                v-model="contract.mailTo"
              />
            </div>
            <div class="row">
              <Dropdown
                placeholder="Достъп"
                type="text"
                icon="contract-users.svg"
                :multiselect="true"
                :dataArray="this.users"
                :formatter="(user) => user.name"
                :invalid="invalid.access"
                :up="true"
                @input="invalid.access = false"
                v-model="contract.access"
                identifier="_id"
              />
            </div>
          </section>
        </main>
        <p class="row error">
          <span v-if="error">{{ error }}</span>
        </p>
      </div>
      <Button @click="save">ЗАПИШИ</Button>
    </form>
  </Modal>
</template>

<script>
import Input from "@/components/Input";
import BoxInput from "@/components/BoxInput";
import InputButtons from "@/components/InputButtons";
import Button from "@/components/Button";
import Dropdown from "@/components/Dropdown";
import Datepicker from "@/components/Datepicker";
import Modal from "@/components/Modal";
export default {
  props: ["contractId"],
  components: {
    Input,
    BoxInput,
    InputButtons,
    Button,
    Dropdown,
    Datepicker,
    Modal,
  },
  computed:{
    disabledDatesFrom() {
      return { from: new Date(this.contract.toDate) };
    },
    disabledDatesTo() {
      return { to: new Date(this.contract.fromDate) };
    },
  },
  data() {
    return {
      contract: {
        client: "",
        contractNumber: "",
        fromDate: "",
        toDate: "",
        contractName: "",
        contractType: "maintenance",

        hoursPod: {
          hours: null,
          priceForHour: null,
          priceExtraHour: null,
        },
        hoursRaz: {
          hours: null,
          budjet: null,
          avgPriceHour: null,
        },
        hoursZap: {
          priceForHour: null,
        },
        hosting: {
          price: null,
          hostingSpace: null,
        },
        travel: {
          distance: null,
          pricePerKm: null,
          greenZone: null,
          blueZone: null,
        },
        note: "",
        mailTo: "",
        access: [
          {
            _id: "",
            name: "",
          },
        ],
      },
      error: "",
      loading: false,
      invalid: {
        invalid: false,
      },
      users: [],
      clients: [],
      openedDropdown: false,
      searchCompanies: "",
    };
  },
  watch: {
    $route: [
      {
        handler: "getUsers",
        immediate: true,
      },
      {
        handler: "getClients",
        immediate: true,
      },
      {
        handler: "getContract",
        immediate: true,
      },
    ],
  },
  methods: {
    selectContract(type) {
      this.contract.contractType = type;
      if (type === "maintenance") {
        this.contract.hoursRaz.hours = null;
        this.contract.hoursRaz.budjet = null;

        this.contract.hoursZap.priceForHour = null;
      } else if (type === "development") {
        this.contract.hoursPod.hours = null;
        this.contract.hoursPod.priceForHour = null;
        this.contract.hoursPod.priceExtraHour = null;

        this.contract.hoursZap.priceForHour = null;
      } else if (type === "question") {
        this.contract.hoursPod.hours = null;
        this.contract.hoursPod.priceForHour = null;
        this.contract.hoursPod.priceExtraHour = null;

        this.contract.hoursRaz.hours = null;
        this.contract.hoursRaz.budjet = null;
      }
    },
    getFilterClients(search) {
      this.clients = [];
      let url = "/clients";

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.clients = res.data.results;
      });
    },

    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.contract.client) {
        this.error = "Не сте въвели клиент";
        this.invalid.client = true;
        return false;
      } else if (!this.contract.fromDate) {
        this.invalid.fromDate = true;
        this.error = "Не сте въвели дата";
        return false;
      } else if (!this.contract.contractNumber) {
        this.error = "Не сте въвели Номер на договор";
        this.invalid.contractNumber = true;
        return false;
      } else if (!this.contract.contractName) {
        this.invalid.contractName = true;
        this.error = "Не сте въвели име на договор";
        return false;
      } else if (
        (this.contract.hoursPod.hours === null ||
          this.contract.hoursPod.priceForHour === null ||
          this.contract.hoursPod.priceExtraHour === null) &&
        this.contract.contractType === "maintenance"
      ) {
        this.invalid.time = true;
        this.error = "Не сте въвели часове";
        return false;
      } else if (
        !this.contract.hoursZap.priceForHour &&
        this.contract.contractType === "question"
      ) {
        this.invalid.time = true;
        this.error = "Не сте въвели часове";
        return false;
      } else if (
        (this.contract.hosting.price === null ||
          this.contract.hosting.hostingSpace === null) &&
        this.contract.contractType !== "development"
      ) {
        this.invalid.hosting = true;
        this.error = "Не сте въвели хостинг";
        return false;
      } else if (
        this.contract.travel.distance === null ||
        this.contract.travel.pricePerKm === null ||
        this.contract.travel.greenZone === null ||
        this.contract.travel.blueZone === null
      ) {
        this.invalid.distance = true;
        this.error = "Не сте въвели пропътуване";
        return false;
      } else if (!this.contract.mailTo) {
        this.invalid.mailTo = true;
        this.error = "Не сте въвели е-мейл";
        return false;
      } else if (!this.contract.access[0]._id) {
        this.invalid.access = true;
        this.error = "Не сте въвели служители за достъп до договора";
        return false;
      }

      return true;
    },
    save() {
      this.error = "";
      if (this.isValid()) {
        let access = this.contract.access.filter((x) => !x.notpresent);
        this.contract.access = access;

        if (this.contractId) {
          this.$apiService
            .put("/contracts", this.contract)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
        if (!this.contractId) {
          this.$apiService
            .post("/contracts", this.contract)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
      }
    },
    getContract() {
      if (this.contractId) {
        this.$apiService.get(`/contracts/${this.contractId}`).then((res) => {
          this.contract = res.data;
        });
      }
    },
    getUsers() {
      this.loading = true;
      this.users = [];
      let url = `/users?active=true`;

      this.$apiService
        .get(url)
        .then((res) => {
          this.users = res.data.results;
        })
        .then(() => {
          this.loading = false;
        });
    },
    getClients() {
      this.loading = true;
      this.clients = [];
      let url = `/clients`;

      this.$apiService
        .get(url)
        .then((res) => {
          this.clients = res.data.results;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

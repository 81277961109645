<template>
  <div
    class="datepicker-container"
    @click.self="isDatePickerOpen = true"
    :class="{ 'datepicker-container--invalid': invalid }"
  >
    <div class="selected-date" v-if="value && !month">
      {{ value | formatDate }}
    </div>

    <div class="selected-date" v-if="value && month">
      {{ value | formatMonth }}
    </div>

    <div class="selected-date" v-if="!value && !month">ДД/ММ/ГГ</div>
    <div class="selected-date" v-if="!value && month">ММ/ГГ</div>

    <img
      @click.self="isDatePickerOpen = true"
      class="icons"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="icon"
    />

    <Datepicker
      class="datepicker-component"
      v-if="isDatePickerOpen"
      :inline="true"
      :full-month-name="true"
      :language="bg"
      :maximumView="month ? 'year' : 'day'"
      :minimumView="month ? 'month' : 'day'"
      :monday-first="true"
      :required="true"
      :value="value"
      @selected="selected"
      v-click-outside="close"
      :disabled-dates="disabledDates"
    />
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { bg } from "vuejs-datepicker/dist/locale";
import vClickOutside from "v-click-outside";
export default {
  components: {
    Datepicker,
  },
  props: ["value", "icon", "month", "disabledDates", "invalid"],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      bg,
      isDatePickerOpen: false,
    };
  },
  methods: {
    selected(d) {
      this.$emit("input", d);
      this.close();
    },
    close() {
      this.isDatePickerOpen = false;
    },
  },
};
</script>

<style lang="scss">
// @import "@/scss/base.scss";
.datepicker-container {
  background-color: $input;
  border: 1px solid #707070;
  border-radius: $borderRadius;
  cursor: pointer;
  display: flex;
  height: 31px;
  position: relative;
  padding: 0 10px;
  width: fit-content;
  @include tr();
  @include noselect();

  &.datepicker-container--invalid {
    border: 1px solid $mainError;
  }

  .selected-date {
    align-self: center;
    font-size: $px12;
    font-weight: 600;
    pointer-events: none;
    margin-right: 5px;
    width: max-content;
  }
  .icons {
    object-fit: scale-down;
  }
  .datepicker-component {
    border: 1px solid rgba($color: $input, $alpha: 0.46);
    border-radius: 2px;
    box-shadow: 0px 0px 8px #00000040;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 1px);
    z-index: 2;
  }
  &.reportDatepicker {
    .datepicker-component {
      right: 0px;
    }
  }
  .vdp-datepicker__calendar {
    padding: 3px;
    header {
      span {
        @include transition($transition);
      }
      .month__year_btn {
        border-radius: 4px;
      }
      .prev,
      .next {
        border-radius: 4px;
        color: transparent;
        text-indent: initial;

        &.disabled::after {
          border: 0;
          opacity: 0.1;
        }
      }

      .prev:not(.disabled):hover,
      .next:not(.disabled):hover,
      .up:not(.disabled):hover {
        background-color: rgba($color: $mainColor, $alpha: 0.2);
      }
    }
    .cell {
      border-radius: 4px;
      border: 1px solid transparent;
      @include tr();
      &.selected {
        background-color: $mainColor;
        color: #ffffff;
        &:hover {
          background-color: rgba($color: $mainColor, $alpha: 0.5) !important;
        }
      }
      &:not(.blank):not(.disabled).day:hover,
      &:not(.blank):not(.disabled).month:hover,
      &:not(.blank):not(.disabled).year:hover {
        border: 1px solid transparent;
        background-color: rgba($color: $mainColor, $alpha: 0.2);
      }
    }
  }
}
@media all and (max-width:$s) {
  .datepicker-container {
    height: fit-content;
    padding: 3px;
    .selected-date {
      display: none;
    }
  }
}
</style>

<template>
  <div class="table__container">
    <section class="search-wrapper">
      <Input
        placeholder="ТЪРСЕНЕ"
        type="text"
        icon="search.svg"
        v-model="search"
        class="search"
      />
    </section>

    <transition name="table-transition" appear>
      <main class="main_content">
        <section class="navs">
          <section>
            <NavToggle
              v-for="n in navs"
              :key="n.name"
              @click="select(n.name), (contractType = n.type)"
              :name="n.name"
              :active="n.selected"
            />
          </section>
          <!-- <span class="cdots-expenses" title="Отчети за сметка на cDots">
            <svg
              @click="cdotsExpenses = !cdotsExpenses"
              :class="{ active: cdotsExpenses }"
              xmlns="http://www.w3.org/2000/svg"
              width="32.407"
              height="32.407"
              viewBox="0 0 32.407 32.407"
            >
              <path
                id="Subtraction_1"
                data-name="Subtraction 1"
                d="M16.2,32.407A16.2,16.2,0,0,1,4.746,4.746,16.2,16.2,0,1,1,27.661,27.661,16.1,16.1,0,0,1,16.2,32.407Zm-2.456-8.216a1.117,1.117,0,1,0,1.117,1.118A1.119,1.119,0,0,0,13.747,24.191Zm-5.644,0A1.117,1.117,0,1,0,9.22,25.309,1.12,1.12,0,0,0,8.1,24.191Zm11.37.219a.9.9,0,1,0,.9.9A.9.9,0,0,0,19.473,24.41Zm-5.726-6.862a2.036,2.036,0,1,0,2.037,2.037A2.04,2.04,0,0,0,13.747,17.548Zm5.726.752a1.285,1.285,0,1,0,1.284,1.284A1.286,1.286,0,0,0,19.473,18.3ZM8.1,18.468A1.117,1.117,0,1,0,9.22,19.585,1.12,1.12,0,0,0,8.1,18.468Zm17.105.108a1.009,1.009,0,1,0,1.01,1.009A1.01,1.01,0,0,0,25.209,18.576ZM19.473,11.8a2.037,2.037,0,1,0,2.038,2.037A2.039,2.039,0,0,0,19.473,11.8Zm-5.726,0a2.037,2.037,0,1,0,2.037,2.037A2.04,2.04,0,0,0,13.747,11.8ZM8.1,11.8a2.037,2.037,0,1,0,2.037,2.037A2.039,2.039,0,0,0,8.1,11.8Zm17.108.905a1.13,1.13,0,1,0,1.129,1.131A1.133,1.133,0,0,0,25.212,12.7ZM13.747,5.981a2.037,2.037,0,1,0,2.037,2.038A2.04,2.04,0,0,0,13.747,5.981ZM8.1,6.489a1.531,1.531,0,1,0,1.532,1.53A1.533,1.533,0,0,0,8.1,6.489Zm11.37.81a.72.72,0,1,0,.72.72A.721.721,0,0,0,19.473,7.3Z"
                fill="#B7B7B7"
              />
            </svg>
          </span> -->
        </section>

        <div class="content">
          <MonthlyClient
            v-if="navs[0].selected || navs[1].selected"
            :contractType="contractType"
            :clients="clients"
            @getClients="getClients"
          />
            <!-- :cdotsExpenses="cdotsExpenses" -->
          <Monthly
            v-if="navs[2].selected || navs[3].selected"
            :contractType="contractType"
            :clients="clients"
            @getClients="getClients"
          />
            <!-- :cdotsExpenses="cdotsExpenses" -->
          <Turnover
            v-if="navs[4].selected || navs[5].selected"
            :contractType="contractType"
            :clients="clients"
            @getClients="getClients"
          />
            <!-- :cdotsExpenses="cdotsExpenses" -->
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import Input from "@/components/Input";
import Monthly from "@/components/inquiry/Monthly";
import NavToggle from "@/components/NavToggle";
import MonthlyClient from "@/components/inquiry/MonthlyClient";
import Turnover from "@/components/inquiry/Turnover";
export default {
  components: { Input, Monthly, MonthlyClient, Turnover, NavToggle },
  data() {
    return {
      navs: [
        {
          name: "МЕСЕЧНА ПО КЛИЕНТ ПОДДРЪЖКА",
          type: "maintenance",
          selected: true,
        },
        {
          name: "МЕСЕЧНА ПО КЛИЕНТ РАЗРАБОТКА",
          type: "development",
          selected: false,
        },
        { name: "МЕСЕЧНА ПО ПОДДРЪЖКА", type: "maintenance", selected: false },
        { name: "ПРОЕКТИ С РАЗРАБОТКА", type: "development", selected: false },
        {
          name: "ОБОРОТИ КЛИЕНТИ - ПОДДРЪЖКА",
          type: "maintenance",
          selected: false,
        },
        {
          name: "ОБОРОТИ КЛИЕНТИ – РАЗРАБОТКА",
          type: "development",
          selected: false,
        },
      ],
      contractType: "maintenance",
      search: "",
      clients: [],
      // cdotsExpenses: false,
    };
  },
  methods: {
    select(name) {
      for (let i = 0; i < this.navs.length; i++) {
        if (this.navs[i].name !== name) {
          this.navs[i].selected = false;
        }
      }

      this.toggleSelection(name);
    },
    toggleSelection(name) {
      const stepsItem = this.navs.find((item) => item.name === name);
      if (stepsItem) {
        stepsItem.selected = true;
        this.contractType = stepsItem.type;
        this.getClients();
      }
    },
    getClients(search) {
      this.loading = true;
      this.clients = [];

      let url = `/contracts?contractType=${this.contractType}`;

      // only Месечна по клиент has only active contracts
      if (this.navs[0].selected || this.navs[1].selected) {
        url += `&active=${true}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          //gets unique clients by id
          this.uniqueObjArray = [
            ...new Map(
              res.data.results.map((item) => [item["client"]._id, item])
            ).values(),
          ];

          //takes only the client value from uniqueObjArray
          this.clients = Object.keys(this.uniqueObjArray).map(
            (k) => this.uniqueObjArray[k].client
          );

          if (search) {
            this.clients = this.clients.filter((c) => {
              return (
                c.name.toLowerCase().includes(search.toLowerCase()) ||
                c.shortName.toLowerCase().includes(search.toLowerCase())
              );
            });
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>
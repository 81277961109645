import Auth from "@/views/auth/Auth";

export default [
  {
    path: "/login",
    name: "login",
    component: Auth,
    meta: {
      requiresNoAuth: true,
    },
  },
];

<template>
    <div class="auth">
    <Login  v-if="!isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Login from "./Login";
export default {
  components:{
  Login
  },
  computed:{
    ...mapGetters(["isAuthenticated"]),
}
};
</script>

<style>
</style>
<template>
  <main>
    <Modal @close="close()">
      <form>
        <div class="form-content">
          <header>
            <div></div>
            <Button @click="close()" class="close"
              ><img class="icons" src="@/assets/icons/close.svg" alt="close"
            /></Button>
          </header>
          <hr class="break" />

          <main class="inputs--wrapper">
            <section class="form-inputs">
              <template v-if="!changePassword">
                <div class="row">
                  <Input
                    placeholder="Потребителско име"
                    type="text"
                    icon="user-username.svg"
                    :invalid="invalid.username"
                    @input="invalid.username = false"
                    v-model="user.username"
                  />
                </div>
                <div class="row">
                  <Input
                    placeholder="Име и фамилия"
                    type="text"
                    icon="user-name.svg"
                    :invalid="invalid.name"
                    @input="invalid.name = false"
                    v-model="user.name"
                  />
                </div>
                <div class="row">
                  <Input
                    placeholder="Име и фамилия (EN)"
                    type="text"
                    icon="user-name.svg"
                    :invalid="invalid.nameEn"
                    @input="invalid.nameEn = false"
                    v-model="user.nameEn"
                  />
                </div>

                <div class="row">
                  <Input
                    placeholder="Телефон"
                    type="text"
                    icon="user-phone.svg"
                    :invalid="invalid.phone"
                    @input="invalid.phone = false"
                    v-model="user.phone"
                  />
                </div>
                <div class="row">
                  <Input
                    autocomplete="new-password"
                    placeholder="Email"
                    type="text"
                    icon="user-mail.svg"
                    :invalid="invalid.email"
                    @input="invalid.email = false"
                    v-model="user.email"
                  />
                </div>
                <div class="row">
                  <Input
                    v-if="!userId"
                    autocomplete="new-password"
                    placeholder="Парола"
                    type="password"
                    icon="pass.svg"
                    :invalid="invalid.password"
                    @input="invalid.password = false"
                    v-model="user.password"
                  />
                </div>
                <div class="row" v-if="user._id && currentUser.role ==='administrator'">
                  <InputButtons icon="contract-access.svg">
                    <Button @click="contractAccessModalOpen = true">
                      ДОСТЪП ДО ДОГОВОРИ</Button
                    >
                  </InputButtons>
                </div>
                <div class="row">
                  <InputButtons
                    icon="user-role.svg"
                    v-if="modalType !== 'previewFromHeader'"
                  >
                    <Button
                      :class="{ selected: user.role === 'user' }"
                      @click="user.role = 'user'"
                    >
                      USER</Button
                    >
                    <Button
                      :class="{ selected: user.role === 'administrator' }"
                      @click="user.role = 'administrator'"
                    >
                      ADMINISTRATOR</Button
                    >
                  </InputButtons>
                </div>

                <section
                  class="app-modes"
                  v-if="modalType === 'previewFromHeader'"
                >
                  <hr class="break" />

                  <p>Таблица с отчети</p>
                  <div>
                    <img
                      src="@/assets/header/reports-main-fields.svg"
                      :class="{
                        'active-mode': reportsMode === 'reportsMainFields',
                      }"
                      alt="main fields reports img"
                      @click="setReportsMode('reportsMainFields')"
                    />
                    <img
                      src="@/assets/header/reports-all-fields.svg"
                      :class="{
                        'active-mode': reportsMode === 'reportsAllFields',
                      }"
                      alt="all reports img"
                      @click="setReportsMode('reportsAllFields')"
                    />
                  </div>
                </section>
                <section
                  class="app-modes"
                  v-if="modalType === 'previewFromHeader'"
                >
                  <p>Изглед</p>
                  <div>
                    <img
                      src="@/assets/header/app-auto.svg"
                      :class="{ 'active-mode': colorMode === 'auto' }"
                      alt="autoMode img"
                      @click="autoMode()"
                    />
                    <img
                      src="@/assets/header/app-light.svg"
                      :class="{ 'active-mode': colorMode === 'light-mode' }"
                      alt="lightMode img"
                      @click="lightMode()"
                    />
                    <img
                      src="@/assets/header/app-dark.svg"
                      :class="{ 'active-mode': colorMode === 'dark-mode' }"
                      alt="darkMode img"
                      @click="darkMode()"
                    />
                  </div>
                </section>
              </template>

              <!-- //if user wants to change the password -->
              <template v-if="changePassword">
                <div class="row">
                  <Input
                    autocomplete="new-password"
                    placeholder="Нова парола"
                    type="password"
                    icon="pass.svg"
                    :invalid="invalid.newPassword"
                    @input="invalid.newPassword = false"
                    v-model="user.newPassword"
                  />
                </div>
                <div class="row">
                  <Input
                    autocomplete="new-password"
                    placeholder="Повторете паролата"
                    type="password"
                    icon="pass.svg"
                    :invalid="invalid.passwordRepeat"
                    @input="invalid.passwordRepeat = false"
                    v-model="user.passwordRepeat"
                  />
                </div>
              </template>
              <!-- //if user wants to change the password -->
            </section>
          </main>
          <p class="row error">
            <span v-if="error">{{ error }}</span>
          </p>
        </div>
        <Button
          @click="changePassword = true"
          v-if="userId && !changePassword"
          class="red"
          >СМЯНА НА ПАРОЛА</Button
        >
        <Button @click="save">ЗАПИШИ</Button>
      </form>
    </Modal>

    <ContractAccessModal
      v-if="contractAccessModalOpen"
      :user="user"
      @close="contractAccessModalOpen = false"
    />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import InputButtons from "@/components/InputButtons";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import ContractAccessModal from "@/components/nomenclatureModals/ContractAccessModal";
export default {
  props: ["modalType", "userId"],
  components: {
    Input,
    InputButtons,
    Button,
    Modal,
    ContractAccessModal,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      user: {
        username: "",
        name: "",
        nameEn: "",
        phone: "",
        email: "",
        password: "",
        role: "user",
        newPassword: "",
        passwordRepeat: "",
      },
      contractAccessModalOpen: false,
      changePassword: false,
      error: "",
      colorMode: "",
      reportsMode: "",
      loading: false,
      invalid: {
        invalid: false,
      },
    };
  },
  watch: {
    $route: {
      handler: "getUser",
      immediate: true,
    },
  },
  methods: {
    setReportsMode(type) {
      this.reportsMode = type;
      localStorage.setItem("reportsMode", type);
      window.dispatchEvent(new Event("storage"));
    },
    autoMode() {
      this.colorMode = "auto";
      const element = document.body;

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        element.classList.add("dark-mode");
      } else {
        element.classList.remove("dark-mode");
      }
      localStorage.setItem("colorMode", "auto");
    },
    darkMode() {
      this.colorMode = "dark-mode";
      const element = document.body;
      element.classList.add("dark-mode");
      localStorage.setItem("colorMode", "dark-mode");
    },
    lightMode() {
      this.colorMode = "light-mode";
      const element = document.body;
      element.classList.remove("dark-mode");
      localStorage.setItem("colorMode", "light-mode");
    },
    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.user.username) {
        this.error = "Не сте въвели потребителско име";
        this.invalid.username = true;
        return false;
      } else if (!this.user.name) {
        this.error = "Не сте въвели Име и Фамилия";
        this.invalid.name = true;
        return false;
      } else if (!this.user.nameEn) {
        this.error = "Не сте въвели Име и Фамилия(ЕN)";
        this.invalid.nameEn = true;
        return false;
      } else if (!this.user.phone) {
        this.error = "Не сте въвели Телефон";
        this.invalid.phone = true;
        return false;
      } else if (!this.user.email) {
        this.error = "Не сте въвели Email";
        this.invalid.email = true;
        return false;
      } else if (!this.user.password && !this.userId) {
        this.error = "Не сте въвели Парола";
        this.invalid.password = true;
        return false;
      } else if (!this.user.role) {
        this.error = "Не сте въвели Роля";
        return false;
      } else if (!this.user.newPassword && this.changePassword) {
        this.error = "Не сте въвели нова Парола";
        this.invalid.newPassword = true;
        return false;
      } else if (!this.user.passwordRepeat && this.changePassword) {
        this.error = "Не сте повторили новата парола";
        this.invalid.passwordRepeat = true;
        return false;
      } else if (this.user.newPassword != this.user.passwordRepeat) {
        this.error = "Паролите не съвпадат";
        return false;
      }
      return true;
    },
    save() {
      this.error = "";
      if (this.isValid()) {
        if (this.userId) {
          let url = "/users";

          if (this.changePassword) {
            url += "?changePassword=true";
          }

          this.$apiService
            .put(url, this.user)
            .then(() => {
              if (this.userId === this.currentUser._id && this.changePassword) {
                this.$store
                  .dispatch("logout")
                  .then(() => this.$router.push("/login"));
              }
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
        if (!this.userId) {
          this.$apiService
            .post("/auth/register", this.user)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
      }
    },
    getUser() {
      if (this.userId) {
        this.$apiService.get(`/users/${this.userId}`).then((res) => {
          this.user = res.data;
        });
      }
    },
  },
  mounted() {
    const colorMode = localStorage.getItem("colorMode");
    const reportsMode = localStorage.getItem("reportsMode");
    this.colorMode = colorMode;
    this.reportsMode = reportsMode;
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.app-modes {
  text-align: left;
  p {
    font-style: italic;
    padding-left: 1rem;
  }
  div {
    display: flex;

    img {
      cursor: pointer;
      mix-blend-mode: luminosity;
      min-width: 100px;
      width: 33%;
      @include tr();
      &.active-mode {
        mix-blend-mode: initial;
      }
    }
  }
}
</style>

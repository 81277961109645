<template>
  <main
    class="report-hours"
    v-click-outside="closeDropdown"
    :class="{
      'report-hours--open': isDropdownOpen,
    }"
  >
    <div
      class="hours-wrapper"
      :class="{
        'hours-wrapper--invalid': invalid,
        'hours-wrapper--open': isDropdownOpen,
        'hours-wrapper--up': up,
      }"
    >
      <input
        @click="isDropdownOpen = !isDropdownOpen"
        :value="
          isDropdownOpen
            ? 'Изберете'
            : getValue
            ? getValue
            : reportMainTime
            ? 'Изработено време'
            : 'Часове'
        "
        :type="'text'"
        readonly
        @focus="$emit('focus')"
      />
      <img
        class="icons"
        :src="`${require(`@/assets/icons/dd.svg`)}`"
        alt="icon"
      />

      <div class="options-wrapper" v-if="isDropdownOpen">
        <section class="section-wrapper">
          <span>ЧАСОВЕ</span>
          <div class="option-box">
            <p
              class="option"
              v-for="h in hours"
              :key="`hour-${h}`"
              :class="{
                selected:
                  h === (typeof value === 'object' ? value.hours : value),
              }"
              @click="setHours(h)"
            >
              {{ h }}
            </p>
          </div>
        </section>
        <section class="section-wrapper">
          <div
            class="minutes"
            v-if="report.contract.contractType !== 'question' && !noMinutes"
          >
            <span>МИНУТИ</span>
            <div class="option-box">
              <p
                class="option"
                v-for="m in minutes"
                :key="`minute-${m.name}`"
                :class="{ selected: m.value === value.minutes }"
                @click="setMinutes(m.value)"
              >
                {{ m.name }}
              </p>
            </div>
          </div>
          <div class="time" v-if="reportMainTime">
            <Checkbox
              label="НРВ"
              :disabled="reportTime === 'time'"
              :value="reportTime === 'time'"
              @input="setReportTime('time')"
            />
            <Checkbox
              label="ИРВ 1"
              :disabled="reportTime === 'overtime'"
              :value="reportTime === 'overtime'"
              @input="setReportTime('overtime')"
            />
            <Checkbox
              label="ИРВ 2"
              :disabled="reportTime === 'overtimeHolidays'"
              :value="reportTime === 'overtimeHolidays'"
              @input="setReportTime('overtimeHolidays')"
            />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import vClickOutside from "v-click-outside";
import Checkbox from "@/components/Checkbox";
export default {
  props: [
    "report",
    "invalid",
    "value",
    "reportMainTime",
    "noMinutes",
    "up",
    "reset",
  ],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Checkbox,
  },
  computed: {
    getValue() {
      let value = this.value;
      if (typeof value === "object") {
        value = Number(`${value.hours}.${value.minutes}`);
      }

      return value;
    },
  },
  watch: {
    "report._id"() {
      if (this.report.time.hoursSum) this.setReportTime("time");
      else if (this.report.overtime.hoursSum) this.setReportTime("overtime");
      else if (this.report.overtimeHolidays.hoursSum)
        this.setReportTime("overtimeHolidays");
      else this.setReportTime("time");
    },
    "report.contract"() {
      if (this.report.contract.contractType === "question") this.setMinutes(0);
    },
  },
  data() {
    return {
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      minutes: [
        { value: 0, name: 0 },
        { value: 25, name: 15 },
        { value: 50, name: 30 },
        { value: 75, name: 45 },
      ],

      reportTime: "time",
      isDropdownOpen: false,
    };
  },
  methods: {
    setHours(h) {
      if (typeof this.value === "object") this.value.hours = h;
      else this.$emit("input", h);
    },
    setMinutes(m) {
      this.value.minutes = m;
    },
    resetHours() {
      this.report[this.reportTime].hours = 0;
      this.report[this.reportTime].minutes = 0;
      this.report[this.reportTime].hoursSum = 0;
    },
    setReportTime(type) {
      if (type !== this.reportTime) this.resetHours();

      this.reportTime = type;
      if (this.reset) this.resetHours(), (this.reportTime = "time");
      this.$emit("selectReportTime", this.reportTime);
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
  },
  mounted() {
    if (this.report.time.hoursSum) this.setReportTime("time");
    else if (this.report.overtime.hoursSum) this.setReportTime("overtime");
    else if (this.report.overtimeHolidays.hoursSum)
      this.setReportTime("overtimeHolidays");
    else this.setReportTime("time");
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.report-hours {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  top: 50%;
  right: 10px;
  max-width: 160px;
  @include tr();
  @include noselect();
  .hours-wrapper {
    position: relative;

    &.hours-wrapper--invalid {
      input,
      .options-wrapper {
        border: 1px solid $mainError;
      }
    }
    &.hours-wrapper--open {
      &:not(.hours-wrapper--up) {
        input {
          border-radius: $borderRadius $borderRadius 0 0;
          border-bottom: none;
        }
        .options-wrapper {
          border-top: none;
        }
      }
      &.hours-wrapper--up {
        input {
          border-radius: 0 0 $borderRadius $borderRadius;
          border-top: none;
        }
        .options-wrapper {
          bottom: 100%;
          border-bottom: none;
          border-radius: $borderRadius $borderRadius 0 0;
          top: initial;
        }
      }
    }

    input {
      background-color: $input;
      border: 1px solid #707070;
      border-radius: $borderRadius;
      cursor: pointer;
      display: flex;
      font: italic normal 600 13px/17px Google Sans;
      outline: none;
      height: 30px;
      position: relative;
      padding: 0 10px;
      width: 100%;
      @include tr();
      @include noselect();
    }

    .icons {
      right: 10px;
      top: 50%;
      pointer-events: none;
      position: absolute;
      @include transform(translateY(-50%) translateX(0%));
    }

    .options-wrapper {
      background-color: $input;
      border-radius: 0 0 $borderRadius $borderRadius;
      border: 1px solid #707070;
      display: flex;
      position: absolute;
      padding-bottom: 5px;
      max-height: 200px;
      width: 100%;
      z-index: 9995;
      @include tr();
      .section-wrapper {
        max-width: 80px;
        width: 50%;

        span {
          display: block;
          text-align: center;
          opacity: 0.5;
          width: 100%;
        }
        .option-box {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          max-height: 140px;
          overflow: auto;

          .option {
            background: #5f7387 0% 0% no-repeat padding-box;
            border-radius: 3px;
            cursor: pointer;
            color: white;
            height: fit-content;
            padding: 4px;
            text-align: center;
            margin: 2px;
            max-width: 24px;
            max-height: 24px;
            width: calc(50% - 10px);
            @include hover();
            &.selected {
              background: #0d66d0;
            }
          }
        }
        .time {
          display: flex;
          flex-direction: column;
          gap: 3px;
          height: 50%;
          justify-content: center;
          max-width: 80px;
          padding-left: 14px;
        }
      }
    }
  }
}
</style>
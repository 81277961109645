<template>
  <div class="contract-status">
    <section class="progress">
      <p :style="`width:${contractPercent > 100 ? 100 : contractPercent}%`">
        {{ monthlyHours }}
      </p>
    </section>
    <section>
      <p>0</p>
      <p v-if="contract">
        {{
          contract.hoursPod.hours
            ? contract.hoursPod.hours
            : contract.hoursRaz.hours
        }}
      </p>
      <p v-if="task">{{ task.hours.real }}</p>
    </section>
  </div>
</template>

<script>
export default {
  props: ["contract", "monthlyHours", "task"],
  computed: {
    contractPercent() {
      let percent = 0;
      if (this.monthlyHours) {
        // contracts
        if (this.contract) {
          if (this.contract.hoursPod.hours) {
            percent = Math.ceil(
              (this.monthlyHours / this.contract.hoursPod.hours) * 100
            );
          } else {
            percent = Math.ceil(
              (this.monthlyHours / this.contract.hoursRaz.hours) * 100
            );
          }
        } else {
          // tasks
          percent = Math.ceil((this.monthlyHours / this.task.hours.real) * 100);
        }
      }
      return percent;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-status {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: 100%;
  position: absolute;
  top: 5px;
  right: 10px;
  width: 115px;

  section {
    display: flex;
    justify-content: space-between;
  }
  .progress {
    background: rgb(95, 115, 135, 0.3) 0% 0% no-repeat padding-box;
    border-radius: $borderRadius;
    height: 15px;
    overflow: hidden;
    position: relative;
    p {
      background: #0099ff;
      color: white;
      position: absolute;
      height: 100%;
      top: 0;
      min-width: fit-content;
      text-align: right;
      width: 0%;
      @include tr();
    }
  }
}
</style>
<template>
  <div class="table-wrapper">
    <table class="table">
      <slot></slot>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.table-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 4px 4px 4px;
  box-shadow: 0px 5px 30px #00000040, -0px 5px 30px #00000040;
  -moz-box-shadow: 0px 5px 30px #00000040, -0px 5px 30px #00000040;
  -webkit-box-shadow: 0px 5px 30px #00000040, -0px 5px 30px #00000040;
  height: 100%;
  min-height: 580px;
  overflow: auto;
  width: 100%;
  @include scrollbar(10px, $secondMainColor);
}
.table {
  border-collapse: collapse;
  width: 100%;
  .month-to-month {
    align-items: center;
    background: $input;
    border: 1px solid #707070;
    border-radius: $borderRadius;
    display: flex;
    .datepicker {
      &.datepicker-container {
        border: 1px solid $input;
      }
    }
  }
  th,
  td {
    border-bottom: 1px solid #d3d3d3;
    height: 60px;
    padding: 14px 10px;
    text-align: left;
    @include transition($transition);
    &.bigger-width {
      max-width: 450px;
      min-width: 450px;
    }
    &.tiny {
      width: 1%;
      white-space: nowrap;
    }
    &.multy {
      p {
        display: block;
        height: fit-content;
        padding: 2px;
        width: 100%;
      }
      :last-child {
        color: $secondMainColor;
      }
    }
    &.center {
      text-align: center;
    }
    p {
      align-items: center;
      border-radius: $borderRadius;
      display: flex;
      height: 28px;
      justify-content: center;
      padding: 10px;
      width: 150px;
      img {
        margin-right: 5px;
      }
    }
    u {
      color: $secondMainColor;
      font-weight: bold;
      @include transition($transition);

      &:hover {
        opacity: 0.6;
      }
    }
    img {
      cursor: pointer;
    }
    &.month-picker {
      width: 5%;
    }
    .contract-parameters {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(odd) {
          border-right: 2px solid #eedad3;
          border-left: 2px solid #eedad3;
        }
      }
    }

    .download {
      color: #fe753f;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }

    .no {
      color: #474747;
      font-weight: 700;
      opacity: 0.5;
    }
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        td {
          background-color: rgba($color: #fe753f1a, $alpha: 0.1);
        }
      }
      &:nth-child(odd) {
        background: #fcf9f7;
      }
    }
  }
}
.maintenance {
  p {
    background: #7bc94e;
  }
}
.development {
  p {
    background: #7ab8e1;
  }
}
.question {
  p {
    background: #d8a890;
  }
}
</style>

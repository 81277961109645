<template>
    <input
      :value="value"
      :type="'number'"
       :min="0"
       :step=".01"
      oninput="validity.valid || (value='')"
      @click="$emit('click')"
      @keypress.enter="$emit('enter')"
      @input="$emit('input', $event.target.value)"
    />
</template>

<script>
export default {
  props: [
    "value",
    "type",
  ],
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
      input,
      .input {
        align-items: center;
        background: #5f7387 0% 0% no-repeat padding-box;
        border: 1px solid #5f7387;
        border-radius: $borderRadius;
        color: white;
        justify-content: center;
        outline: none;
        text-align: center;
        max-width: 45px;
        margin-left: 5px;
        width: 45px;
      }
</style>
<template>
  <Modal @close="close()">
    <form>
      <div class="form-content">
        <header>
          <Dropdown
            placeholder="Клиент..."
            icon="search.svg"
            class="client-search"
            :dataArray="clients"
            :formatter="(client) => client.name"
            :typing="true"
            :invalid="invalid.client"
            @input="
              getContracts(task.client ? task.client._id : false),
                (invalid.client = false)
            "
            @search="getClients($event)"
            v-model="task.client"
          />

          <section>
            <Datepicker
              icon="Calendar.svg"
              class="datepicker reportDatepicker"
              :invalid="invalid.fromDate"
              @input="invalid.fromDate = false"
              v-model="task.fromDate"
            />

            <Button @click="close" class="close"
              ><img class="icons" src="@/assets/icons/close.svg" alt="close"
            /></Button>
          </section>
        </header>

        <hr class="break" />

        <main class="inputs--wrapper">
          <section class="form-inputs">
            <div class="row">
              <Dropdown
                placeholder="Договор..."
                icon="report-contract.svg"
                :dataArray="contracts"
                :formatter="(contract) => contract.contractName"
                :invalid="invalid.contract"
                :disabled="!task.client"
                @input="invalid.contract = false"
                v-model="task.contract"
              />
            </div>

            <div class="row">
              <Input
                placeholder="Име на задачата"
                type="text"
                icon="report-contract.svg"
                :invalid="invalid.name"
                @input="invalid.name = false"
                v-model="task.name"
              />
            </div>

            <div class="row">
              <InputButtons
                icon="contract-hours.svg"
                :invalid="invalid.realHours"
              >
                <div>
                  <p>
                    Оценка - Часове - Реално оценени (не се вижда от клиента)
                  </p>
                  <BoxInput
                    :input="(invalid.realHours = false)"
                    v-model="task.hours.real"
                  />
                </div>
              </InputButtons>
            </div>

            <div class="row">
              <InputButtons
                icon="contract-hours.svg"
                :invalid="invalid.clientHours"
              >
                <div>
                  <p>Оценка - Часове - подадени към клиента</p>
                  <BoxInput
                    :input="(invalid.clientHours = false)"
                    v-model="task.hours.client"
                  />
                </div>
              </InputButtons>
            </div>

            <div class="row" v-if="id">
              <InputButtons icon="contract-hours.svg">
                <div>
                  <p>Оценка - Часове - реално отчетени (автоматично)</p>
                  <BoxInput v-model="task.reportedHours" :disabled="true" />
                </div>
              </InputButtons>
            </div>
          </section>
        </main>
        <p class="row error">
          <span v-if="error">{{ error }}</span>
        </p>
      </div>
      <Button @click="save">ЗАПИШИ</Button>
    </form>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import BoxInput from "@/components/BoxInput";
import InputButtons from "@/components/InputButtons";
import Button from "@/components/Button";
import Dropdown from "@/components/Dropdown";
import Datepicker from "@/components/Datepicker";
import Modal from "@/components/Modal";
export default {
  props: ["id"],
  components: {
    Input,
    BoxInput,
    InputButtons,
    Button,
    Dropdown,
    Datepicker,
    Modal,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      task: { contract: "", hours: {} },
      error: "",
      loading: false,
      invalid: {
        invalid: false,
      },
      clients: [],
      contracts: [],
      openedDropdown: false,
      searchCompanies: "",
    };
  },
  watch: {
    $route: [
      {
        handler: "getClients",
        immediate: true,
      },
      {
        handler: "getTask",
        immediate: true,
      },
    ],
  },
  methods: {
    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.task.client) {
        this.error = "Не сте въвели клиент";
        this.invalid.client = true;
        return false;
      } else if (!this.task.contract) {
        this.error = "Не сте въвели договор";
        this.invalid.contract = true;
        return false;
      } else if (!this.task.name) {
        this.error = "Не сте въвели име на задачата";
        this.invalid.name = true;
        return false;
      } else if (!this.task.hours.real) {
        this.error = "Не сте въвели реалните часове";
        this.invalid.realHours = true;
        return false;
      } else if (!this.task.hours.client) {
        this.error = "Не сте въвели подадените към клиента часове";
        this.invalid.clientHours = true;
        return false;
      }

      return true;
    },
    save() {
      this.error = "";
      if (this.isValid()) {
        if (this.id) {
          this.$apiService
            .put("/tasks", this.task)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
        if (!this.id) {
          this.$apiService
            .post("/tasks", this.task)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
      }
    },
    getTask() {
      if (this.id) {
        this.$apiService.get(`/tasks/${this.id}`).then((res) => {
          this.task = res.data;
        });
      }
    },
    getClients(search) {
      this.loading = true;
      this.clients = [];
      let url = `/clients?currentUserId=${this.currentUser._id}`;

      if (typeof search === "string") {
        url += `&search=${search}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.clients = res.data.results;
        })
        .then(() => {
          this.loading = false;
        });
    },
    getContracts(clientId) {
      this.loading = true;
      this.contracts = [];
      if (!this.task._id) this.task.contract = "";
      let url = `/contracts?clientId=${clientId}&currentUserId=${this.currentUser._id}`;

      this.$apiService
        .get(url)
        .then((res) => {
          this.contracts = res.data.results;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-wrapper {
  .modal {
    .form-content {
      .row {
        .buttons_wrapper {
          div {
            justify-content: space-between;
            padding: 0 20px 0 50px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>


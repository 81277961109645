<template>
  <div class="table__container">
    <section class="search-wrapper">
      <Input
        placeholder="ТЪРСЕНЕ"
        type="text"
        icon="search.svg"
        v-model="search"
        class="search"
        @input="getIssues()"
      />
    </section>

    <transition name="table-transition" appear>
      <main class="main_content">
        <section class="navs">
          <section>
            <NavToggle
              @click="selectIssuesType('unresolved')"
              name="НОВИ"
              icon="issues-unresolved.svg"
              :active="type === 'unresolved'"
            />
            <NavToggle
              @click="selectIssuesType('resolved')"
              name="РАЗРЕШЕНИ"
              icon="issues-resolved.svg"
              :active="type === 'resolved'"
            />
          </section>
        </section>

        <div class="content">
          <Table>
            <thead>
              <tr>
                <th>ДАТА, ЧАС</th>
                <th>КЛИЕНТ</th>
                <th>ПРИЛОЖЕНИЕ</th>
                <th>ПОДАТЕЛ</th>
                <th>ОПИСАНИЕ</th>
                <th class="tiny center">АУДИО ЗАПИС</th>
                <th class="tiny center">ПРИКАЧЕНИ ФАЙЛОВЕ</th>
                <th class="tiny center">РАЗРЕШЕН</th>
              </tr>
            </thead>

            <tbody v-if="loading || !reports.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="r in reports" :key="`reports-${r._id}`">
                <td class="multy">
                  <p>{{ r.createdAt | formatDate }}</p>
                  <p>{{ r.createdAt | formatDate }}</p>
                </td>
                <td></td>
                <td></td>
                <td class="multy">
                  <p>{{ r.username }}</p>
                  <p>{{ r.email }}</p>
                  <p>{{ r.phone }}</p>
                </td>
                <td class="bigger-width">{{ r.description }}</td>
                <td class="center">
                  <AudioPlayer
                    v-if="r.audio"
                    :src="`/uploads/issues/${r.audio}`"
                  />
                  <span class="no" v-else>НЯМА АУДИО</span>
                </td>
                <td class="center">
                  <a
                    :href="`/uploads/issues/${r.file}`"
                    target="_blank"
                    v-if="r.file"
                    class="download"
                    >СВАЛИ</a
                  >
                  <span class="no" v-else>НЯМА ФАЙЛОВЕ</span>
                </td>
                <td>
                  <Slider
                    @change="changeResolved(r, $event)"
                    :checked="r.resolved"
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <Pagination :total="total" :size="size" />
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import NavToggle from "@/components/NavToggle";
import Table from "@/components/Table";
import Pagination from "@/components/Pagination";
import AudioPlayer from "@/components/AudioPlayer";
import Slider from "@/components/Slider";
export default {
  components: {
    Input,
    Table,
    Pagination,
    NavToggle,
    AudioPlayer,
    Slider,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    reportsMainFields() {
      return (
        this.$route.query.type !== "all" &&
        this.reportsMode === "reportsMainFields"
      );
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      size: 20,
      search: "",
      type: "",
      reportsMode: "",
      reports: [],
      reportModal: false,
      groupReportModal: false,
      isCopyReport: false,
    };
  },
  watch: {
    $route: [
      {
        handler: "getIssues",
        immediate: true,
      },
    ],
  },
  methods: {
    closeReportModal(update) {
      this.reportModal = false;
      this.isCopyReport = false;
      this.groupReportModal = false;
      if (update) {
        this.getIssues();
      }
    },

    selectIssuesType(t) {
      if (this.type !== t) {
        this.type = t;
        this.$router.push(`/issues?type=${t}`);
      }
    },
    getIssues() {
      this.loading = true;
      this.reports = [];

      let url = `/issues?type=${this.type}&size=${this.size}&page=${
        this.$route.query.page || 1
      }`;

      if (this.search) {
        url += `&search=${this.search}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.reports = res.data.results;
          this.total = res.data.total;

          if (res.data.totalPages) {
            const query = { ...this.$route.query };
            if (query.page > res.data.totalPages) {
              delete query.page;
              this.$router.replace({ query: query });
            }
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    changeResolved(i, r) {
      this.$set(i, "resolved", r);

      this.$apiService
        .put("/issues", i)
        .then(() => {
          this.getIssues();
        })
        .then(() => {});
    },
  },
  mounted() {
    if (this.$route.query.type) this.type = this.$route.query.type;

    if (!this.$route.query.task) this.selectIssuesType("unresolved");
  },
};
</script>

<template>
  <div class="content">
    <template>
      <Table>
        <thead>
          <tr>
            <th class="month-picker">
              <Datepicker
                icon="Calendar.svg"
                class="inquiry-dropdown"
                :month="true"
                v-model="month"
                @input="getContracts(clientFilter._id ? clientFilter._id : '')"
              />
            </th>
            <th>
              <Dropdown
                placeholder="ВСИЧКИ КЛИЕНТИ"
                class="inquiry-dropdown"
                icon="dropdown-arrows.svg"
                :dataArray="clients"
                :formatter="(client) => client.name"
                :iconRight="true"
                :typing="true"
                v-model="clientFilter"
                @input="getContracts(clientFilter._id ? clientFilter._id : '')"
                @search="getClients"
              />
            </th>
            <th>
              <Dropdown
                placeholder="ДОГОВОР"
                class="inquiry-dropdown"
                icon="dropdown-arrows.svg"
                :dataArray="contracts"
                :formatter="(contract) => contract.contractName"
                :iconRight="true"
                v-model="contractFilter"
                :disabled="!clientFilter"
              />
            </th>
            <th v-if="contractType === 'development'">БЮДЖЕТ</th>
            <th>ВКЛЮЧЕНИ ЧАСОВЕ</th>
            <template v-if="contractType === 'maintenance'">
              <th>ОСТАВАЩИ ЧАСОВЕ</th>
              <th>ДОПЪЛНИТЕЛНИ</th>
              <th>ЗА СМЕТКА НА CDOTS</th>
              <th>СУМА ЛВ</th>
              <th>СВЪРЗАНИ РАЗХОДИ</th>
              <th>ОБЩО ЛЕВА</th>
            </template>
            <template v-else>
              <th>ОБЩО ЧАСОВЕ</th>
              <th>РАЗЛИКА</th>
              <th>ЗА СМЕТКА НА CDOTS</th>
              <th>СР. ЦЕНА НА ЧАС</th>
              <th>СР. ЦЕНА НА ЧАС ОБЩО</th>
              <th></th>
              <th></th>
            </template>
          </tr>
        </thead>
        <!-- MAINTENANCE AND QUESTION CONTRACTS TABLE -->
        <tbody v-if="contractType === 'maintenance'">
          <tr
            v-for="c in contracts"
            :key="`contract-${c._id}`"
            v-show="contractFilter._id ? c._id === contractFilter._id : true"
          >
            <td>{{ c.client.name }}</td>
            <td>{{ c.contractName }}</td>
            <td></td>
            <td>
              {{
                c.contractType === "maintenance"
                  ? c.hoursPod.hours
                  : c.monthlyHours
              }}
              ч.
            </td>
            <td v-if="c.contractType === 'maintenance'">
              {{
                0 > c.hoursPod.hours - c.monthlyHours
                  ? 0
                  : c.hoursPod.hours - c.monthlyHours
              }}
              ч.
            </td>
            <td v-else>-</td>
            <!--question -->
            <td v-if="c.contractType === 'maintenance'">
              {{
                0 > c.hoursPod.hours - c.monthlyHours
                  ? (c.hoursPod.hours - c.monthlyHours) * -1
                  : 0
              }}
              ч.
            </td>
            <td v-else>-</td>
            <td>{{c.monthlyHoursCdotsExpenses}}</td>
            <!--question -->
            <td v-if="c.contractType === 'maintenance'">
              {{
                sumHours(
                  c.hoursPod.hours,
                  c.hoursPod.priceForHour,
                  c.hoursPod.priceExtraHour,
                  c.monthlyHours
                ).toFixed(2)
              }}лв.
            </td>
            <td v-else>
              {{ (c.hoursZap.priceForHour * c.monthlyHours).toFixed(2) }}лв.
            </td>
            <td>{{ c.monthlyCosts }} лв.</td>
            <td v-if="c.contractType === 'maintenance'">
              {{
                (
                  sumHours(
                    c.hoursPod.hours,
                    c.hoursPod.priceForHour,
                    c.hoursPod.priceExtraHour,
                    c.monthlyHours
                  ) +
                  Number(c.monthlyCosts) +
                  Number(c.hosting.price)
                ).toFixed(2)
              }}
              лв.
            </td>
            <td v-else>
              {{
                (
                  c.hoursZap.priceForHour * c.monthlyHours +
                  Number(c.monthlyCosts)
                ).toFixed(2)
              }}
              лв.
            </td>
          </tr>
        </tbody>
        <!-- MAINTENANCE AND QUESTION CONTRACTS TABLE -->

        <!-- DEVELOPMENT CONTRACTS TABLE -->
        <tbody v-else>
          <tr
            v-for="c in contracts"
            :key="`contract-${c._id}`"
            v-show="contractFilter._id ? c._id === contractFilter._id : true"
          >
            <td>{{ c.client.name }}</td>
            <td>{{ c.contractName }}</td>
            <td></td>
            <td>{{ c.hoursRaz.budjet }} лв.</td>
            <td>{{ c.hoursRaz.hours }} ч.</td>
            <td>
              <!-- old  -->
              <!-- {{  c.hoursRaz.hours + extraHours(c) }} -->
              {{ c.monthlyHours }}
              ч.
            </td>
            <td>{{ c.hoursRaz.hours - c.monthlyHours }} ч.</td>
            <td>{{c.monthlyHoursCdotsExpenses}}</td>
            <td>{{ c.hoursRaz.avgPriceHour }}лв.</td>
            <td>
              {{
                (avgPriceDevTotal(c) > 0 ? avgPriceDevTotal(c) : 0).toFixed(2)
              }}лв.
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <!-- DEVELOPMENT CONTRACTS TABLE -->
      </Table>
      <!-- <Pagination :total="total" :size="size" /> -->
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/Dropdown";
import Table from "@/components/Table";
// import Pagination from "@/components/Pagination";
import Datepicker from "@/components/Datepicker";
export default {
  components: { Dropdown, Datepicker, Table },
  props: ["contractType", "clients", "cdotsExpenses"],
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      search: "",
      total: 0,
      size: 20,
      month: "",
      clientFilter: "",
      contractFilter: "",
      contracts: [],
    };
  },
  watch: {
    contractType() {
      this.getContracts();
      this.clientFilter = "";
      this.contractFilter = "";

      if (this.$route.fullPath.includes("page")) {
        this.$router.replace({ query: {} });
      }
      if (this.contractType === "development") this.month = "";
      else this.month = new Date();
    },
    // cdotsExpenses() {
    //   this.getContracts();
    //   this.clientFilter = "";
    //   this.contractFilter = "";
    // },
    $route: [
      {
        handler: "getContracts",
        immediate: true,
      },
    ],
  },
  methods: {
    avgPriceDevTotal(contract) {
      // return contract.hoursRaz.budjet /  ( contract.hoursRaz.hours + this.extraHours(contract))
      return (
        contract.hoursRaz.budjet /
        (contract.monthlyHours >= 1 ? contract.monthlyHours : 1)
      );
    },
    sumHours(hours, priceForHour, priceExtraHour, monthlyHours) {
      const hoursIncludedPrice = hours * priceForHour;
      let hoursExceeded = 0;

      if (monthlyHours > hours) {
        hoursExceeded = monthlyHours - hours;
      }
      const hoursExceededPrice = hoursExceeded * priceExtraHour;

      return hoursIncludedPrice + hoursExceededPrice;
    },
    getClients(search) {
      if (this.contractType === "development") {
        this.$emit("getClients", search, "");
      } else {
        this.$emit("getClients", search ? search : "");
      }
    },
    getContracts(clientId) {
      this.contracts = [];
      this.contractFilter = "";

      let url = `/contracts?contractType=${this.contractType}`;
      //let url = `/contracts?contractType=${this.contractType}&size=${
      //   this.size
      // }&page=${this.$route.query.page || 1}&active=${true}`;

      if (typeof clientId === "string") {
        url += `&clientId=${clientId}`;
      }
      if (this.contractFilter) {
        url += `&contractName=${this.contractFilter.contractName}`;
      }

      this.$apiService.get(url).then((res) => {
        this.contracts = res.data.results;
        this.total = res.data.total;
        if (this.contracts.length === 1) {
          this.contractFilter = this.contracts[0];
        }

        for (let c of this.contracts) {
          this.getReports(c);
        }
      });
    },
    getReports(contract) {
      if (contract) {
        // let url = `/reports/stats/${contract._id}?month=${this.month}&allReports=true`;
        let url = `/reports/stats/${contract._id}?month=${this.month}`;

        this.$apiService.get(url).then((res) => {
          this.$set(contract, "monthlyHours", res.data.Time || 0);
          this.$set(
            contract,
            "totalMonthExpenses",
            res.data.totalMonthExpenses
              ? res.data.totalMonthExpenses.$numberDecimal
              : 0
          );
          this.$set(
            contract,
            "monthlyCosts",
            res.data.monthlyCosts ? res.data.monthlyCosts.$numberDecimal : 0
          );

        this.$apiService.get(`/reports/stats/${contract._id}?month=${this.month}&cdotsExpenses=true`).then((res) => {
          this.$set(contract, "monthlyHoursCdotsExpenses", res.data.Time || 0);
        });

        });
      }
    },
  },
  mounted() {
    this.month = "";
    if (this.contractType === "development") this.month = "";
    else this.month = new Date();
    if (this.$route.fullPath.includes("page")) {
      this.$router.replace({ query: {} });
    }
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
</style>
<template>
  <div class="not-found">
    <h1>404</h1>
    <p>Нищо не беше открито на този адрес или нямате достъп до него</p>
  </div>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $header);
  justify-content: center;
  width: 100%;

  h1 {
    color: $secondMainColor;
    font-size: clamp(150px,10vw,10vw);
    animation: pulse-rotate 4s infinite;

      @keyframes pulse-rotate {
        0% {
          transform: scale(0.95);
        }

        70% {
          transform: scale(1);
          opacity: 0.6;
        }

        100% {
          transform: scale(0.95);
        }
      }
  }
  p {
    color: $secondMainColor;
    opacity: 0.5;
    font-size: 1.3rem;
    font-size: clamp(1.3rem,2vw,2vw);
  }
}
</style>
<template>
  <div class="content">
    <template>
      <Table>
        <thead>
          <tr>
            <th class="month-picker">
              <div class="month-to-month">
                <Datepicker
                  icon="Calendar.svg"
                  class="datepicker"
                  :month="true"
                  :disabledDates="disabledDatesFrom"
                  v-model="monthFrom"
                  @input="
                    getContracts(clientFilter._id ? clientFilter._id : '')
                  "
                />
                -
                <Datepicker
                  icon="Calendar.svg"
                  class="datepicker"
                  :month="true"
                  :disabledDates="disabledDatesTo"
                  v-model="monthTo"
                  @input="
                    getContracts(clientFilter._id ? clientFilter._id : '')
                  "
                />
              </div>
            </th>
            <th>
              <Dropdown
                class="inquiry-dropdown"
                placeholder="ВСИЧКИ КЛИЕНТИ"
                icon="dropdown-arrows.svg"
                :dataArray="clients"
                :formatter="(client) => client.name"
                :iconRight="true"
                :typing="true"
                v-model="clientFilter"
                @input="getContracts(clientFilter._id ? clientFilter._id : '')"
                @search="getClients"
              />
            </th>
            <th>
              <Dropdown
                class="inquiry-dropdown"
                placeholder="ДОГОВОР"
                icon="dropdown-arrows.svg"
                :dataArray="contracts"
                :formatter="(contract) => contract.contractName"
                :iconRight="true"
                :disabled="!clientFilter"
                v-model="contractFilter"
              />
            </th>
            <th>
              {{
                contractType === "maintenance"
                  ? "ВКЛЮЧЕНИ ЧАСОВЕ"
                  : "БЮДЖЕТ ЧАСОВЕ"
              }}
            </th>
            <th>
              {{
                contractType === "maintenance"
                  ? "ИЗПОЛЗВАНИ ЧАСОВЕ"
                  : "РЕАЛНИ ЧАСОВЕ"
              }}
            </th>
            <th>
              {{
                contractType === "maintenance" ? "СРЕДНО ИЗРАБОТЕНИ" : "РАЗЛИКА"
              }}
            </th>
            <th></th>
            <th></th>
            <th>
              <img
                src="@/assets/icons/download-excel.svg"
                alt="download-excel-img"
                class="icons"
                @click="exportToExcelFile()"
              />
            </th>
            <th>
              <img
                src="@/assets/icons/preview-graph.svg"
                alt="preview-graph-img"
                class="icons"
                @click="(chartOpen = true), (all = true), (data = contracts)"
              />
            </th>
          </tr>
        </thead>
        <!-- MAINTENANCE AND QUESTION CONTRACTS TABLE -->
        <tbody v-if="contractType === 'maintenance'" ref="contract-table">
          <tr
            v-for="c in contracts"
            :key="`contract-${c._id}`"
            v-show="contractFilter._id ? c._id === contractFilter._id : true"
          >
            <td>{{ c.client.name }}</td>
            <td>{{ c.contractName }}</td>
            <td></td>
            <td v-if="c.contractType === 'maintenance'">
              {{ c.hoursPod.hours }} ч.
            </td>
            <!--question -->
            <td v-else>-</td>
            <!--question -->
            <td v-if="c.contractType === 'maintenance'">
              {{ c.monthlyHours }}
              ч.
            </td>
            <!--question -->
            <td v-else>-</td>
            <!--question -->
            <td v-if="c.contractType === 'maintenance'">
              {{ avgHoursByPeriod(c) }} ч.
            </td>
            <!--question -->
            <td v-else>{{ c.monthlyHours }} ч.</td>
            <!--question -->
            <td></td>
            <td></td>
            <td></td>
            <td>
              <img
                src="@/assets/icons/preview-graph.svg"
                alt="preview-graph-img"
                class="icons"
                @click="(chartOpen = true), (data = c), (all = false)"
              />
            </td>
          </tr>
        </tbody>
        <!-- MAINTENANCE AND QUESTION CONTRACTS TABLE -->

        <!-- DEVELOPMENT CONTRACTS TABLE -->
        <tbody v-else ref="contract-table">
          <tr
            v-for="c in contracts"
            :key="`contract-${c._id}`"
            v-show="contractFilter._id ? c._id === contractFilter._id : true"
          >
            <td>{{ c.client.name }}</td>
            <td>{{ c.contractName }}</td>
            <td></td>
            <td>{{ c.hoursRaz.hours }} ч.</td>
            <td>
              {{ c.monthlyHours }}
              ч.
            </td>
            <td>{{ c.hoursRaz.hours - c.monthlyHours }} ч.</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <img
                src="@/assets/icons/preview-graph.svg"
                alt="preview-graph-img"
                class="icons"
                @click="(chartOpen = true), (data = c), (all = false)"
              />
            </td>
          </tr>
        </tbody>
        <!-- DEVELOPMENT CONTRACTS TABLE -->
      </Table>
      <!-- <Pagination :total="total" :size="size" /> -->
    </template>
    <Chart
      v-if="chartOpen"
      :type="contractType"
      :all="all"
      :data="data"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/Dropdown";
import Table from "@/components/Table";
// import Pagination from "@/components/Pagination";
import Datepicker from "@/components/Datepicker";
import Chart from "@/components/inquiry/Chart";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  components: { Dropdown, Datepicker, Table, Chart },
  props: ["contractType", "clients", "cdotsExpenses"],
  computed: {
    ...mapGetters(["currentUser"]),
    disabledDatesFrom() {
      return { from: this.monthTo };
    },
    disabledDatesTo() {
      return { to: this.monthFrom };
    },
  },
  data() {
    return {
      all: false,
      search: "",
      total: 0,
      size: 20,
      chartOpen: false,
      monthFrom: "",
      monthTo: "",
      clientFilter: "",
      contractFilter: "",
      contracts: [],
      reports: [],
    };
  },
  watch: {
    contractType() {
      this.getContracts();
      this.clientFilter = "";
      this.contractFilter = "";

      if (this.$route.fullPath.includes("page")) {
        this.$router.replace({ query: {} });
      }
    },
    // cdotsExpenses() {
    //   this.getContracts();
    //   this.clientFilter = "";
    //   this.contractFilter = "";
    // },
    $route: [
      {
        handler: "getContracts",
        immediate: true,
      },
    ],
  },
  methods: {
    avgHoursByPeriod(c) {
      let min = Number.MIN_VALUE;
      let max = Number.MAX_VALUE;

      let period =
        this.monthTo.getMonth() -
        this.monthFrom.getMonth() +
        12 * (this.monthTo.getFullYear() - this.monthFrom.getFullYear());

      let value = period
        ? (c.monthlyHours / period).toFixed(2)
        : c.monthlyHours;

      return value > min && value < max ? value : 0;
    },
    exportToExcelFile() {
      const wscols = [{ wpx: 350 }, { wpx: 200 }, { wpx: 100 }];

      const config = { dateNF: "dd/mm/yyyy" };

      // Convert Table to Worksheet
      const workersWorksheet = XLSX.utils.table_to_sheet(
        this.$refs["contract-table"],
        config
      );
      // Define New Workbook
      const new_workbook = XLSX.utils.book_new();

      workersWorksheet["!cols"] = wscols;

      // Append Worksheet
      XLSX.utils.book_append_sheet(new_workbook, workersWorksheet, `Обороти`);
      // Save File

      XLSX.writeFile(
        new_workbook,
        `Обороти ${
          this.clientFilter ? `Клиент - ${this.clientFilter.name}` : "Клиенти"
        }-${this.$options.filters.formatMonth(
          this.monthFrom
        )}-${this.$options.filters.formatMonth(this.monthTo)}.xlsx`
      );
    },
    closeModal() {
      this.chartOpen = false;
    },
    getFilterClients(search) {
      this.clients = [];
      let url = "/clients";

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.clients = res.data.results;
      });
    },
    getClients(search) {
      if (this.contractType === "development") {
        this.$emit("getClients", search, "");
      } else {
        this.$emit("getClients", search ? search : "");
      }
    },
    getContracts(clientId) {
      this.contracts = [];
      this.contractFilter = "";

      let url = `/contracts?contractType=${this.contractType}`;
      // let url = `/contracts?contractType=${this.contractType}&size=${
      //   this.size
      // }&page=${this.$route.query.page || 1}`;

      if (!this.contractType === "development") {
        url += `&active=${true}`;
      }
      if (typeof clientId === "string") {
        url += `&clientId=${clientId}`;
      }
      if (this.contractFilter) {
        url += `&contractName=${this.contractFilter.contractName}`;
      }

      this.$apiService.get(url).then((res) => {
        this.contracts = res.data.results;
        this.total = res.data.total;
        if (this.contracts.length === 1) {
          this.contractFilter = this.contracts[0];
        }
        for (let c of this.contracts) {
          this.getReports(c);
        }
      });
    },
    getReports(contract) {
      if (contract) {
        let url = `/reports/stats/${contract._id}?monthFrom=${this.monthFrom}&monthTo=${this.monthTo}&allReports=true`;

        // if (this.cdotsExpenses) {
        //   url += `&cdotsExpenses=true`;
        // }

        this.$apiService.get(url).then((res) => {
          this.$set(contract, "monthlyHours", res.data.Time || 0);
          this.$set(contract, "extraHours", res.data.extraHours || 0);
        });
      }
    },
  },
  mounted() {
    let date = new Date();
    this.monthFrom = new Date("January 1, 2022 00:00:00");
    this.monthTo = new Date(date.getFullYear(), date.getMonth() + 2, 0);

    if (this.$route.fullPath.includes("page")) {
      this.$router.replace({ query: {} });
    }
  },
};
</script>
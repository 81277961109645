<template>
  <div class="modal-wrapper">
    <transition name="modal" appear>
      <div class="modal">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  methods: {
    handleKeydown(e) {
      if (e.keyCode === 27) this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    document.body.classList.add("noscroll");
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  destroyed() {
    document.body.classList.remove("noscroll");
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style lang="scss">
.bigger-modal {
  .modal {
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: initial;
    width: 100%;
    form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: initial;
      max-width: 1280px;
      width: 100%;
      .form-content {
        width: 100%;
      }
    }
    .report-buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: 100%;
      .button {
        border-radius: 4px;
        max-width: 200px;
      }
    }
  }
}
.modal-wrapper {
  align-items: center;
  background-color: #00000080;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.modal {
  background-color: #e0eef9;
  border-radius: 10px;
  box-shadow: 0 0 50px #00000061;
  max-width: 700px;
  overflow: hidden;
  position: relative;
  width: 700px;
  @include noselect();

  form {
    max-height: 80vh;
    @include scrollbar(5px, #cbd3df);
    .form-content {
      padding: 10px 0 0 0;
      &.reports-wrapper {
        .row {
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .row {
        position: relative;
        margin-bottom: 10px;

        &.row-open {
          .switch-input-wrapper {
            border-radius: 0;
            &:first-child {
              border-radius: $borderRadiusInput $borderRadiusInput 0 0;
            }
            &:last-child {
              border-radius: 0 0 $borderRadiusInput $borderRadiusInput;
            }
          }
        }
        &.row-flex {
          display: flex;
          justify-content: space-between;
          padding: 5px 20px;

          &.row-gap {
            gap: 5px;
            padding: 0;
          }

          .text {
            text-align: left;
            p {
              font: normal normal 600 13px/17px Google Sans;
            }
            span {
              font: normal normal 600 12px/12px Google Sans;
              opacity: 0.6;
            }
          }
          .counter {
            align-items: center;
            display: flex;
            gap: 5px;
            justify-content: space-evenly;
            img {
              cursor: pointer;
              object-fit: scale-down;
            }
            p {
              align-items: center;
              background: #0c518f;
              border-radius: $borderRadius;
              color: white;
              display: flex;
              height: 24px;
              justify-content: center;
              padding: 5px 10px;
            }
          }
        }
        &.expand {
          color: #0099ff;
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          opacity: 0.5;
          @include hover();
          img {
            @include tr();
          }
          &.expand-open {
            padding-bottom: 5px;
            .row-delete {
              img {
                @include transform(initial);
              }
            }
            img {
              @include transform(rotate(-180deg));
            }
          }
        }
      }

      .close {
        border-radius: $borderRadius;
        background: transparent;
        color: black;
        height: fit-content;
        width: fit-content;
        @include hover();
        img {
          width: 22px;
        }
      }

      .inputs--wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 55vh;
      }
      .form-inputs {
        padding: 0 15px;
        position: relative;

        &:hover {
          .row-delete {
            @include tr();
            visibility: visible;
            opacity: 1;
          }
        }
        .row-delete {
          cursor: pointer;
          position: absolute;
          right: 25px;
          top: 10px;
          visibility: hidden;
          opacity: 0;
          z-index: 1;
          @include tr();
        }
        &.form-inputs--reports:not(:last-child) {
          border-bottom: 2px solid #a8becc;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        &.form-inputs--reports {
          .row-delete {
            top: 0;
          }
          &.form-inputs--group-reports {
            border-bottom: initial;
            background: rgb(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
            border-radius: 11px;
            padding: 15px 10px 5px 10px;
            margin: 10px 0;
          }
        }
      }
    }
    header {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: space-between;
      padding: 0 15px;

      &.header-group-reports {
        padding-bottom: 20px;
      }
      section {
        align-items: center;
        display: flex;
        gap: 10px;
      }
      h1 {
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
    .break {
      border: 1px solid #a8becc;
      background-color: #a8becc;
      height: 0px;
      width: 100%;
    }
  }

  .error {
    color: $mainError !important;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    height: fit-content;
    @include transition($transition);
  }
}
@media all and (max-width: $s) {
  .modal-wrapper {
    .modal {
      height: fit-content;
      width: 95%;
      form {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        min-height: 100%;

        .form-content {
          display: flex;
          flex-grow: 2;
          flex-direction: column;

          header {
            gap: 10px;
            section {
              gap: 4px;
            }
          }
          .inputs--wrapper {
            height: 100%;
            max-height: 65vh;
            min-height: 100%;
            &.contract-access {
              max-height: 40vh;
            }
          }
        }
      }
    }
  }
  .bigger-modal {
    .modal {
      form {
        .form-content {
          .inputs--wrapper {
            max-height: 45vh;
            min-height: initial;
          }
        }
        .form-inputs {
          &.form-inputs--reports {
            border-radius: 0;
            .row-delete {
              top: 235px;
            }
          }
        }
        .row-flex {
          &.row-gap {
            flex-direction: column;
            border-radius: 0;
            .contract-status {
              top: 50px;
            }
          }
        }
      }
      .report-buttons {
        flex-direction: column;
        gap: 0;
        .button {
          border-radius: 0;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
<template>
  <div class="content">
    <template>
      <Table>
        <section class="content_wrapper">
          <main class="filters">
            <div v-if="contractType === 'development'" class="month-to-month">
              <Datepicker
                icon="Calendar.svg"
                class="datepicker"
                :month="true"
                :disabledDates="disabledDatesFrom"
                v-model="monthFrom"
                @input="getReports(contractFilter._id)"
              />
              -
              <Datepicker
                icon="Calendar.svg"
                class="datepicker"
                :month="true"
                :disabledDates="disabledDatesTo"
                v-model="monthTo"
                @input="getReports(contractFilter._id)"
              />
            </div>
            <div v-else>
              <Datepicker
                icon="Calendar.svg"
                class="inquiry-dropdown"
                :month="true"
                v-model="month"
                @input="getReports(contractFilter._id)"
              />
            </div>
            <div>
              <Dropdown
                placeholder="ВСИЧКИ КЛИЕНТИ"
                class="inquiry-dropdown"
                icon="dropdown-arrows.svg"
                :dataArray="clients"
                :formatter="(client) => client.name"
                :iconRight="true"
                :typing="true"
                v-model="clientFilter"
                @input="getContracts(clientFilter._id ? clientFilter._id : '')"
                @search="getClients"
              />
            </div>
            <div>
              <Dropdown
                placeholder="ДОГОВОР"
                class="inquiry-dropdown"
                icon="dropdown-arrows.svg"
                :dataArray="contracts"
                :formatter="(contract) => contract.contractName"
                :iconRight="true"
                v-model="contractFilter"
              />
            </div>
          </main>

          <div @click="print()" class="print">
            <img
              v-if="this.month && this.contractFilter && this.clientFilter"
              src="@/assets/icons/download-pdf.svg"
              alt="download-pdf"
              class="icons"
            />
            <p v-if="this.month && this.contractFilter && this.clientFilter">
              СВАЛИ PDF
            </p>
          </div>
        </section>

        <main>
          <p
            class="select-filters"
            v-if="!this.contractFilter || !this.clientFilter"
          >
            ИЗБЕРЕТЕ МЕСЕЦ, КЛИЕНТ И ДОГОВОР
          </p>
          <template v-else>
            <ContractTableMain
              v-if="changeType === 'maintenance'"
              :ref="changeType === 'maintenance' ? 'table' : ''"
              :contract="contractFilter"
              :month="month"
              :reports="reports"
              :cdotsExpensesReports="cdotsExpensesReports"
            />
            <ContractTableDev
              v-if="changeType === 'development'"
              :ref="changeType === 'development' ? 'table' : ''"
              :contract="contractFilter"
              :monthFrom="monthFrom"
              :monthTo="monthTo"
              :reports="reports"
              :cdotsExpensesReports="cdotsExpensesReports"
            />
            <ContractTableQue
              v-if="changeType === 'question'"
              :ref="changeType === 'question' ? 'table' : ''"
              :contract="contractFilter"
              :month="month"
              :reports="reports"
              :cdotsExpensesReports="cdotsExpensesReports"
            />
            <!-- <ContractTable
              :contract="contractFilter"
              :reports="reports"
              :month="month"
              :allReportsDev="allReportsDev"
              :type="changeType"
            /> -->
          </template>
        </main>
      </Table>
    </template>
  </div>
</template>

<script>
import pdfMake from "pdfmake";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import Dropdown from "@/components/Dropdown";
import Table from "@/components/Table";
// import ContractTable from "@/components/inquiry/ContractTable";
import ContractTableMain from "@/components/inquiry/ContractTableMain";
import ContractTableDev from "@/components/inquiry/ContractTableDev";
import ContractTableQue from "@/components/inquiry/ContractTableQue";
import Datepicker from "@/components/Datepicker";
export default {
  components: {
    Dropdown,
    Datepicker,
    Table,
    // ContractTable,
    ContractTableMain,
    ContractTableDev,
    ContractTableQue,
  },
  props: ["contractType", "clients", "cdotsExpenses"],
  computed: {
    ...mapGetters(["currentUser"]),
    disabledDatesFrom() {
      return { from: this.monthTo };
    },
    disabledDatesTo() {
      return { to: this.monthFrom };
    },
  },
  data() {
    return {
      search: "",
      month: "",
      monthFrom: "",
      monthTo: "",
      clientFilter: "",
      contractFilter: "",
      changeType: "",

      total: 0,
      size: 20,

      contracts: [],
      contract: [],
      reports: [],
      cdotsExpensesReports: [],
    };
  },
  watch: {
    "contractFilter._id"() {
      this.changeType = this.contractFilter.contractType;
      this.getContract(this.contractFilter._id);
      this.getReports(this.contractFilter._id);
    },
    contractType() {
      this.getContracts();
      this.clientFilter = "";
      this.contractFilter = "";
    },
    // cdotsExpenses() {
    //   if (this.contractFilter._id) this.getReports(this.contractFilter._id);
    // },
  },
  methods: {
    async print() {
      let month = this.$options.filters.formatMonth(this.month).split(" ");
      let monthFrom = this.$options.filters
        .formatMonth(this.monthFrom)
        .split(" ");
      let monthTo = this.$options.filters.formatMonth(this.monthTo).split(" ");

      const element = this.$refs.table;

      if (element.$el) {
        const canvas = await html2canvas(element.$el, {
          scale: 1.8,
        });
        const data = canvas.toDataURL();

        const width = canvas.width * 0.6; // in points
        const height = canvas.height * 0.6; // in points

        const docDefinition = {
          pageSize: {
            width: width,
            height: height,
          },
          pageMargins: [10, 10, 10, 10],
          content: [
            {
              image: data,
              width: width - 20,
              height: height - 20,
            },
          ],
        };

        pdfMake
          .createPdf(docDefinition)
          .download(
            this.contractFilter.contractType === "development"
              ? `${this.clientFilter.name} - ${monthFrom[0]}/${monthFrom[1]} - ${monthTo[0]}/${monthTo[1]}`
              : `${this.clientFilter.name} - ${month[0]}/${month[1]}`
          );
      }
    },
    getClients(search) {
      this.$emit("getClients", search ? search : "");
    },
    getContracts(clientId) {
      if (clientId) {
        this.contractFilter = "";
        this.contracts = [];
        let url = `/contracts?contractType=${this.contractType}&clientId=${
          typeof clientId == "string" ? clientId : ""
        }&contractName=${
          this.contractFilter.contractName != undefined
            ? this.contractFilter.contractName
            : ""
        }&active=${true}`;
        this.$apiService.get(url).then((res) => {
          this.contracts = res.data.results;
          // if client has only one contract its auto selected
          if (this.contracts.length === 1) {
            this.contractFilter = this.contracts[0];
          }
        });
      }
    },
    getContract(contractId) {
      if (contractId) {
        this.contract = [];
        let url = `/contracts/${
          typeof contractId == "string" ? contractId : ""
        }`;

        this.$apiService.get(url).then((res) => {
          this.contract = res.data;
        });
      }
    },
    getCdotsExpensesReports(clientId) {
      if (clientId) {
        this.loading = true;
        this.cdotsExpensesReports = [];
        let url = `/reports?clientId=${clientId}&montlyClientTab=true&cdotsExpenses=true`;

        if (this.contractType === "development") {
          url += `&monthFrom=${this.monthFrom}&monthTo=${this.monthTo}`;
        } else {
          url += `&month=${this.month}`;
        }

        this.$apiService
          .get(url)
          .then((res) => {
            this.cdotsExpensesReports = res.data.results;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    getReports(clientId) {
      if (clientId) {
        this.loading = true;
        this.reports = [];
        let url = `/reports?clientId=${clientId}&montlyClientTab=true`;

        // if (this.cdotsExpenses) {
        //   url += `&cdotsExpenses=true`;
        // }
        if (this.contractType === "development") {
          url += `&monthFrom=${this.monthFrom}&monthTo=${this.monthTo}`;
        } else {
          url += `&month=${this.month}`;
        }

        this.$apiService
          .get(url)
          .then((res) => {
            this.reports = res.data.results;
          })
          .then(() => {
            this.loading = false;
          });

        if (clientId) {
          let url = `/reports/stats/${clientId}`;

          if (this.contractType === "development") {
            url += `?monthFrom=${this.monthFrom}&monthTo=${this.monthTo}`;
          } else {
            url += `?month=${this.month}`;
          }

          // if (this.cdotsExpenses) {
          //   url += `&cdotsExpenses=true`;
          // }

          this.$apiService.get(url).then((res) => {
            //sets montly stats
            let r = res.data;

            this.$set(this.contractFilter, "Overtime", r.Overtime || 0);
            this.$set(
              this.contractFilter,
              "OvertimeHolidays",
              res.data.OvertimeHolidays || 0
            );
            this.$set(this.contractFilter, "Time", r.Time || 0);
            this.$set(this.contractFilter, "Km", r.Km || 0);
            this.$set(this.contractFilter, "Costs", r.Costs || 0);
            this.$set(this.contractFilter, "BlueZone", r.BlueZone || 0);
            this.$set(this.contractFilter, "GreenZone", r.GreenZone || 0);
            this.$set(this.contractFilter, "extraHours", r.extraHours || 0);

            this.$set(
              this.contractFilter,
              "monthlyHoursPriceMain",
              r.monthlyHoursPriceMain ||
                this.contractFilter.hoursPod.hours *
                  this.contractFilter.hoursPod.priceForHour
            );
            this.$set(
              this.contractFilter,
              "monthlyExtraHoursPriceMain",
              r.monthlyExtraHoursPriceMain || 0
            );
            this.$set(
              this.contractFilter,
              "monthlyHoursPriceQue",
              r.monthlyHoursPriceQue || 0
            );
            this.$set(
              this.contractFilter,
              "totalOvertimePriceMain",
              r.totalOvertimePriceMain || 0
            );
            this.$set(
              this.contractFilter,
              "totalOvertimePriceQue",
              r.totalOvertimePriceQue || 0
            );
            this.$set(
              this.contractFilter,
              "totalOvertimeHPriceMain",
              r.totalOvertimeHPriceMain || 0
            );
            this.$set(
              this.contractFilter,
              "totalOvertimeHPriceQue",
              r.totalOvertimeHPriceQue || 0
            );
            this.$set(this.contractFilter, "KmPrice", r.KmPrice || 0);
            this.$set(
              this.contractFilter,
              "BlueZonePrice",
              r.BlueZonePrice || 0
            );
            this.$set(
              this.contractFilter,
              "GreenZonePrice",
              r.GreenZonePrice || 0
            );

            this.$set(
              this.contractFilter,
              "totalDistancePrice",
              r.totalDistancePrice
                ? Number(r.totalDistancePrice.$numberDecimal)
                : 0
            );
            this.$set(
              this.contractFilter,
              "TotalDistance",
              r.TotalDistance ? Number(r.TotalDistance.$numberDecimal) : 0
            );
            this.$set(
              this.contractFilter,
              "totalMonthExpenses",
              r.totalMonthExpenses
                ? Number(r.totalMonthExpenses.$numberDecimal)
                : this.contractFilter.contractType === "question"
                ? this.contractFilter.hosting.price
                : this.contractFilter.hoursPod.hours *
                    this.contractFilter.hoursPod.priceForHour +
                  this.contractFilter.hosting.price
            );
          });
          this.getCdotsExpensesReports(clientId);
        }

        this.getAllReportsDev(clientId);
      }
    },
    getAllReportsDev(clientId) {
      // gets all reports from the development contract
      if (clientId) {
        //sets all time stats
        let url = `/reports/stats/${clientId}`;

        this.$apiService.get(url).then((res) => {
          this.$set(this.contractFilter, "hoursLeftDev", res.data.hoursLeftDev);
          this.$set(
            this.contractFilter,
            "avgPriceDevTotal",
            res.data.avgPriceDevTotal
          );
        });
      }
    },
  },
  mounted() {
    let prevMonth = new Date();
    prevMonth.setDate(1);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    this.month = prevMonth;
    this.monthFrom = prevMonth;
    this.monthTo = prevMonth;
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.content_wrapper {
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  height: 70px;
  justify-content: space-between;

  .filters {
    div {
      margin: 0 10px;
    }
  }
  .print {
    align-items: center;
    display: flex;
    cursor: pointer;
    font: normal normal bold 11px/14px Google Sans;
    text-align: right;
    margin-right: 20px;
    &:hover {
      img {
        background: #e8e8e8;
      }
    }
    img {
      border-radius: 13px;
      padding: 8px;
      @include tr();
    }
  }
}
main {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 110px;
  height: fit-content;
  .select-filters {
    font: normal normal bold 11px/14px Google Sans;
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import vClickOutside from 'v-click-outside'
import {ApiService} from './common/api.service'
import Snotify from 'vue-snotify'

Vue.prototype.$apiService = ApiService
Vue.prototype.$moment = moment;

Vue.use(vClickOutside)
Vue.use(Snotify, {
  toast: {
    position: 'rightBottom',
    showProgressBar: false,
    timeout: 5000
  }
})

Vue.filter('formatDate', (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : 'ДД/ММ/ГГГГ'
})

Vue.filter('formatMonth', (date) => {
  return date ? moment(date).locale("bg").format('MMMM YYYY') : 'ММММ ГГГГ'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <TheHeader v-if="this.$route.name !== 'login'" />
    <router-view />
    <vue-snotify />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader";
export default {
  components: {
    TheHeader,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    $route() {
      window.scroll(0, 0);
    },
  },
  mounted() {
    // Color Modes
    const element = document.body;
    const colorMode = localStorage.getItem("colorMode");
    const reportsMode = localStorage.getItem("reportsMode");
    localStorage.setItem(
      "reportsMode",
      reportsMode ? reportsMode : "reportsAllFields"
    );

    if (colorMode && colorMode !== "auto") {
      element.classList.add(colorMode);
    } else {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        element.classList.add("dark-mode");
        localStorage.setItem("colorMode", "auto");
      } else {
        element.classList.add("light-mode");
        localStorage.setItem("colorMode", "auto");
      }
    }
    // Color Modes
  },
};
</script>

<style lang="scss">
// @import "~vue-snotify/styles/material";
// @import "@/scss/darkmode.scss";
// @import "@/scss/reset.scss";
// @import "@/scss/main.scss";
// @import "@/scss/base.scss";
// @import "@/scss/table.scss";
// @import "@/scss/snotify.scss";
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Google Sans";
  src: url("./assets/fonts/GoogleSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/GoogleSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

#app {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
body {
  color: black;
}

// // Print
// @media print {
//   @page {
//     size: auto;
//   }
//   header,
//   .navs,
//   .content_wrapper,
//   .search {
//     display: none !important;
//   }
//   .table__container {
//     padding: 0 !important;
//   }
//   .table-wrapper {
//     box-shadow: initial !important;
//   }
//   .table {
//     max-width: initial !important;
//     -webkit-print-color-adjust: exact;
//     .bigger-width {
//       max-width: 250px;
//     }
//   }
//   // IF USER IS IN DARK MODE WHILE PRINTING
//   .dark-mode {
//     color: black !important;

//     .table {
//       background: white !important;
//       max-width: initial !important;
//       -webkit-print-color-adjust: exact;
//       color: black;
//       .bigger-width {
//         max-width: 250px;
//       }
//       tbody {
//         tr {
//           &:nth-child(odd) {
//             background: #efefef !important;
//           }
//         }
//       }
//     }
//     //  contractTable
//     .contract-info {
//       tr,
//       td {
//         background: #f6f6f6 0% 0% no-repeat padding-box !important;
//         border-bottom: 5px solid white !important;
//       }
//     }
//     .total-sum {
//       background: #e1e1e1 !important;
//     }
//   }
//   // IF USER IS IN DARK MODE WHILE PRINTING
//   .main-title {
//     align-items: center;
//     background: #f6f6f6;
//     display: flex;
//     padding: 10px !important;
//     justify-content: space-between;
//     text-align: right;
//     h1 {
//       font-size: 18px !important;
//       padding-left: 15px;
//     }
//     img {
//       display: block !important;
//     }
//   }
// }
// // Print
</style>

<template>
  <div
    class="dropdown-wrapper"
    :class="{
      'dropdown-container--open': isDropdownOpen,
      'dropdown-wrapper--invalid': invalid,
      'dropdown-wrapper--disabled': disabled,
      'dropdown-wrapper--up': up,
    }"
    v-click-outside="closeDropdown"
  >
    <img
      v-if="!iconRight"
      class="icons"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="icon"
    />

    <div
      class="input"
      @click="
        disabled
          ? null
          : typing
          ? (isDropdownOpen = true)
          : (isDropdownOpen = !isDropdownOpen)
      "
    >
      <input
        :class="{ 'icon-right': iconRight }"
        :type="type || 'text'"
        :placeholder="placeholder"
        v-if="!value"
        :disabled="typing || !disabled ? false : true"
        v-model="search"
      />
      <div class="selected-data" :class="{ 'icon-right': iconRight }" v-else>
        <template v-if="multiselect">
          <p class="placeholder" v-if="!value[0].name">{{ placeholder }}</p>
          <span v-for="(data, i) in value" :key="`data-${i}`">
            <span v-if="i">, </span><span>{{ formatData(data) }}</span>
          </span>
        </template>
        <span>{{ dataAsObject ? dataArray[value] : formatData(value) }}</span>
      </div>
    </div>

    <img
      @click="disabled ? null : (isDropdownOpen = !isDropdownOpen)"
      v-if="iconRight"
      class="icons right"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="icon"
    />

    <div
      class="options-wrapper"
      :class="{ 'options-showing-up': dropdown }"
      v-if="isDropdownOpen"
    >
      <template
        v-if="dataAsObject ? true : dataArray ? dataArray.length : null"
      >
        <p
          v-if="multiselect && this.sizeValueArray !== this.sizeDataArray - 1"
          class="option"
          @click="selectAll()"
        >
          Изберете всички
        </p>
        <p
          v-if="
            (multiselect && this.sizeValueArray === this.sizeDataArray - 1) ||
            (value && !multiselect)
          "
          class="option"
          @click="deselectAll()"
        >
          Изчисти всички
        </p>

        <!-- LAST REPORTS -->

        <div v-if="lastReports && !search">
          Последно отчетени:
          <span
            class="option lastReports"
            @click="onDataSelected(data, i)"
            v-for="(data, i) in lastReports"
            :key="`data-${i}`"
          >
            {{ formatData(data) }}
          </span>

          <hr />
        </div>

        <!-- LAST REPORTS -->

        <div v-if="multiselect">
          <span
            class="option"
            :class="{ 'option--notpresent': data.notpresent }"
            v-for="(data, i) in dataArray"
            :key="`data-${i}`"
            @click="onMultipleDataSelected(data, i)"
          >
            {{ formatData(data) }}

            <Checkbox
              class="checkbox"
              notRadio="true"
              :checked="
                typeof dataArray[0] === 'object'
                  ? data.selected
                  : value
                  ? value.indexOf(data) > -1
                  : false
              "
            />
          </span>
        </div>
        <div v-else>
          <span
            class="option"
            @click="onDataSelected(data, i)"
            v-show="
              lastReports ? i < dataArray.length - lastReports.length : true
            "
            v-for="(data, i) in dataArray"
            :key="`data-${i}`"
          >
            {{ formatData(data) }}
          </span>
        </div>
      </template>
      <p v-else class="no-results">Няма резултати</p>
    </div>
  </div>
</template>

<script>
import Checkbox from "./Checkbox";
import vClickOutside from "v-click-outside";
export default {
  data() {
    return {
      isDropdownOpen: false,
      search: "",
      sizeDataArray: 0,
      sizeValueArray: 0,
    };
  },
  components: {
    Checkbox,
  },
  props: [
    "value",
    "multiselect",
    "placeholder",
    "dataArray",
    "dataAsObject", // use if data is an object, object value is displayed and on select object key is selected (done for nonmultiselects only)
    "formatter",
    "dropdown", //showing content options up
    "typing", // allow typing,
    "type",
    "icon",
    "up",
    "iconRight",
    "disabled",
    "invalid",
    "identifier", // when working with nonprimitive types an unique field for comparison is needed
    "lastReports",
  ],
  watch: {
    search() {
      this.$emit("search", this.search);
    },
    value() {
      this.handleChanges();
      this.sizeDataArray = Object.keys(this.dataArray).length;
      this.sizeValueArray = Object.keys(this.value).length;
    },
    dataArray() {
      this.handleChanges();
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    handleChanges() {
      if (this.value && this.dataArray && this.multiselect && this.identifier) {
        for (let v of this.value) {
          const i = this.dataArray.findIndex(
            (da) => da[this.identifier] === v[this.identifier]
          );
          if (i > -1) {
            this.$set(this.dataArray[i], "selected", true);
          } else {
            const x = { ...v, selected: false, notpresent: true };
            this.dataArray.push(x);
          }
        }
      }
    },
    closeDropdown() {
      this.isDropdownOpen = false;
      this.search = "";
    },
    formatData(data) {
      if (this.formatter) {
        return this.formatter(data);
      } else {
        return data;
      }
    },
    onDataSelected(data, i) {
      this.selectedData = this.dataAsObject ? i : data;
      this.$emit("input", this.selectedData);
      // this.$emit("search", "");
      this.closeDropdown();
    },
    selectAll() {
      this.$emit(
        "input",
        this.dataArray.filter((d) => d._id)
      );
    },
    deselectAll() {
      this.$emit("input", "");
      if (this.multiselect) {
        this.value.filter((v) => (v.selected = false));
      }
    },
    onMultipleDataSelected(data) {
      if (typeof data === "object") {
        this.$set(data, "selected", !data.selected);

        this.$emit(
          "input",
          this.dataArray.filter((d) => d.selected)
        );
      } else {
        let output = [];
        if (this.value) {
          output = this.value.slice();
          const i = output.indexOf(data);
          if (i > -1) output.splice(i, 1);
          else output.push(data);
        }

        this.$emit("input", output);
      }
    },
  },
};
</script>

<style lang="scss">
// @import "@/scss/base.scss";
.dropdown-wrapper {
  position: relative;
  min-width: 200px;
  width: 100%;

  &.dropdown-wrapper--up {
    .options-wrapper {
      bottom: 100%;
      top: initial;
    }
  }
  &.dropdown-wrapper--invalid {
    input {
      border: 1px solid $mainError;
    }
  }
  &.dropdown-wrapper--disabled {
    opacity: 0.3;
  }
  .icons {
    left: 27.5px;
    position: absolute;
    top: 20px;
    @include transform(translateY(-50%) translateX(-50%));
    &.right {
      left: 90%;
    }
  }
  &.client-search {
    input,
    .selected-data {
      border: 1px solid transparent !important;
      background: transparent !important;
    }
    &.dropdown-wrapper--invalid {
      input {
        border-bottom: 1px solid $mainError !important;
      }
    }
  }
  input {
    // background: #e4e8ec;
    background: #ffffff;
    // border: 1px solid #e4e8ec;
    border: 1px solid #cdd9e5;
    border-radius: $borderRadiusInput;
    color: #000000e4;
    font: italic normal 700 14px/17px Google Sans;
    height: 40px;
    outline: none;
    overflow-y: hidden;
    padding: 0 20px 0 50px;
    width: 100%;
    @include tr();
    &.right_input {
      padding: 0 20px;
    }
    &.icon-right {
      padding: 0 20px 0 10px;
    }
  }
  .selected-data {
    align-items: center;
    // background: #e4e8ec;
    background: #ffffff;
    // border: 1px solid #e4e8ec;
    border: 1px solid #cdd9e5;
    border-radius: $borderRadiusInput;
    display: flex;
    color: #000000e4;
    font: italic normal 700 14px/17px Google Sans;
    height: 40px;
    outline: none;
    overflow: auto;
    overflow-y: hidden;
    padding: 0 20px 0 50px;
    width: 100%;
    white-space: nowrap;
    @include scrollbar(5px, $mainColor);

    .placeholder {
      display: flex;
      font-size: 15px;
      font-weight: 700;
      width: 100%;
    }
    &.icon-right {
      padding: 0 20px 0 10px;
    }
  }
  .options-wrapper {
    background: #fff;
    box-shadow: 0 0 50px rgb(0 0 0 / 38%);
    border: 1px solid #e4e8ec;
    border-radius: $borderRadius;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 2;

    @include tr();
    &.options-showing-up {
      top: calc(100% + -182px);
    }
  }
  hr {
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    height: 0px;
    margin-left: -10px;
  }
  .option {
    align-items: center;
    display: flex;
    cursor: pointer;
    font-size: $px12;
    justify-content: space-between;
    min-height: 40px;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    white-space: initial;
    @include noselect();
    &.option--notpresent {
      display: none;
    }
    &:hover {
      background-color: rgba($color: $input, $alpha: 0.4);
    }
    img {
      height: 20px;
    }
  }

  .no-results {
    align-items: center;
    display: flex;
    font-size: $px12;
    height: 40px;
    line-height: $px12;
    padding: 0 1rem;
  }
}
.inquiry-dropdown {
  input,
  .selected-data {
    border: 1px solid #707070;
    border-radius: $borderRadius;
    height: 33px;
  }
  .icons {
    top: 50%;
  }
}
@media all and (max-width:$s) {
  .dropdown-wrapper {
    &.dropdown-wrapper--contract-status {
      .input {
        input,
        .selected-data {
          padding: 40px 0 10px 15px;
          height: auto;
        }
      }
    }
  }
}
</style>

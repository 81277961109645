import { render, staticRenderFns } from "./Issues.vue?vue&type=template&id=60029d07"
import script from "./Issues.vue?vue&type=script&lang=js"
export * from "./Issues.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.38_css-loader@6.11.0_webpack@5.94.0__handlebars@4.7._m2icaavtym56oajca3bknhruuu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <main
    class="switch-input-wrapper"
    :class="{
      'switch-input-wrapper--invalid': invalid,
      'switch-input-wrapper--disabled': disabled,
      'switch-input-wrapper--main-switch': withSwitch,
      'switch-input-wrapper--two-slots': twoSlots,
    }"
  >
    <div>
      <img
        v-if="icon"
        class="icons"
        :src="`${require(`@/assets/icons/${icon}`)}`"
        alt="icon"
      />
      <p>{{ placeholder }}</p>
      <span>{{ label }}</span>
    </div>
    <div class="div-slot" :class="{ 'two-slots': twoSlots }">
      <Slider
        v-if="withSwitch"
        @change="changeStatus($event, newValue)"
        :checked="value === newValue"
      />
      <slot />
    </div>
  </main>
</template>

<script>
import Slider from "@/components/Slider";
export default {
  props: [
    "value",
    "newValue",
    "placeholder",
    "label",
    "icon",
    "invalid",
    "disabled",
    "withSwitch",
    "twoSlots",
    "oldValue",
  ],
  components: {
    Slider,
  },
  methods: {
    changeStatus(state, v) {
      this.$emit("input", state ? v : this.oldValue ? this.oldValue :"");
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.switch-input-wrapper {
  align-items: center;
  background: #ffffff;
  border-radius: $borderRadiusInput;
  border: 1px solid #cdd9e5;
  display: flex;
  height: 45px;
  justify-content: space-between;
  text-align: left;
  padding: 0 10px 0 50px;
  position: relative;
  &.switch-input-wrapper--main-switch {
    height: 71px;
  }

  .div-slot {
    max-width: 160px;
    &.two-slots {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      max-width: initial;
      min-width: 100%;
      .input-wrapper {
        &:last-child {
          max-width: 160px;
        }
      }
    }
  }

  .icons {
    left: 27.5px;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));
  }
  p {
    font: italic normal bold 13px/17px Google Sans;
  }
  span {
    font: italic normal normal 13px/17px Google Sans;
  }
}
@media all and (max-width:$s) {
  .switch-input-wrapper {
    &.switch-input-wrapper--two-slots {
      height: fit-content;
      padding: 5px 10px 5px 50px;
    }
    .div-slot {
      .report-hours,
      .input-wrapper {
        max-width: 80px;
        &.report-hours--open {
          max-width: 160px;
        }
      }

      &.two-slots {
        gap: 5px;
        flex-direction: column;
        height: fit-content;
        .input-wrapper {
          max-width: 100%;
          &:last-child {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
<template>
  <main class="inquiry-contract-table--wrapper">
    <section class="text printing">
      <img
        class="cdots-logo--print"
        src="@/assets/icons/logo-cdots-reports.svg"
        alt=""
      />
      <div>
        <h1>МЕСЕЧЕН ОТЧЕТ ЗА {{ month | formatMonth }}</h1>
        <h1>{{ contract.client.name }},</h1>
        <h1>Договор: {{ contract.contractName }}</h1>
      </div>
    </section>

    <div class="text space-between">
      <p></p>
      <p>УСЛОВИЯ ПО ДОГОВОР</p>
      <p>ОБЩО</p>
    </div>

    <section class="table contract-info">
      <!-- FIRSTTTTT -->
      <tbody>
        <tr>
          <td>Използвани часове</td>
          <td>{{ contract.Time }} ч.</td>
        </tr>
        <tr v-if="contract.TotalDistance">
          <td>Часове за пропътуване</td>
          <td>{{ contract.TotalDistance }} ч.</td>
        </tr>
        <tr v-if="contract.Km">
          <td>Пропътувани км</td>
          <td>{{ contract.Km }} км.</td>
        </tr>
        <tr v-if="contract.BlueZone">
          <td>Синя зона</td>
          <td>{{ contract.BlueZone }} ч.</td>
        </tr>
        <tr v-if="contract.GreenZone">
          <td>Зелена зона</td>
          <td>{{ contract.GreenZone }} ч.</td>
        </tr>
        <tr v-if="contract.Overtime">
          <td>ИРВ 1</td>
          <td>{{ contract.Overtime }} ч.</td>
        </tr>
        <tr v-if="contract.OvertimeHolidays">
          <td>ИРВ 2</td>
          <td>{{ contract.OvertimeHolidays }} ч.</td>
        </tr>
        <tr>
          <td>Хостинг</td>
          <td>{{ contract.hosting.hostingSpace }} MB</td>
        </tr>
        <tr v-if="contract.Costs">
          <td>Други разходи</td>
          <td>{{ contract.Costs }} лв.</td>
        </tr>
      </tbody>

      <!--  SECONDDDD  -->

      <tbody>
        <tr>
          <td>Цена на час</td>
          <td>{{ contract.hoursZap.priceForHour.toFixed(2) }}лв.</td>
        </tr>
        <tr v-if="contract.TotalDistance">
          <td>Цена на час пропътуване</td>
          <td>{{ contract.travel.distance.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Km">
          <td>Цена на км.</td>
          <td>{{ contract.travel.pricePerKm.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.BlueZone">
          <td>Цена синя зона</td>
          <td>{{ contract.travel.blueZone.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.GreenZone">
          <td>Цена зелена зона</td>
          <td>{{ contract.travel.greenZone.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Overtime">
          <td>Цена ИРВ1 х 1.5</td>
          <td></td>
        </tr>
        <tr v-if="contract.OvertimeHolidays">
          <td>Цена ИРВ2 х 2</td>
          <td></td>
        </tr>
        <tr>
          <td>Цена</td>
          <td>{{ contract.hosting.price.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Costs">
          <td>-</td>
          <td></td>
        </tr>
      </tbody>

      <!--  THIRDDDD -->

      <tbody class="last">
        <tr>
          <td>
            {{
              contract.monthlyHoursPriceQue
                ? contract.monthlyHoursPriceQue.toFixed(2)
                : (0).toFixed(2)
            }}
            лв.
          </td>
        </tr>
        <tr v-if="contract.TotalDistance">
          <td>{{ contract.totalDistancePrice.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Km">
          <td>{{ contract.KmPrice.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.BlueZone">
          <td>{{ contract.BlueZonePrice.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.GreenZone">
          <td>{{ contract.GreenZonePrice.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Overtime">
          <td>{{ contract.totalOvertimePriceQue.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.OvertimeHolidays">
          <td>{{ contract.totalOvertimeHPriceQue.toFixed(2) }} лв.</td>
        </tr>
        <tr>
          <td>{{ contract.hosting.price.toFixed(2) }} лв.</td>
        </tr>
        <tr v-if="contract.Costs">
          <td>{{ contract.Costs.toFixed(2) }} лв.</td>
        </tr>
      </tbody>
    </section>

    <div class="total-sum" v-if="contract.totalMonthExpenses">
      <p>ОБЩО</p>
      <p>{{ contract.totalMonthExpenses.toFixed(2) }} лв.</p>
    </div>

    <div class="text">
      <h1>СПИСЪК С НАПРАВЕНИТЕ ОТЧЕТИ ЗА ПЕРИОДА</h1>
    </div>
    <div class="reports-wrapper">
      <section class="table">
        <thead>
          <tr>
            <th>СЪЗДАТЕЛ</th>
            <th>ДАТА</th>
            <th class="bigger-width">ОПИСАНИЕ</th>
            <th>ВРЕМЕ</th>
            <th>КИЛОМЕТРИ</th>
            <th>ПРОПЪТУВАНЕ</th>
            <th>СИНЯ ЗОНА</th>
            <th>ЗЕЛЕНА ЗОНА</th>
            <th>ДРУГИ РАЗХОДИ</th>
            <th>ИРВ</th>
            <th>ИРВ2</th>
          </tr>
        </thead>
        <tbody v-if="!reports.length">
          <tr>
            <td colspan="15" class="center">
              <em>Няма отчети за {{ month | formatMonth }} </em>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="r in reports" :key="`reports-${r._id}`">
            <td>{{ r.creator.name }}</td>
            <td>{{ r.fromDate | formatDate }}</td>
            <td class="bigger-width">{{ r.description }}</td>
            <td>{{ `${r.time.hours}.${r.time.minutes}` }} ч.</td>
            <td>{{ r.km }} км</td>
            <td>{{ `${r.distance.hours}.${r.distance.minutes}` }} ч.</td>
            <td>{{ r.blueZone }} ч.</td>
            <td>{{ r.greenZone }} ч.</td>
            <td>{{ r.costs }} лв.</td>
            <td>{{ `${r.overtime.hours}.${r.overtime.minutes}` }}ч.</td>
            <td>
              {{
                `${r.overtimeHolidays.hours}.${r.overtimeHolidays.minutes}`
              }}ч.
            </td>
          </tr>
        </tbody>
      </section>
    </div>
    <div class="text">
      <h1>СПИСЪК С НАПРАВЕНИТЕ ОТЧЕТИ ЗА ПЕРИОДА, ЗА СМЕТКА НА CDOTS</h1>
    </div>
    <div class="reports-wrapper">
      <section class="table">
        <thead>
          <tr>
            <th>СЪЗДАТЕЛ</th>
            <th>ДАТА</th>
            <th class="bigger-width">ОПИСАНИЕ</th>
            <th>ВРЕМЕ</th>
            <th>КИЛОМЕТРИ</th>
            <th>ПРОПЪТУВАНЕ</th>
            <th>СИНЯ ЗОНА</th>
            <th>ЗЕЛЕНА ЗОНА</th>
            <th>ДРУГИ РАЗХОДИ</th>
            <th>ИРВ</th>
            <th>ИРВ2</th>
          </tr>
        </thead>
        <tbody v-if="!cdotsExpensesReports.length">
          <tr>
            <td colspan="15" class="center">
              <em>Няма отчети за {{ month | formatMonth }} </em>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="r in cdotsExpensesReports"
            :key="`cdotsExpensesReports-${r._id}`"
          >
            <td>{{ r.creator.name }}</td>
            <td>{{ r.fromDate | formatDate }}</td>
            <td class="bigger-width">{{ r.description }}</td>
            <td>{{ `${r.time.hours}.${r.time.minutes}` }} ч.</td>
            <td>{{ r.km }} км</td>
            <td>{{ `${r.distance.hours}.${r.distance.minutes}` }} ч.</td>
            <td>{{ r.blueZone }} ч.</td>
            <td>{{ r.greenZone }} ч.</td>
            <td>{{ r.costs }} лв.</td>
            <td>{{ `${r.overtime.hours}.${r.overtime.minutes}` }}ч.</td>
            <td>
              {{
                `${r.overtimeHolidays.hours}.${r.overtimeHolidays.minutes}`
              }}ч.
            </td>
          </tr>
        </tbody>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  props: ["contract", "reports", "month", "cdotsExpensesReports"],
};
</script>

<template>
  <main class="buttons_wrapper">
    <img
      v-if="icon"
      class="icons"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="icon"
    />
    <div class="buttons" :class="{ buttons_invalid: invalid }">
      <slot></slot>
    </div>
  </main>
</template>

<script>
export default {
  props: ["icon", "invalid"],
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.buttons_wrapper {
  position: relative;
  margin: 0 auto;
  .icons {
    left: 27.5px;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));
  }
  .buttons {
    align-items: center;
    // background: #e4e8ec;
    background: #ffffff;
    // border: 1px solid #e4e8ec;
    border: 1px solid #cdd9e5;
    border-radius: $borderRadiusInput;
    display: flex;
    justify-content: flex-end;
    height: 40px;
    width: 100%;
    overflow: auto;
    overflow-y: hidden;
    @include tr();
    @include scrollbar(5px, $mainColor);

    div {
      padding: 5px 10px;
    }
    &.buttons_invalid {
      border: 1px solid $mainError;
    }
    .minutes-img,
    .hours-img {
      margin-right: -6px;
    }

    .btn-option {
      align-items: center;
      background: #5f7387 0% 0% no-repeat padding-box;
      border-radius: $borderRadius;
      cursor: pointer;
      color: white;
      display: flex;
      height: 24px;
      margin: 2px 5px;
      justify-content: center;
      width: 24px;
      @include hover();
      &.selected {
        background: #0d66d0;
      }
    }

    div {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      max-height: 30px;
      input,
      .input {
        align-items: center;
        background: #5f7387 0% 0% no-repeat padding-box;
        border: 1px solid #5f7387;
        border-radius: $borderRadius;
        color: white;
        font: italic normal normal 14px/17px Google Sans;
        justify-content: center;
        outline: none;
        text-align: center;
        max-width: 45px;
        margin-left: 5px;
        width: 45px;
      }

      p {
        white-space: nowrap;
      }
      main {
        width: 33%;
        display: flex;
        flex-wrap: wrap;
        color: white;
        justify-content: center;
      }
    }
    .button {
      align-self: center;
      background: #5f7387;
      border-radius: $borderRadius !important;
      font-size: 13px;
      height: 28px;
      margin-right: 5px;
      width: max-content;
      @include hover();
      &.selected {
        background: #0d66d0;
      }
      &.maintenance {
        background: #7bc94e;
      }
      &.development {
        background: #7ab8e1;
      }
      &.question {
        background: #d8a890;
      }
    }
  }
}
@media all and (max-width:$s) {
  .buttons_wrapper {
    .buttons {
      justify-content: space-between;
      padding-left: 40px;
    }
  }
}
</style>
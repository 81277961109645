<template>
  <transition name="table-transition" appear>
    <div class="login">
      <form>
        <img
          src="@/assets/login/login-dark.svg"
          alt="login-logo"
          class="white-logo"
        />
        <img
          src="@/assets/login/login-light.svg"
          alt="login-logo"
          class="black-logo"
        />
        <section class="inputs">
          <div>
            <Input
              placeholder="e-mail"
              type="text"
              icon="mail.svg"
              :invalid="invalid.email"
              @input="(invalid.email = false), (error = '')"
              @enter="login"
              v-model="user.email"
            />
            <Input
              placeholder="password"
              type="password"
              icon="pass.svg"
              :invalid="invalid.password"
              @input="(invalid.password = false), (error = '')"
              @enter="login"
              v-model="user.password"
            />
          </div>
          <p class="error">
            <transition name="table-transition" appear>
              <span v-if="error">{{ error }}</span>
            </transition>
          </p>
          <Button @click="login">ВХОД</Button>
        </section>
      </form>

      <a href="https://cdots.bg/" target="_blank"
        ><img src="@/assets/login/cdots-logo.svg" alt="asd"
      /></a>
    </div>
  </transition>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";
export default {
  components: {
    Button,
    Input,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      error: "",
      invalid: {
        invalid: false,
      },
    };
  },
  methods: {
    isValid() {
      if (!this.user.email) {
        this.invalid.email = true;
        this.error = "Моля въведете e-mail";
        return false;
      } else if (!this.user.password) {
        this.invalid.password = true;
        this.error = "Моля въведете парола";
        return false;
      }

      return true;
    },
    login() {
      if (this.isValid()) {
        this.error = "";
        this.$store
          .dispatch("login", this.user)
          .then(() => {
            this.$router.push(
              this.$route.query.from ? this.$route.query.from : "/reports"
            );
          })
          .catch((err) => {
            this.error = err.response.data.message;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.login {
  align-items: center;
  background: #e7ebef 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;

  img {
    object-fit: scale-down;
  }
  form {
    align-items: center;
    display: flex;
    flex-basis: 90%;
    flex-direction: column;
    justify-content: center;

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 99px #0000004d;
      border-radius: 10px;
      width: 110%;
      height: 200px;
      margin-top: -130px;

      .button {
        border-radius: 0px 0px 10px 10px;
      }
      .input-wrapper:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
    .error {
      color: $mainError;
      display: flex;
      flex-direction: column;
      height: 10px;
      margin-bottom: 15px;
      @include tr();
      span{
        padding-top: 5px;
      }
    }
  }
}
</style>
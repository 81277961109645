var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('header',[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/reports"}},[_c('img',{staticClass:"white-logo",attrs:{"src":require("../assets/header/logo_dark.svg"),"alt":"logo"}}),_c('img',{staticClass:"black-logo",attrs:{"src":require("../assets/header/logo_light.svg"),"alt":"logo"}})])],1),_c('div',{staticClass:"links"},[_c('router-link',{attrs:{"to":"/reports"}},[_c('img',{staticClass:"route-img icons",attrs:{"src":require("../assets/header/reports.svg"),"alt":"reports"}}),_c('img',{staticClass:"route-img-active icons",attrs:{"src":require("../assets/header/reports-active.svg"),"alt":"reports"}}),_c('img',{staticClass:"route-img-hover",attrs:{"src":require("../assets/header/reports-hover.svg"),"alt":"reports"}}),_c('p',[_vm._v("ОТЧЕТИ")])]),_c('router-link',{class:{ disabled:_vm.currentUser.role!=='administrator' },attrs:{"to":"/inquiry"}},[_c('img',{staticClass:"route-img icons",attrs:{"src":require("../assets/header/inquiry.svg"),"alt":"inquiry"}}),_c('img',{staticClass:"route-img-active icons",attrs:{"src":require("../assets/header/inquiry-active.svg"),"alt":"inquiry"}}),_c('img',{staticClass:"route-img-hover",attrs:{"src":require("../assets/header/inquiry-hover.svg"),"alt":"inquiry"}}),_c('p',[_vm._v("СПРАВКИ")])]),_c('router-link',{class:{
          disabled:_vm.currentUser.role!=='administrator'
        },attrs:{"to":"/nomenclature"}},[_c('img',{staticClass:"route-img icons",attrs:{"src":require("../assets/header/nomenclature.svg"),"alt":"nomenclature"}}),_c('img',{staticClass:"route-img-active icons",attrs:{"src":require("../assets/header/nomenclature-active.svg"),"alt":"nomenclature"}}),_c('img',{staticClass:"route-img-hover",attrs:{"src":require("../assets/header/nomenclature-hover.svg"),"alt":"inquiry"}}),_c('p',[_vm._v("НОМЕНКЛАТУРИ")])]),_c('router-link',{class:{ disabled: _vm.currentUser.role !== 'administrator' },attrs:{"to":"/issues"}},[_c('img',{staticClass:"route-img icons",attrs:{"src":require("../assets/header/issues.svg"),"alt":"issues"}}),_c('img',{staticClass:"route-img-active icons",attrs:{"src":require("../assets/header/issues-active.svg"),"alt":"issues"}}),_c('img',{staticClass:"route-img-hover icons",attrs:{"src":require("../assets/header/issues-hover.svg"),"alt":"issues"}}),_c('p',[_vm._v("СИГНАЛИ")])])],1),_c('div',{staticClass:"user-exit"},[_c('img',{staticClass:"userIcon icons",attrs:{"src":require("@/assets/icons/user-username.svg"),"alt":"userIcon"},on:{"click":function($event){(_vm.userModalOpen = true),
            (_vm.userId = _vm.currentUser._id),
            (_vm.modalType = 'previewFromHeader')}}}),_c('p',{on:{"click":function($event){(_vm.userModalOpen = true),
            (_vm.userId = _vm.currentUser._id),
            (_vm.modalType = 'previewFromHeader')}}},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('span',[_c('img',{staticClass:"icons exit-img",attrs:{"src":require("../assets/header/exit.svg"),"alt":"exit"},on:{"click":_vm.logout}}),_c('img',{staticClass:"exit-hover",attrs:{"src":require("../assets/header/exit-hover.svg"),"alt":"exit"},on:{"click":_vm.logout}}),_c('img',{staticClass:"exit-hover-darkMode",attrs:{"src":require("../assets/header/exit-hover-darkMode.svg"),"alt":"exit"},on:{"click":_vm.logout}})])])]),(_vm.userModalOpen)?_c('UserModal',{attrs:{"modalType":_vm.modalType,"userId":_vm.userId},on:{"close":function($event){_vm.userModalOpen = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
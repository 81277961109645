<template>
  <div class="audio-player">
    <header v-if="player">
      <img
        src="../assets/icons/play.svg"
        alt="play"
        @click="play()"
        v-if="!isPlaying"
      />
      <img
        src="../assets/icons/pause.svg"
        alt="pause"
        @click="pause()"
        v-else
      />
      <input
        type="range"
        id="seek-slider"
        step="0.0001"
        min="0"
        :max="duration"
        v-model="player.currentTime"
        @input="currentTime = player.currentTime"
      />
    </header>
    <footer>{{ getProgress }}</footer>
  </div>
</template>

<script>
export default {
  props: ["src"],
  data() {
    return {
      player: null,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      updateInterval: null,
    };
  },
  computed: {
    getProgress() {
      return (
        this.calculateTime(this.currentTime) +
        "/" +
        this.calculateTime(this.duration)
      );
    },
  },
  methods: {
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    play() {
      this.player.play();
      this.isPlaying = true;

      this.updateInterval = setInterval(this.handleTimeUpdate, 20);
    },
    pause() {
      this.player.pause();
    },
    handlePause() {
      this.isPlaying = false;
      clearInterval(this.updateInterval);
    },
    handleTimeUpdate() {
      if (this.player.duration == Infinity) return;

      this.currentTime = this.player.currentTime;
      this.duration = this.player.duration;
    },
  },
  mounted() {
    this.player = new Audio(this.src);
    this.player.addEventListener("pause", this.handlePause);

    // https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity
    function getDuration(url, next) {
      const _player = new Audio(url);
      _player.addEventListener(
        "durationchange",
        function () {
          if (this.duration != Infinity) {
            const duration = this.duration;
            _player.remove();
            next(duration);
          }
        },
        false
      );
      _player.load();
      _player.currentTime = 24 * 60 * 60; //fake big time
      _player.volume = 0;
      // _player.play();
    }

    getDuration(this.src, (d) => (this.duration = d));
  },
  destroyed() {
    this.player.removeEventListener("pause", this.handlePause);
  },
};
</script>

<style lang="scss">
.audio-player {
  header {
    display: flex;
    gap: 0.5rem;

    img {
      width: 36px;
    }
  }
  footer {
    color: #000;
    opacity: 0.5;
    font-size: 0.9rem;
    letter-spacing: 0.05px;
    padding-left: calc(36px + 0.5rem);
    text-align: center;
  }

  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 115px;

    &:focus {
      outline: none;
    }

    &::-moz-range-track {
      background: #00000072;
      border-radius: 1rem;
      height: 6px;
    }
    &::-webkit-slider-runnable-track {
      background-color: #00000072;
      border-radius: 0.5rem;
      height: 6px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      // background-color: #fff;
      background: radial-gradient(
        circle,
        rgba(0, 153, 255, 1) 0%,
        rgba(0, 153, 255, 1) 40%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );

      border-radius: 50%;
      border: 1px solid #707070;
      margin-top: -7.5px;
      height: 21px;
      width: 21px;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      // background-color: #fff;
      background: radial-gradient(
        circle,
        rgba(0, 153, 255, 1) 0%,
        rgba(0, 153, 255, 1) 40%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );

      border-radius: 50%;
      border: 1px solid #707070;
      margin-top: -7.5px;
      height: 21px;
      width: 21px;
    }
  }
}
</style>

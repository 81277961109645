import axios from 'axios';
import store from '@/store/index';
const BASE_URI = process.env.VUE_APP_BASE_URI;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    store.dispatch('error', error.response.data.message);
    return Promise.reject(error);
  }
);

export const ApiService = {
  get(resource, opts) {
    return axios.get(`${BASE_URI}${resource}`, opts);
  },
  post(resource, data) {
    return axios.post(`${BASE_URI}${resource}`, data);
  },
  put(resource, data) {
    return axios.put(`${BASE_URI}${resource}`, data);
  },
  delete(resource) {
    return axios.delete(`${BASE_URI}${resource}`);
  }
};

export const AuthService = {
  setAuthHeader() {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }
};

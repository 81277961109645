<template>
  <div class="table__container">
    <section class="search-wrapper">
      <Input
        placeholder="ТЪРСЕНЕ"
        type="text"
        icon="search.svg"
        v-model="search"
        class="search"
        @input="getReports()"
      />
    </section>

    <transition name="table-transition" appear>
      <main class="main_content">
        <section class="navs">
          <section>
            <NavToggle
              @click="selectReportsType('my')"
              name="МОИТЕ ОТЧЕТИ"
              icon="reports-my.svg"
              :active="type === 'my'"
            />
            <NavToggle
              @click="selectReportsType('all')"
              name="ВСИЧКИ ОТЧЕТИ"
              icon="reports-all.svg"
              :active="type === 'all'"
            />
          </section>

          <section class="wrap">
            <div></div>
            <section class="buttons-right-nav">
              <div
                @click="groupReportModal = true"
                class="nav-toggle export-mails"
              >
                <img
                  src="@/assets/icons/new-group.svg"
                  alt="new"
                  class="new_w"
                />
                <p>ГРУПОВ ОТЧЕТ</p>
              </div>

              <div
                @click="(reportModal = true), (reportId = null)"
                class="nav-toggle create-new"
              >
                <img src="@/assets/icons/new-w.svg" alt="new" class="new_w" />
                <p>НОВ ОТЧЕТ</p>
              </div>
            </section>
          </section>
        </section>

        <div class="content">
          <Table>
            <thead>
              <tr>
                <th class="multy">
                  <p v-if="!reportsMainFields">СЪЗДАТЕЛ</p>
                  <p>Дата</p>
                </th>
                <th class="multy">
                  <p>КЛИЕНТ</p>
                  <p>Договор</p>
                </th>
                <th>ОПИСАНИЕ</th>
                <th></th>
                <th class="multy">
                  <p>ВРЕМЕ</p>
                </th>
                <template v-if="!reportsMainFields">
                  <th class="multy">
                    <p>КИЛОМЕТРИ</p>
                    <p>Пропътуване</p>
                  </th>
                  <th class="multy">
                    <p>СИНЯ ЗОНА</p>
                    <p>Зелена зона</p>
                  </th>
                  <th class="multy">
                    <p>ИРВ</p>
                    <p>ИРВ2</p>
                  </th>
                  <th>ДРУГИ РАЗХОДИ</th>
                </template>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody v-if="loading || !reports.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="r in reports"
                :key="`reports-${r._id}`"
                @click="(reportModal = true), (reportId = r._id)"
              >
                <td class="multy">
                  <p v-if="!reportsMainFields">{{ r.creator.name }}</p>
                  <p>{{ r.fromDate | formatDate }}</p>
                </td>
                <td class="multy">
                  <p>{{ r.client.name }}</p>
                  <p>{{ r.contract.contractName }}</p> 
                </td>
                <td class="bigger-width">{{ r.description }}</td>
                <td>
                  <img
                    title="Отчитане за сметка на cDots - няма да се взимат от часовете на клиента"
                    v-if="r.cdotsExpenses"
                    src="@/assets/icons/cdots-active.svg"
                    alt=""
                  />
                </td>
                <td class="multy">
                  <p>
                    {{ `${r.time.hours}.${r.time.minutes}` }}
                  </p>
                </td>
                <template v-if="!reportsMainFields">
                  <td class="multy">
                    <p>{{ r.km ? `${r.km} км` : "0 км" }}</p>
                    <p>{{ `${r.distance.hours}.${r.distance.minutes}` }}</p>
                  </td>
                  <td class="multy">
                    <p>{{ r.blueZone }}</p>
                    <p>{{ r.greenZone }}</p>
                  </td>
                  <td class="multy">
                    <p>{{ `${r.overtime.hours}.${r.overtime.minutes}` }}</p>
                    <p>
                      {{
                        `${r.overtimeHolidays.hours}.${r.overtimeHolidays.minutes}`
                      }}
                    </p>
                  </td>
                  <td>{{ r.costs }} лв.</td>
                </template>

                <td @click.stop="copyReport(r._id)">
                  <img
                    title="Дублирай отчет"
                    src="@/assets/icons/copy-report.svg"
                    alt="copy"
                    class="icons"
                  />
                </td>
                <td @click.stop="deleteReport(r._id)">
                  <img
                    title="Изтрий отчет"
                    src="@/assets/icons/delete.svg"
                    alt="remove"
                    class="icons"
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <Pagination :total="total" :size="size" />
          <ReportModal
            v-if="reportModal"
            :reportId="reportId"
            :copyReport="isCopyReport"
            @close="closeReportModal"
          />
          <GroupReportModal
            v-if="groupReportModal"
            @close="closeReportModal"
            @deleteReport="deleteReport"
          />
        </div>
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import NavToggle from "@/components/NavToggle";
import Table from "@/components/Table";
import Pagination from "@/components/Pagination";
import ReportModal from "@/components/reportsModals/ReportModal";
import GroupReportModal from "@/components/reportsModals/GroupReportModal";
export default {
  components: {
    Input,
    Table,
    Pagination,
    ReportModal,
    GroupReportModal,
    NavToggle,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    reportsMainFields() {
      return (
        this.$route.query.type !== "all" &&
        this.reportsMode === "reportsMainFields"
      );
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      totalMyreports: 0,
      size: 20,
      search: "",
      type: "",
      reportsMode: "",
      reports: [],
      myReports: [],
      reportModal: false,
      groupReportModal: false,
      isCopyReport: false,
    };
  },
  watch: {
    $route: [
      {
        handler: "getReports",
        immediate: true,
      },
    ],
  },
  methods: {
    closeReportModal(update) {
      this.reportModal = false;
      this.isCopyReport = false;
      this.groupReportModal = false;
      if (update) {
        this.getReports();
      }
    },
    copyReport(reportId) {
      this.reportId = reportId;
      this.isCopyReport = true;
      this.reportModal = true;
    },
    deleteReport(reportId, aggree) {
      if (
        confirm("Сигурни ли сте, че искате да изтриете този отчет?") ||
        aggree
      ) {
        let url = `/reports/delete/${reportId}`;

        this.$apiService.delete(url).then(() => {
          this.getReports();
        });
      }
    },
    selectReportsType(t) {
      if (this.type !== t) {
        this.type = t;
        this.$router.push(`/reports?type=${t}`);
      }
    },
    getReports() {
      this.loading = true;
      this.reports = [];

      let url = `/reports?size=${this.size}&page=${
        this.$route.query.page || 1
      }`;

      if (this.search) {
        url += `&search=${this.search}`;
      }
      if (this.$route.query.task) {
        url += `&task=${this.$route.query.task}`;
      }

      if (
        this.$route.query.type === "my" ||
        this.currentUser.role !== "administrator"
      ) {
        url += `&creatorId=${this.currentUser._id}`;
      }
      this.$apiService
        .get(url)
        .then((res) => {
          this.reports = res.data.results;
          this.total = res.data.total;

          if (res.data.totalPages) {
            const query = { ...this.$route.query };
            if (query.page > res.data.totalPages) {
              delete query.page;
              this.$router.replace({ query: query });
            }
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    onStorageUpdate() {
      const reportsMode = localStorage.getItem("reportsMode");
      this.reportsMode = reportsMode;
    },
  },
  mounted() {
    window.addEventListener("storage", this.onStorageUpdate);
    window.dispatchEvent(new Event("storage")); // this fires up the storage listener

    if (this.$route.query.type) this.type = this.$route.query.type;

    // if (this.currentUser.role !== "administrator") {
    //   this.selectReportsType("my");
    // } else {
    //   if (!this.$route.query.type) {
    //     this.selectReportsType("all");
    //   }
    // }
    if (!this.$route.query.task) this.selectReportsType("my");
  },
  destroyed() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
};
</script>
<template>
  <Modal @close="close()" class="bigger-modal">
    <form>
      <div class="form-content reports-wrapper">
        <header class="header-group-reports">
          <section>
            <img
              class="icons"
              src="@/assets/icons/group-reports.svg"
              alt="group reports"
            />
            <h1>ОТЧЕТ ЗА СВЪРШЕНА РАБОТА</h1>
          </section>

          <section>
            <Datepicker
              :disabledDates="disabledDatesTo"
              icon="Calendar.svg"
              v-model="fromDate"
              class="datepicker reportDatepicker"
            />
            <Button @click="close" class="close"
              ><img class="icons" src="@/assets/icons/close.svg" alt="close"
            /></Button>
          </section>
        </header>
        <!-- <hr class="break" /> -->

        <div class="row row-flex">
          <div class="text">
            <p>Изберете броя клиенти по които сте работили днес</p>
            <span
              >Отчитането на работата за деня се извършва на брой проекти и
              времето за всяка от тях</span
            >
          </div>
          <div class="counter">
            <img
              class="icons"
              @click="remove"
              src="@/assets/icons/minus.svg"
              alt=""
            />
            <p>{{ reports.length }}</p>
            <img
              class="icons"
              @click="add"
              src="@/assets/icons/plus.svg"
              alt=""
            />
          </div>
        </div>

        <main class="inputs--wrapper inputs--wrapper--group">
          <section
            class="form-inputs form-inputs--reports form-inputs--group-reports"
            v-for="(report, i) of reports"
            :key="`report-${i}`"
          >
            <div class="row row-flex row-gap">
              <Dropdown
                placeholder="Клиенти..."
                icon="search.svg"
                :lastReports="
                  lastReportContracts.length ? lastReportContracts : false
                "
                :dataArray="
                  lastReportContracts.length
                    ? clients.concat(lastReportContracts)
                    : clients
                "
                :formatter="(client) => client.name"
                :typing="true"
                :invalid="invalid.client"
                @input="
                  (invalid.client = false),
                    getContracts(
                      report,
                      report.client ? report.client._id : false
                    )
                "
                :up="reports.length - 1 === i ? 'up' : false"
                v-model="report.client"
                @search="getClients"
              />

              <Dropdown
                class="dropdown-wrapper--contract-status"
                placeholder="Договор"
                icon="report-contract.svg"
                :dataArray="report.contractsArray"
                :formatter="(contract) => contract.contractName"
                :invalid="invalid.contract"
                :disabled="!report.client"
                @input="
                  getThisMonthReports(report.contract, report),
                    (invalid.contract = false)
                "
                v-model="report.contract"
              />
              <ContractStatus
                v-if="
                  report.contract && report.contract.contractType !== 'question'
                "
                :contract="report.contract"
                :monthlyHours="report.monthlyHours"
              />
            </div>

            <!-- <div class="row">
              <Dropdown
                class="dropdown-wrapper--contract-status"
                placeholder="Задача"
                icon="report-contract.svg"
                :dataArray="report.tasksArray"
                :formatter="(task) => task.name"
                :disabled="!report.client"
                v-model="report.task"
              />
              <ContractStatus
                v-if="report.task && report.task._id"
                :task="report.task"
                :monthlyHours="report.task ? report.task.reportedHours : 0"
              />
            </div> -->

            <div class="row">
              <Input
                type="text"
                icon="description.svg"
                :placeholder="'Описание...'"
                :textarea="true"
                :maxlength="550"
                :invalid="invalid.description"
                @input="invalid.description = false"
                v-model="report.description"
              >
                <span
                  class="cdots-expenses"
                  title="Отчитане за сметка на cDots - няма да се взимат от часовете на клиента"
                >
                  <svg
                    @click="setNoExpenses(report)"
                    :class="{ active: report.cdotsExpenses }"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32.407"
                    height="32.407"
                    viewBox="0 0 32.407 32.407"
                  >
                    <path
                      id="Subtraction_1"
                      data-name="Subtraction 1"
                      d="M16.2,32.407A16.2,16.2,0,0,1,4.746,4.746,16.2,16.2,0,1,1,27.661,27.661,16.1,16.1,0,0,1,16.2,32.407Zm-2.456-8.216a1.117,1.117,0,1,0,1.117,1.118A1.119,1.119,0,0,0,13.747,24.191Zm-5.644,0A1.117,1.117,0,1,0,9.22,25.309,1.12,1.12,0,0,0,8.1,24.191Zm11.37.219a.9.9,0,1,0,.9.9A.9.9,0,0,0,19.473,24.41Zm-5.726-6.862a2.036,2.036,0,1,0,2.037,2.037A2.04,2.04,0,0,0,13.747,17.548Zm5.726.752a1.285,1.285,0,1,0,1.284,1.284A1.286,1.286,0,0,0,19.473,18.3ZM8.1,18.468A1.117,1.117,0,1,0,9.22,19.585,1.12,1.12,0,0,0,8.1,18.468Zm17.105.108a1.009,1.009,0,1,0,1.01,1.009A1.01,1.01,0,0,0,25.209,18.576ZM19.473,11.8a2.037,2.037,0,1,0,2.038,2.037A2.039,2.039,0,0,0,19.473,11.8Zm-5.726,0a2.037,2.037,0,1,0,2.037,2.037A2.04,2.04,0,0,0,13.747,11.8ZM8.1,11.8a2.037,2.037,0,1,0,2.037,2.037A2.039,2.039,0,0,0,8.1,11.8Zm17.108.905a1.13,1.13,0,1,0,1.129,1.131A1.133,1.133,0,0,0,25.212,12.7ZM13.747,5.981a2.037,2.037,0,1,0,2.037,2.038A2.04,2.04,0,0,0,13.747,5.981ZM8.1,6.489a1.531,1.531,0,1,0,1.532,1.53A1.533,1.533,0,0,0,8.1,6.489Zm11.37.81a.72.72,0,1,0,.72.72A.721.721,0,0,0,19.473,7.3Z"
                      fill="#B7B7B7"
                    />
                  </svg>
                </span>
                <ReportHours
                  :report="report"
                  :invalid="invalid.time"
                  v-model="report[report.reportTime]"
                  reportMainTime="true"
                  :up="reports.length - 1 === i ? 'up' : false"
                  @selectReportTime="report.reportTime = $event"
                />
              </Input>
            </div>
            <div
              class="row expand icons"
              @click="expand(report)"
              :class="{
                'expand-open': report.type !== 'Отдалечено' || report.expand,
              }"
            >
              <img src="@/assets/icons/expand.svg" alt="expand" />
              <p>
                {{
                  report.type !== "Отдалечено"
                    ? "Скрий отчет на място, или споделено пътуване"
                    : "Добави отчет на място, или споделено пътуване"
                }}
              </p>

              <div class="row row-delete">
                <img
                  @click.stop="deleteReport(report._id, i)"
                  src="@/assets/icons/delete.svg"
                  alt="remove"
                  class="icons"
                />
              </div>
            </div>
            <template v-if="report.type !== 'Отдалечено' || report.expand">
              <!-- На място -->
              <div
                class="row"
                :class="{ 'row-open': report.type === 'На място' }"
              >
                <SwitchInput
                  icon="alone-drive.svg"
                  placeholder="Отчет на място"
                  label="Отчита се времето за пропътуване, разходи за паркинг и други"
                  withSwitch="true"
                  newValue="На място"
                  oldValue="Отдалечено"
                  @input="resetTypeInputs(report)"
                  v-model="report.type"
                />
                <template v-if="report.type === 'На място'">
                  <SwitchInput label="Пропътувани километри">
                    <Input
                      v-if="report.type === 'На място'"
                      v-model="report.km"
                      type="number"
                      rightPlaceholder="км."
                      :invalid="invalid.km"
                      @input="invalid.km = false"
                      min="0"
                      :step="0.01"
                    />
                  </SwitchInput>

                  <SwitchInput label="Пропътувано време">
                    <ReportHours
                      :report="report"
                      :invalid="invalid.distance"
                      v-model="report.distance"
                    />
                  </SwitchInput>

                  <SwitchInput label="Зелена зона (ч)">
                    <ReportHours
                      :report="report"
                      :invalid="invalid.greenZone"
                      v-model="report.greenZone"
                      noMinutes="true"
                    />
                  </SwitchInput>

                  <SwitchInput label="Синя зона (ч)">
                    <ReportHours
                      :report="report"
                      :invalid="invalid.blueZone"
                      v-model="report.blueZone"
                      noMinutes="true"
                    />
                  </SwitchInput>
                  <SwitchInput twoSlots="true">
                    <Input
                      v-model="report.costsInfo"
                      type="text"
                      rightPlaceholder=" "
                      :placeholder="'Забележка други разходи'"
                    />
                    <Input
                      v-model="report.costs"
                      type="number"
                      rightPlaceholder="лв."
                      min="0"
                      :step="0.01"
                    />
                  </SwitchInput>
                </template>
              </div>
              <!-- На място -->
              <!-- Споделено пътуване -->
              <div
                class="row"
                :class="{ 'row-open': report.type === 'Споделено' }"
              >
                <SwitchInput
                  icon="shared-drive.svg"
                  placeholder="Споделено пътуване"
                  label="Отчита се времето за споделено пътуване"
                  withSwitch="true"
                  newValue="Споделено"
                  oldValue="Отдалечено"
                  @input="resetTypeInputs(report)"
                  v-model="report.type"
                />
                <template v-if="report.type === 'Споделено'">
                  <SwitchInput label="Пропътувано време">
                    <ReportHours
                      :report="report"
                      :invalid="invalid.distance"
                      v-model="report.distance"
                      up="true"
                    />
                  </SwitchInput>
                </template>
              </div>
              <!-- Споделено пътуване -->
            </template>
            <p class="row error">
              <span v-if="report.invalid">{{ report.invalid }}</span>
              <span v-if="report.error">{{ report.error }}</span>
              <span
                v-if="report.client ? report.client.language === 'EN' : false"
                >Попълнете на английски!</span
              >
              <span v-if="report.cdotsExpenses"
                >Отчитане за сметка на cDots - няма да се взимат от часовете на
                клиента</span
              >
            </p>
          </section>
        </main>
      </div>

      <div class="report-buttons">
        <Button @click="save(true)" class="grey">МЕЖДИНЕН ОТЧЕТ</Button>
        <Button @click="save">ФИНАЛЕН ЗАПИС</Button>
      </div>
    </form>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Dropdown from "@/components/Dropdown";
import Datepicker from "@/components/Datepicker";
import Modal from "@/components/Modal";
import ContractStatus from "@/components/ContractStatus";
import ReportHours from "@/components/ReportHours";
import SwitchInput from "@/components/SwitchInput";
export default {
  components: {
    Input,
    Button,
    Datepicker,
    Dropdown,
    Modal,
    ContractStatus,
    ReportHours,
    SwitchInput,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    disabledDatesTo() {
      return { from: new Date() };
    },
  },
  data() {
    return {
      invalid: {
        invalid: false,
      },
      reports: [
        {
          reportTime: "time",
          reportNumber: 0,
          creator: {},
          fromDate: "",
          task: "",
          client: "",
          contract: "",
          description: "",
          cdotsExpenses: false,
          time: {
            hours: 0,
            minutes: 0,
            hoursSum: "",
          },
          km: 0,
          distance: {
            hours: 0,
            minutes: 0,
          },
          blueZone: 0,
          greenZone: 0,
          costs: 0,
          costsInfo: "",
          overtime: {
            hours: 0,
            minutes: 0,
            hoursSum: "",
          },
          overtimeHolidays: {
            hours: 0,
            minutes: 0,
            hoursSum: "",
          },
          type: "Отдалечено",
          monthlyHours: 0,
        },
      ],
      monthlyHours: 0,
      error: "",

      fromDate: new Date(),
      clients: [],
      monthlyOvertimeHours: 0,
      monthlyOvertimeHolidaysHours: 0,
      monthlyCosts: 0,
      lastReportContracts: [],
    };
  },
  watch: {
    $route: [
      {
        handler: "getLastReports",
        immediate: true,
      },
      {
        handler: "getClients",
        immediate: true,
      },
    ],
    fromDate() {
      for (let r of this.reports) {
        this.getThisMonthReports(r.contract, r);
      }
    },
  },
  methods: {
    remove() {
      if (
        this.reports.length > 1 &&
        !this.reports[this.reports.length - 1]._id
      ) {
        this.reports.splice(this.reports.length - 1, 1);
      }
    },
    add() {
      this.reports.push({
        reportTime: "time",
        reportNumber: 0,
        creator: {},
        fromDate: "",
        task: "",
        client: "",
        contract: "",
        description: "",
        time: {
          hours: 0,
          minutes: 0,
          hoursSum: "",
        },
        km: 0,
        distance: {
          hours: 0,
          minutes: 0,
        },
        blueZone: 0,
        greenZone: 0,
        costs: 0,
        costsInfo: "",
        overtime: {
          hours: 0,
          minutes: 0,
          hoursSum: "",
        },
        overtimeHolidays: {
          hours: 0,
          minutes: 0,
          hoursSum: "",
        },
        type: "Отдалечено",
        monthlyHours: 0,
      });
    },
    resetTypeInputs(report) {
      report.km = 0;
      report.distance = {
        hours: 0,
        minutes: 0,
      };
      report.blueZone = 0;
      report.greenZone = 0;
      report.costs = 0;
      report.costsInfo = "";
    },
    deleteReport(reportID, index) {
      if (confirm("Сигурни ли сте, че искате да изтриете този отчет?")) {
        if (reportID) this.$emit("deleteReport", reportID, true);
        this.reports.splice(index, 1);
      }
    },
    close(update) {
      this.$emit("close", update);
    },
    isValid(draft) {
      let output = true;

      for (let report of this.reports) {
        if (!report.client) {
          this.$set(report, "error", "Не сте въвели Клиент");
          this.invalid.client = true;
          output = false;
        } else if (!report.contract) {
          this.$set(report, "error", "Не сте въвели Договор");

          this.invalid.contract = true;
          output = false;
        } else if (
          !draft &&
          0 >= report[report.reportTime].hours &&
          0 >= report[report.reportTime].minutes
        ) {
          this.$set(report, "error", "Не сте въвели Време");
          this.invalid.time = true;
          output = false;
        } else if (!report.description) {
          this.$set(report, "error", "Не сте въвели Описание");
          this.invalid.description = true;
          output = false;
        } else if (0 >= report.km && report.type === "На място") {
          this.$set(report, "error", "Не сте въвели Километри");
          this.invalid.km = true;
          output = false;
        } else if (
          report.distance.hours == 0 &&
          report.distance.minutes == 0 &&
          report.type !== "Отдалечено"
        ) {
          this.error = "Не сте въвели Пропътувано време";
          this.invalid.distance = true;
          output = false;
        }
      }

      return output;
    },
    async save(draft) {
      this.error = "";
      const promises = [];

      for (let report of this.reports) {
        if (draft) report.draft = true;
        report.creator = this.currentUser._id;
        report.fromDate = this.fromDate;

        if (this.isValid(draft)) {
          report[report.reportTime].hoursSum = Number(
            `${report[report.reportTime].hours}.${
              report[report.reportTime].minutes
            }`
          );

          if (report._id) {
            if (!draft) report.draft = false;

            promises.push(
              await new Promise((resolve) => {
                this.$apiService
                  .put("/reports", report)
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    this.error = err.response.data.message;
                  });
              })
            );
          } else {
            promises.push(
              await new Promise((resolve) => {
                this.$apiService
                  .post("/reports", report)
                  .then(() => {
                    resolve();
                  })
                  .catch((err) => {
                    this.error = err.response.data.message;
                  });
              })
            );
          }
        }
      }

      if (promises.length) {
        Promise.all(promises)
          .then(() => {
            this.close(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getThisMonthReports(contract, report) {
      if (contract && report) {
        report.monthlyHours = 0;
        let url = `/reports/stats/${contract._id}`;
        if (contract.contractType !== "development") {
          url += `?month=${this.fromDate}`;
        }
        this.$apiService.get(url).then((res) => {
          let s = res.data;

          this.$set(contract, "monthlyHours", s.Time || 0);
          this.$set(report, "monthlyHours", s.Time || 0);
          this.$set(contract, "monthlyOvertimeHours", s.Overtime || 0);

          this.$set(
            contract,
            "monthlyOvertimeHolidaysHours",
            s.OvertimeHolidays || 0
          );

          this.$set(
            contract,
            "monthlyCosts",
            Number(s.monthlyCosts ? s.monthlyCosts.$numberDecimal : 0)
          );
        });
      }
    },
    setNoExpenses(report) {
      this.$set(report, "cdotsExpenses", !report.cdotsExpenses);
    },
    expand(report) {
      this.$set(report, "expand", !report.expand);
    },
    getContracts(report, clientId) {
      report.contract = "";
      if (clientId) {
        this.getTasks(report, clientId);
        let url = `/contracts?clientId=${clientId}&currentUserId=${this.currentUser._id}`;
        // &active=${true}
        // gets all contracts because old reports might be with not active contracts

        this.$apiService.get(url).then((res) => {
          this.$set(report, "contractsArray", res.data.results);
          report.contractsArray = report.contractsArray.filter((c) => c.active);
          // if client has only one contract its auto selected
          if (report.contractsArray.length === 1) {
            report.contract = report.contractsArray[0];
          }
          this.getThisMonthReports(report.contract, report);
        });
      }
    },
    getClients(search) {
      this.clients = [];
      let url = `/contracts?currentUserId=${
        this.currentUser._id
      }&active=${true}`;

      this.$apiService.get(url).then((res) => {
        //gets unique clients by id
        this.uniqueObjArray = [
          ...new Map(
            res.data.results.map((item) => [item["client"]._id, item])
          ).values(),
        ];

        //takes only the client value from uniqueObjArray
        this.clients = Object.keys(this.uniqueObjArray).map(
          (k) => this.uniqueObjArray[k].client
        );
        if (typeof search === "string") {
          this.clients = this.clients.filter((c) => {
            return (
              c.name.toLowerCase().includes(search.toLowerCase()) ||
              c.shortName.toLowerCase().includes(search.toLowerCase())
            );
          });
        }
      });
    },
    getLastReports() {
      this.lastReportContracts = [];

      let url = `/reports?creatorId=${this.currentUser._id}&size=${5}&page=${
        this.$route.query.page || 1
      }`;

      this.$apiService.get(url).then((res) => {
        this.uniqueObjArray = [
          ...new Map(
            res.data.results.map((item) => [item["client"]._id, item])
          ).values(),
        ];
        for (let r of this.uniqueObjArray) {
          this.$apiService.get(`/contracts/${r.contract._id}`).then((res) => {
            if (res.data.active) this.lastReportContracts.push(res.data.client);
          });
        }
      });
    },
    getDraftReports() {
      let url = `/reports?draft=true&creatorId=${this.currentUser._id}`;

      this.$apiService.get(url).then((res) => {
        if (res.data.results.length) {
          this.reports = res.data.results;
          this.reports.forEach((r) => {
            this.getContracts(r, r.client._id);
            this.getTasks(r, r.client._id);
            if (r.task && r.task._id) {
              this.getTask(r, r.task._id);
            } else {
              r.task = "";
            }
          });
        } else {
          this.add();
        }
      });
    },
    getTask(report, id) {
      if (id && report) {
        this.$apiService.get(`/tasks/${id}`).then((res) => {
          if (res.data) {
            this.$set(report, "task", res.data);
          }
        });
      }
    },
    getTasks(report, clientId) {
      if (!report._id) report.task = "";
      if (clientId) {
        this.$set(report, "tasksArray", []);

        let url = `/tasks?clientId=${clientId}`;

        this.$apiService.get(url).then((res) => {
          this.$set(report, "tasksArray", res.data.results);
        });
      }
    },
  },
  beforeMount() {
    this.getDraftReports();
  },
};
</script>
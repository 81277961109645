import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=b99aabf6&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=b99aabf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.38_css-loader@6.11.0_webpack@5.94.0__handlebars@4.7._m2icaavtym56oajca3bknhruuu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b99aabf6",
  null
  
)

export default component.exports
<template>
  <div class="pagination" v-if="total >= size">
    <!-- FIRST PAGE -->
    <span class="pagination__item" v-if="page > 3" @click="changePage(1)">
      <!-- <span class="icon">D</span> -->
      1
    </span>
    <!-- <span
      class="pagination__item"
      v-if="page > 3"
      @click="changePage(page - 1)"
    >
      <span>prev</span>
    </span> -->
    <span
      class="pagination__item"
      v-if="page > 2"
      @click="changePage(page - 2)"
      >{{ page - 2 }}</span
    >
    <span
      class="pagination__item"
      v-if="page > 1"
      @click="changePage(page - 1)"
      >{{ page - 1 }}</span
    >
    <span class="pagination__item active">{{ page }}</span>
    <span
      class="pagination__item"
      v-if="page < Math.ceil(total / size) - 1"
      @click="changePage(page + 1)"
      >{{ page + 1 }}</span
    >
    <span
      class="pagination__item"
      v-if="page < Math.ceil(total / size) - 2"
      @click="changePage(page + 2)"
      >{{ page + 2 }}</span
    >
    <!-- <span
      class="pagination__item"
      v-if="page < Math.ceil(total / size) - 2"
      @click="changePage(page + 1)"
    >
      <span>next</span>
    </span> -->
    <!-- LAST PAGE -->
    <span
      class="pagination__item"
      v-if="page !== Math.ceil(total / size) && Math.ceil(total / size)"
      @click="changePage(Math.ceil(total / size))"
    >
      <span v-if="page < Math.ceil(total / size) - 2">{{
        Math.ceil(total / size)
      }}</span>
      <template v-else>{{ Math.ceil(total / size) }}</template>
    </span>
  </div>
</template>

<script>
export default {
  props: ["total", "size"],

  computed: {
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    changePage(p) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page: p },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.pagination {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  width: 100%;
  .pagination__item {
    align-items: center;
    border-radius: $borderRadius;
    box-shadow: 0px 5px 30px #00000040, -0px 5px 30px #00000040;
    color: #828282;
    background-color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    height: 32px;
    margin: 0 2px;
    min-width: 32px;
    padding: 0 0.4rem;
    @include noselect();
    &:not(.active){
      @include hover();
    }
    .icon {
      font-size: 0.8rem;
    }
    &.active {
      background-color: #000000;
      color: #ffffff;
    }
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Issues from "../views/Issues.vue";
import Inquiry from "../views/Inquiry.vue";
import Reports from "../views/Reports.vue";
import Nomenclature from "../views/Nomenclature.vue";
import NotFound from "../components/NotFound.vue";
import auth from "./auth";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    alias: '/',
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inquiry",
    name: "Inquiry",
    component: Inquiry,
    meta: {
      requiresAuth: true,
      rolesAllowed: ["administrator"],
    },
  },
  {
    path: "/issues",
    name: "Issues",
    component: Issues,
    meta: {
      requiresAuth: true,
      rolesAllowed: ["administrator"],
    },
  },
  {
    path: "/nomenclature",
    name: "Nomenclature",
    component: Nomenclature,
    meta: {
      requiresAuth: true,
      rolesAllowed: ["administrator"],
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  ...auth,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("checkAuth").then(() => {
    // inactive
    if (!store.getters.currentUser.active) {
      store.dispatch("logout").then(() => {
        return next({
          path: "/login",
        });
      });
    }


    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        // if only specific roles are allowed
        if (to.meta.rolesAllowed) {
          if (to.meta.rolesAllowed.indexOf(store.getters.currentUser.role) > -1) {

            return next();
          }
          // if no match
          return next({
            path: from.path || "/",
          });
        }
        // no role required
        return next();
      }

      return next({
        path: "/login",
      });
    } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      if (!store.getters.isAuthenticated) {
        return next();
      }
      return next({
        path: "/",
      });
    } else {
      return next();
    }
  });
});
export default router;

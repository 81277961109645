<template>
  <div class="table__container">
    <section class="search-wrapper">
      <Input
        placeholder="ТЪРСЕНЕ"
        type="text"
        icon="search.svg"
        v-model="search"
        class="search"
        @input="getClients(), getContracts(), getUsers()"
      />
    </section>

    <transition name="table-transition" appear>
      <main class="main_content">
        <section class="navs">
          <section>
            <NavToggle
              @click="currentView = 'clients'"
              name="КЛИЕНТИ"
              icon="clients.svg"
              :active="currentView === 'clients'"
            />
            <NavToggle
              @click="currentView = 'contracts'"
              name="ДОГОВОРИ"
              icon="contracts.svg"
              :active="currentView === 'contracts'"
            />
            <NavToggle
              @click="currentView = 'tasks'"
              name="ЗАДАЧИ"
              icon="contracts.svg"
              :active="currentView === 'tasks'"
            />
            <NavToggle
              @click="currentView = 'users'"
              name="СЛУЖИТЕЛИ"
              icon="users.svg"
              :active="currentView === 'users'"
            />
          </section>

          <section class="wrap">
            <div></div>

            <div
              class="nav-toggle create-new"
              v-if="currentView === 'clients'"
              @click="(clientModal = true), (clientId = null)"
            >
              <img src="@/assets/icons/new-w.svg" alt="new" class="new_w" />
              <p>НОВ КЛИЕНТ</p>
            </div>

            <Slider
              v-if="currentView !== 'clients'"
              @change="setActiveState($event)"
              :checked="onlyActive"
              off="НЕАКТИВНИ"
              on="АКТИВНИ"
            />

            <section
              v-if="currentView === 'contracts'"
              class="buttons-right-nav"
            >
              <div @click="exportMails()" class="nav-toggle export-mails">
                <img
                  src="@/assets/icons/download.svg"
                  alt="new"
                  class="new_w"
                />
                <p>ЕКСПОРТ МЕЙЛИ</p>
              </div>

              <div
                class="nav-toggle create-new"
                @click="
                  (contractModal = true),
                    (modalType = 'new'),
                    (contractId = null)
                "
              >
                <img src="@/assets/icons/new-w.svg" alt="new" class="new_w" />
                <p>НОВ ДОГОВОР</p>
              </div>
            </section>

            <div
              v-if="currentView === 'tasks'"
              class="nav-toggle create-new"
              @click="(taskModal = true), (taskId = null)"
            >
              <img src="@/assets/icons/new-w.svg" alt="new" class="new_w" />
              <p>НОВА ЗАДАЧА</p>
            </div>

            <div
              v-if="currentView === 'users'"
              class="nav-toggle create-new"
              @click="(userModal = true), (userId = null)"
            >
              <img src="@/assets/icons/new-w.svg" alt="new" class="new_w" />
              <p>НОВ СЛУЖИТЕЛ</p>
            </div>
          </section>
        </section>

        <div class="content">
          <Table v-if="currentView === 'clients'">
            <thead>
              <tr>
                <th>ЮРИДИЧЕСКО ИМЕ</th>
                <th>КРАТКО ИМЕ</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>ЕЗИК</th>
              </tr>
            </thead>
            <tbody v-if="loading || !clients.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="c in clients"
                :key="`clients-${c._id}`"
                @click="
                  (clientModal = true),
                    (clientId = c._id),
                    (modalType = 'preview')
                "
              >
                <td>{{ c.name }}</td>
                <td>{{ c.shortName }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ c.language }}</td>
              </tr>
            </tbody>
          </Table>
          <Table v-if="currentView === 'contracts'">
            <thead>
              <tr>
                <th>КЛИЕНТ</th>
                <th>
                  <Dropdown
                    placeholder="ТИП ДОГОВОР"
                    icon="filter.svg"
                    :iconRight="true"
                    :dataArray="contractTypes"
                    :formatter="(contractType) => contractType.bg"
                    v-model="contractType"
                  />
                </th>
                <th>НОМЕР НА ДОГОВОР</th>
                <th>ОТ ДАТА</th>
                <th>ДО ДАТА</th>
                <th>ДОГОВОР</th>
                <th class="center">ПАРАМЕТРИ СПОРЕД ТИПА ДОГОВОР</th>
                <th>ЗАБЕЛЕЖКА</th>
                <th>СТАТУС</th>
              </tr>
            </thead>
            <tbody v-if="loading || !contracts.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="c in contracts"
                :key="`contracts-${c._id}`"
                @click="
                  (contractModal = true),
                    (contractId = c._id),
                    (modalType = 'preview')
                "
              >
                <td>{{ c.client.name }}</td>
                <td :class="c.contractType">
                  <p class="contract-type">
                    <img
                      :src="`${require(`@/assets/icons/${c.contractType}.svg`)}`"
                      alt="contractType icon"
                    />
                    {{
                      c.contractType == "maintenance"
                        ? "ПОДДРЪЖКА"
                        : c.contractType == "development"
                        ? "РАЗРАБОТКА"
                        : c.contractType == "question"
                        ? "ЗАПИТВАНЕ"
                        : ""
                    }}
                  </p>
                </td>
                <td>{{ c.contractNumber }}</td>
                <td>{{ c.fromDate | formatDate }}</td>
                <td>
                  <span v-if="c.toDate">
                    {{ c.toDate | formatDate }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td>{{ c.contractName }}</td>
                <td v-if="c.contractType == 'maintenance'">
                  <main class="contract-parameters">
                    <div>
                      <p>Включени часове</p>
                      <p>{{ c.hoursPod.hours }} ч.</p>
                    </div>
                    <div>
                      <p>Цена на вкл. час</p>
                      <p>{{ c.hoursPod.priceForHour }} лв.</p>
                    </div>
                    <div>
                      <p>Цена доп. час</p>
                      <p>{{ c.hoursPod.priceExtraHour }} лв.</p>
                    </div>
                  </main>
                </td>
                <td v-if="c.contractType == 'development'">
                  <main class="contract-parameters">
                    <div>
                      <p>Включени часове</p>
                      <p>{{ c.hoursRaz.hours }} ч.</p>
                    </div>
                    <div>
                      <p>Общ бюджет</p>
                      <p>{{ c.hoursRaz.budjet }} лв.</p>
                    </div>
                    <div>
                      <p>Цена на час</p>
                      <p>{{ c.hoursRaz.avgPriceHour }} лв.</p>
                    </div>
                  </main>
                </td>
                <td v-if="c.contractType == 'question'">
                  <main class="contract-parameters">
                    <div>
                      <p>-</p>
                    </div>
                    <div>
                      <p>-</p>
                    </div>
                    <div>
                      <p>Цена на час</p>
                      <p>{{ c.hoursZap.priceForHour }} лв.</p>
                    </div>
                  </main>
                </td>
                <td>{{ c.note }}</td>
                <td>
                  <Slider
                    :title="
                      cantBeActive(c)
                        ? 'Променете ДАТА ДО, преди да активирате договора'
                        : false
                    "
                    @change="statusChangeContract($event, c)"
                    :disabled="cantBeActive(c)"
                    :checked="c.active"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table v-if="currentView === 'tasks'">
            <thead>
              <tr>
                <th>КЛИЕНТ</th>
                <th>ДОГОВОР</th>
                <th>ЗАДАЧА</th>
                <th>ЧАСОВЕ РЕАЛНИ</th>
                <th>ЧАСОВЕ КЪМ КЛИЕНТА</th>
                <th>ЧАСОВЕ ОТЧЕТЕНИ</th>
                <th></th>
                <th>СТАТУС</th>
              </tr>
            </thead>
            <tbody v-if="loading || !tasks.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="t in tasks"
                :key="`task-${t._id}`"
                @click="(taskModal = true), (taskId = t._id)"
              >
                <td>{{ t.client.name }}</td>
                <td>{{ t.contract.contractName }}</td>
                <td>{{ t.name }}</td>
                <td>{{ t.hours.real }}</td>
                <td>{{ t.hours.client }}</td>
                <td>{{ t.reportedHours || 0 }}</td>
                <td>
                  <a @click.stop :href="`/reports?type=all&task=${t._id}`"
                    ><u>Виж отчетите</u></a
                  >
                </td>
                <td>
                  <Slider
                    @change="changeStatus($event, t)"
                    :checked="t.active"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Table v-if="currentView === 'users'">
            <thead>
              <tr>
                <th>ПОТРЕБИТЕЛСКО ИМЕ</th>
                <th>ИМЕ</th>
                <th>ИМЕ EN</th>
                <th>ТЕЛЕФОН</th>
                <th>EMAIL</th>
                <th>РОЛЯ</th>
                <th>СТАТУС</th>
              </tr>
            </thead>
            <tbody v-if="loading || !users.length">
              <tr>
                <td colspan="15" class="center">
                  <em>{{ loading ? "Зареждане..." : "Няма резултати" }}</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="u in users"
                :key="`users-${u._id}`"
                @click="(userModal = true), (userId = u._id)"
              >
                <td>{{ u.username }}</td>
                <td>{{ u.name }}</td>
                <td>{{ u.nameEn }}</td>
                <td>{{ u.phone }}</td>
                <td>{{ u.email }}</td>
                <td>{{ u.role }}</td>
                <td>
                  <Slider
                    @change="changeStatus($event, u)"
                    :checked="u.active"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Pagination
            v-if="currentView === 'clients'"
            :total="totalClients"
            :size="size"
          />
          <!-- <Pagination
            v-if="contractsOpen"
            :total="totalContracts"
            :size="size"
          /> -->
          <Pagination
            v-if="currentView === 'users'"
            :total="totalUsers"
            :size="size"
          />
          <Pagination
            v-if="currentView === 'tasks'"
            :total="totalTasks"
            :size="size"
          />
        </div>
        <ClientModal
          v-if="clientModal"
          @close="closeModal"
          :clientId="clientId"
        />
        <ContractModal
          v-if="contractModal"
          @close="closeModal"
          :contractId="contractId"
        />
        <UserModal
          v-if="userModal"
          @close="closeModal"
          :modalType="modalType"
          :userId="userId"
        />
        <TaskModal
          v-if="taskModal"
          @close="closeModal"
          :modalType="modalType"
          :id="taskId"
        />
      </main>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/Input";
import Dropdown from "@/components/Dropdown";
import NavToggle from "@/components/NavToggle";
import Table from "@/components/Table";
import Pagination from "@/components/Pagination";
import Slider from "@/components/Slider";
import ClientModal from "@/components/nomenclatureModals/ClientModal";
import ContractModal from "@/components/nomenclatureModals/ContractModal";
import UserModal from "@/components/nomenclatureModals/UserModal";
import TaskModal from "@/components/nomenclatureModals/TaskModal";
export default {
  components: {
    Input,
    Dropdown,
    NavToggle,
    Table,
    Pagination,
    Slider,
    ClientModal,
    ContractModal,
    UserModal,
    TaskModal,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      loading: false,
      onlyActive: true,
      totalClients: 0,
      totalContracts: 0,
      totalUsers: 0,
      totalTasks: 0,
      size: 20,
      search: "",
      clients: [],
      contracts: [],
      users: [],
      tasks: [],

      contractTypes: [
        { bg: "Поддръжка", en: "maintenance" },
        { bg: "Разработка", en: "development" },
        { bg: "Запитване", en: "question" },
      ],
      contractType: "",
      modalType: "",
      clientModal: false,
      contractModal: false,
      userModal: false,
      taskModal: false,

      currentView: "clients",
    };
  },
  watch: {
    contractType() {
      this.getContracts();
    },
    $route: [
      {
        handler: "getUsers",
        immediate: true,
      },
      {
        handler: "getTasks",
        immediate: true,
      },
      {
        handler: "getClients",
        immediate: true,
      },
      {
        handler: "getContracts",
        immediate: true,
      },
    ],
    currentView() {
      if (this.$route.fullPath.includes("page")) {
        const query = { ...this.$route.query };
        delete query.page;
        this.$router.replace({ query: query });
      }

      if (this.currentView === "clients") this.getClients();
      if (this.currentView === "contracts") this.getContracts();
      if (this.currentView === "users") this.getUsers();
      if (this.currentView === "tasks") this.getTasks();
    },
  },
  methods: {
    setActiveState(state) {
      this.onlyActive = state;
      this.$router.push(`/nomenclature?active=${state}`);
    },
    exportMails() {
      let mails = [];
      let endMails = [];

      this.$apiService.get("/contracts").then((res) => {
        for (let c of res.data.results) {
          mails.push(c.mailTo.split(", "));
        }

        for (let m of mails) {
          let unique = m.filter((item, i, ar) => ar.indexOf(item) === i);

          for (let u of unique) {
            if (!endMails.includes(u)) {
              endMails.push(u);
            }
          }
        }
        endMails = endMails.join(", ");

        let a = document.createElement("a");
        a.href =
          "data:application/octet-stream," + encodeURIComponent(endMails);
        a.download = "clientMails.txt";
        a.click();
      });
    },
    // might be changed
    changeStatus(state, u) {
      this.$apiService
        .put(`/users/changeStatus`, { _id: u._id, active: state })
        .then(() => {
          if (u._id === this.currentUser._id) {
            this.$store
              .dispatch("logout")
              .then(() => this.$router.push("/login"));
          } else {
            this.getUsers();
          }
        });
    },
    cantBeActive(c) {
      if (c.toDate && new Date(c.toDate) < new Date()) return true;
      else false;
    },
    statusChangeContract(state, c) {
      this.$apiService
        .put(`/contracts/changeActive`, {
          _id: c._id,
          active: state,
        })
        .then(() => {
          this.getContracts();
        });
    },
    closeModal(update) {
      this.clientModal = false;
      this.userModal = false;
      this.contractModal = false;
      this.taskModal = false;
      if (update) {
        this.getClients();
        this.getContracts();
        this.getUsers();
        this.getTasks();
      }
    },
    resetPage(totalPages) {
      const query = { ...this.$route.query };
      if (query.page > totalPages) {
        delete query.page;
        query.active = true;
        this.$router.replace({ query: query }).catch(() => {});
      }
    },
    getClients() {
      this.loading = true;
      this.clients = [];
      let url = `/clients?size=${this.size}&page=${
        this.$route.query.page || 1
      }`;

      if (this.search) {
        url += `&search=${this.search}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.clients = res.data.results;
          this.totalClients = res.data.total;
          if (res.data.totalPages && this.currentView === "clients") {
            this.resetPage(res.data.totalPages);
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    getTasks() {
      this.loading = true;
      this.tasks = [];
      let url = `/tasks?size=${this.size}&page=${this.$route.query.page || 1}`;

      if (this.search) {
        url += `&search=${this.search}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.tasks = res.data.results;
          this.totalTasks = res.data.total;
          if (res.data.totalPages && this.currentView === "tasks") {
            this.resetPage(res.data.totalPages);
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    getContracts() {
      this.loading = true;
      this.contracts = [];

      // has no pagination bc of the alphabetical order
      let url = `/contracts?type=${
        this.contractType.en ? this.contractType.en : ""
      }`;

      if (this.search) {
        url += `&search=${this.search}`;
      }
      if (this.$route.query.active) {
        url += `&active=${this.$route.query.active}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.contracts = res.data.results;
          this.totalContracts = res.data.total;
          if (res.data.totalPages && this.currentView === "contracts") {
            this.resetPage(res.data.totalPages);
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    getUsers() {
      this.loading = true;
      this.users = [];
      let url = `/users?size=${this.size}&page=${this.$route.query.page || 1}`;

      if (this.search) {
        url += `&search=${this.search}`;
      }
      if (this.$route.query.active) {
        url += `&active=${this.$route.query.active}`;
      }
      this.$apiService
        .get(url)
        .then((res) => {
          this.users = res.data.results;
          this.totalUsers = res.data.total;
          if (res.data.totalPages && this.currentView === "users") {
            this.resetPage(res.data.totalPages);
          }
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
  },
  mounted() {
    if (this.$route.query.active === undefined) {
      this.setActiveState(true);
    } else {
      this.onlyActive = this.$route.query.active === "true" ? true : false;
    }
  },
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"report-hours",class:{
    'report-hours--open': _vm.isDropdownOpen,
  }},[_c('div',{staticClass:"hours-wrapper",class:{
      'hours-wrapper--invalid': _vm.invalid,
      'hours-wrapper--open': _vm.isDropdownOpen,
      'hours-wrapper--up': _vm.up,
    }},[_c('input',{attrs:{"type":'text',"readonly":""},domProps:{"value":_vm.isDropdownOpen
          ? 'Изберете'
          : _vm.getValue
          ? _vm.getValue
          : _vm.reportMainTime
          ? 'Изработено време'
          : 'Часове'},on:{"click":function($event){_vm.isDropdownOpen = !_vm.isDropdownOpen},"focus":function($event){return _vm.$emit('focus')}}}),_c('img',{staticClass:"icons",attrs:{"src":`${require(`@/assets/icons/dd.svg`)}`,"alt":"icon"}}),(_vm.isDropdownOpen)?_c('div',{staticClass:"options-wrapper"},[_c('section',{staticClass:"section-wrapper"},[_c('span',[_vm._v("ЧАСОВЕ")]),_c('div',{staticClass:"option-box"},_vm._l((_vm.hours),function(h){return _c('p',{key:`hour-${h}`,staticClass:"option",class:{
              selected:
                h === (typeof _vm.value === 'object' ? _vm.value.hours : _vm.value),
            },on:{"click":function($event){return _vm.setHours(h)}}},[_vm._v(" "+_vm._s(h)+" ")])}),0)]),_c('section',{staticClass:"section-wrapper"},[(_vm.report.contract.contractType !== 'question' && !_vm.noMinutes)?_c('div',{staticClass:"minutes"},[_c('span',[_vm._v("МИНУТИ")]),_c('div',{staticClass:"option-box"},_vm._l((_vm.minutes),function(m){return _c('p',{key:`minute-${m.name}`,staticClass:"option",class:{ selected: m.value === _vm.value.minutes },on:{"click":function($event){return _vm.setMinutes(m.value)}}},[_vm._v(" "+_vm._s(m.name)+" ")])}),0)]):_vm._e(),(_vm.reportMainTime)?_c('div',{staticClass:"time"},[_c('Checkbox',{attrs:{"label":"НРВ","disabled":_vm.reportTime === 'time',"value":_vm.reportTime === 'time'},on:{"input":function($event){return _vm.setReportTime('time')}}}),_c('Checkbox',{attrs:{"label":"ИРВ 1","disabled":_vm.reportTime === 'overtime',"value":_vm.reportTime === 'overtime'},on:{"input":function($event){return _vm.setReportTime('overtime')}}}),_c('Checkbox',{attrs:{"label":"ИРВ 2","disabled":_vm.reportTime === 'overtimeHolidays',"value":_vm.reportTime === 'overtimeHolidays'},on:{"input":function($event){return _vm.setReportTime('overtimeHolidays')}}})],1):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--disabled': disabled,
    }"
  >
    <img
      v-if="icon"
      class="icons"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="icon"
    />
    <input
      v-if="!textarea"
      :autocomplete="autocomplete"
      :value="value"
      :step="step ? step : 0"
      :type="type || 'text'"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxlength ? maxlength : 100"
      :class="{ 'input-right-placeholder': rightPlaceholder }"
      oninput="validity.valid || (value='')"
      @click="$emit('click')"
      @keypress.enter="$emit('enter')"
      @input="$emit('input', $event.target.value)"
    />
    <textarea
      v-else
      :autocomplete="autocomplete"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxlength ? maxlength : 100"
      @click="$emit('click')"
      @keypress.enter="$emit('enter')"
      @input="$emit('input', $event.target.value), resize($event)"
      @focus="resize($event)"
      ref="ta"
    />

    <p v-if="!icon" class="right--placeholder">{{ rightPlaceholder }}</p>

    <div class="slot">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "type",
    "placeholder",
    "icon",
    "disabled",
    "autocomplete",
    "maxlength",
    "invalid",
    "rightPlaceholder",
    "textarea",
    "step",
  ],
  methods: {
    resize(e, m) {
      let textarea = m ? e : e.target;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 10}px`;
    },
  },
  mounted() {
    if (this.$refs.ta) this.resize(this.$refs.ta, true);
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.input-wrapper {
  position: relative;
  width: 100%;

  .slot {
    align-items: center;
    display: flex;
    gap: 10px;
    position: absolute;
    top: 25px;
    right: 10px;
    z-index: 1;
    @include transform(translateY(-50%) translateX(0%));
  }

  &.input-wrapper--invalid {
    input,
    textarea,
    .input-right-placeholder {
      border: 1px solid $mainError !important;
    }
  }
  &.input-wrapper--disabled {
    input {
      &:disabled {
        border: 1px solid rgba($color: #e4e8ec, $alpha: 0.2);
        background-color: rgba($color: #e4e8ec, $alpha: 0.2);
      }
    }
  }
  .icons {
    left: 27.5px;
    position: absolute;
    top: 20px;
    @include transform(translateY(-50%) translateX(-50%));
  }
  &.search {
    input {
      box-shadow: 0px 6px 20px #0000004d;
      background: #ffffff;
      border: 1px solid #dfdfdf;
    }
  }
  input,
  textarea {
    // background: #e4e8ec;
    background: #ffffff;
    // border: 1px solid #e4e8ec;
    border: 1px solid #cdd9e5;
    border-radius: $borderRadiusInput;
    color: #000000e4;
    font: italic normal 700 14px/17px Google Sans;
    height: 40px;
    outline: none;
    padding: 0 20px 0 50px;
    width: 100%;
    @include tr();

    &.input-right-placeholder {
      background-color: $input;
      border: 1px solid #707070;
      border-radius: $borderRadius;
      padding: 0 20px 0 10px;
      height: 30px;
    }
  }
  textarea {
    padding: 10px 30% 0 50px;
    overflow: hidden;
    resize: none;
    min-height: 50px;
  }
  .right--placeholder {
    color: #000000e4;
    position: absolute;
    right: 0;
    font-weight: 600;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));
  }
}
@media all and (max-width:$s) {
  .input-wrapper {
    textarea {
      padding: 45px 0 5px 15px;
    }
  }
}
</style>
<template>
  <Modal @close="close()">
    <main v-click-outside="close">
      <div class="text">
        <h1 v-if="type == 'maintenance' && all">
          Обороти клиенти - Поддръжка/Запитване
        </h1>
        <h1 v-if="type == 'development' && all">
          Обороти клиенти - Разработка
        </h1>
        <h1 v-if="!all">{{ this.data.client.name }}</h1>
        <p class="download" @click="download">
          <img
            src="@/assets/icons/download.svg"
            alt="download-img"
            class="icons"
          />свали изображение
        </p>
      </div>
      <canvas id="myChart" width="400" height="400"></canvas>
    </main>
  </Modal>
</template>

<script>
import { Chart, registerables } from "chart.js";
import Modal from "@/components/Modal";
import vClickOutside from "v-click-outside";
export default {
  name: "Chart",
  props: ["type", "data", "all"],
  components: { Modal },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  created() {
    Chart.register(...registerables);
  },
  data() {
    return {
      myChart: "",
    };
  },
  methods: {
    download() {
      const image = document.createElement("a");
      image.href = this.myChart.toBase64Image();
      image.download = `${
        this.all ? "Обороти клиенти" : this.data.client.name
      }-Chart.png`;
      image.click();
    },
    close() {
      this.$emit("close", true);
    },
  },
  mounted() {
    const context = document.getElementById("myChart").getContext("2d");

    const chartOptions = {
      animation: {
        duration: 2000,
      },
      legend: {
        position: "bottom",
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    if (this.type === "maintenance" && this.all) {
      const name = [];
      const hours = [];
      const additionalHours = [];
      const totalHours = [];

      for (let d of this.data) {
        additionalHours.push(
          0 > d.hoursPod.hours - d.monthlyHours
            ? (d.hoursPod.hours - d.monthlyHours) * -1
            : 0
        );
        name.push(d.contractName);
        hours.push(d.hoursPod.hours ? d.hoursPod.hours : d.monthlyHours);
        totalHours.push(d.monthlyHours);
      }

      const hoursData = {
        label: "Включени часове",
        data: hours,
        backgroundColor: "#18a0fb",
      };

      const additionalHoursData = {
        label: "Допълнителни часове",
        data: additionalHours,
        backgroundColor: "#a84141",
      };

      const totalData = {
        label: "Общо",
        data: totalHours,
        backgroundColor: "black",
      };

      const chartData = {
        labels: name,
        datasets: [hoursData, additionalHoursData, totalData],
      };

      this.myChart = new Chart(context, {
        type: "bar",
        data: chartData,
        options: chartOptions,
      });
    }

    if (this.type === "development" && this.all) {
      const name = [];
      const budjet = [];
      const usedHours = [];
      const difference = [];

      for (let d of this.data) {
        name.push(d.contractName);
        budjet.push(d.hoursRaz.budjet);
        usedHours.push(d.monthlyHours);
        difference.push(d.hoursRaz.budjet - d.monthlyHours);
      }

      const hoursData = {
        label: "Бюджет",
        data: budjet,
        backgroundColor: "#18a0fb",
      };

      const additionalHoursData = {
        label: "Реални часове",
        data: usedHours,
        backgroundColor: "orange",
      };

      const totalData = {
        label: "Разлика",
        data: difference,
        backgroundColor: "grey",
      };
      const chartData = {
        labels: name,
        datasets: [hoursData, additionalHoursData, totalData],
      };

      this.myChart = new Chart(context, {
        type: "bar",
        data: chartData,
        options: chartOptions,
      });
    }

    if (this.type === "maintenance" && !this.all) {
      this.myChart = new Chart(context, {
        type: "bar",
        data: {
          labels: ["включени часове", "допълнителни часове", "общо"],
          datasets: [
            {
              label: `обороти ${this.data.contractName} - Поддръжка/Запитване`,
              data: [
                this.data.hoursPod.hours
                  ? this.data.hoursPod.hours
                  : this.data.monthlyHours,
                this.data.hoursPod.hours
                  ? 0 > this.data.hoursPod.hours - this.data.monthlyHours
                    ? (this.data.hoursPod.hours - this.data.monthlyHours) * -1
                    : 0
                  : 0,
                this.data.monthlyHours,
              ],
              backgroundColor: ["#18a0fb", "#a84141", "black"],
            },
          ],
        },
        options: chartOptions,
      });
    }
    if (this.type === "development" && !this.all) {
      this.myChart = new Chart(context, {
        type: "bar",
        data: {
          labels: ["бюджет часове", "реални часове", "разлика"],
          datasets: [
            {
              label: `обороти ${this.data.contractName} - разработка`,
              data: [
                this.data.hoursRaz.budjet,
                this.data.monthlyHours,
                this.data.hoursRaz.budjet - this.data.monthlyHours,
              ],
              backgroundColor: ["#18a0fb", "orange", "grey"],
            },
          ],
        },
        options: chartOptions,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.modal-wrapper {
  main {
    max-height: 70vh;
    padding-bottom: 60px;

    .text {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;

      h1 {
        font: normal normal bold 20px/20px Google Sans;
        max-width: 70%;
        text-align: left;
      }
      .download {
        align-items: center;
        display: flex;
        cursor: pointer;
        font: normal normal bold 12px/14px Google Sans;
        border-radius: 13px;
        padding: 8px;
        @include tr();
        @include hover();
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
@media all and (max-width:$s) {
  .modal-wrapper {
    main {
      .text {
        padding: 10px;

        h1 {
          font-size: 13px;
        }
      }
    }
  }
}
</style>

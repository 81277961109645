<template>
<main>
  <header>
    <div class="logo">
      <router-link to="/reports">
        <img src="../assets/header/logo_dark.svg" alt="logo" class="white-logo"/>
        <img src="../assets/header/logo_light.svg" alt="logo" class="black-logo"/>
      </router-link>
    </div>
    <div class="links">
      <router-link to="/reports">
        <img
          src="../assets/header/reports.svg"
          alt="reports"
          class="route-img icons"
        />
        <img
          src="../assets/header/reports-active.svg"
          alt="reports"
          class="route-img-active icons"
        />
        <img
          src="../assets/header/reports-hover.svg"
          alt="reports"
          class="route-img-hover"
        />
        <p>ОТЧЕТИ</p>
      </router-link>
      <router-link 
        to="/inquiry"
        :class="{ disabled:currentUser.role!=='administrator' }"
      >
        <img
          src="../assets/header/inquiry.svg"
          alt="inquiry"
          class="route-img icons"
        />
        <img
          src="../assets/header/inquiry-active.svg"
          alt="inquiry"
          class="route-img-active icons"
        />
        <img
          src="../assets/header/inquiry-hover.svg"
          alt="inquiry"
          class="route-img-hover"
        />
        <p>СПРАВКИ</p>
      </router-link>
      <router-link
        to="/nomenclature"
        :class="{
          disabled:currentUser.role!=='administrator'
        }"
      >
        <img
          src="../assets/header/nomenclature.svg"
          alt="nomenclature"
          class="route-img icons"
        />
        <img
          src="../assets/header/nomenclature-active.svg"
          alt="nomenclature"
          class="route-img-active icons"
        />
        <img
          src="../assets/header/nomenclature-hover.svg"
          alt="inquiry"
          class="route-img-hover"
        />
        <p>НОМЕНКЛАТУРИ</p>
      </router-link>
      <router-link 
        to="/issues"
        :class="{ disabled: currentUser.role !== 'administrator' }"
      >
        <img
          src="../assets/header/issues.svg"
          alt="issues"
          class="route-img icons"
        />
        <img
          src="../assets/header/issues-active.svg"
          alt="issues"
          class="route-img-active icons"
        />
        <img
          src="../assets/header/issues-hover.svg"
          alt="issues"
          class="route-img-hover icons"
        />
        <p>СИГНАЛИ</p>
      </router-link>
    </div>

    <div class="user-exit">
      <img
        @click="
          (userModalOpen = true),
            (userId = currentUser._id),
            (modalType = 'previewFromHeader')
        "
        src="@/assets/icons/user-username.svg"
        alt="userIcon"
        class="userIcon icons"
      />
      <p
        @click="
          (userModalOpen = true),
            (userId = currentUser._id),
            (modalType = 'previewFromHeader')
        "
      >
        {{ currentUser.name }}
      </p>
      <span>
      <img @click="logout" src="../assets/header/exit.svg" alt="exit" class="icons exit-img"/>
      <img @click="logout" src="../assets/header/exit-hover.svg" alt="exit" class="exit-hover"/>
      <img @click="logout" src="../assets/header/exit-hover-darkMode.svg" alt="exit" class="exit-hover-darkMode"/>
      </span>
    </div>
  </header>
    <UserModal
      v-if="userModalOpen"
      @close="userModalOpen = false"
      :modalType="modalType" 
      :userId="userId"
    />
</main>

</template>

<script>
import { mapGetters } from "vuex";
import UserModal from "@/components/nomenclatureModals/UserModal";
export default {
  components: {
    UserModal,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser"]),
  },
  data() {
    return {
      userModalOpen: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => this.$router.push("/login"));
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 99px #00000029;
  display: flex;
  justify-content: space-between;
  height: $header;
  position: fixed;
  padding: 0 $sides 20px $sides;
  width: 100%;
  z-index: 5;
  @include noselect();

  div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .logo {
    @include hover();
  }
  .links {
    a {
      display: flex;
      justify-content: center;
      padding: 5px;
      position: relative;
      padding: 10px 25px;
      margin: 0 10px;

      img {
        margin-right: 5px;
        max-height: 17px;
      }
      .route-img-active,
      .route-img-hover {
        display: none;
      }
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      &.router-link-active {
        &:after {
          content: "";
          background: #000000;
          bottom: 0;
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
        }
        .route-img {
          display: none;
        }
        .route-img-active {
          display: block;
        }
      }

      &:hover:not(.router-link-active) {
        color: $secondMainColor;
        &:after {
          content: "";
          background: $secondMainColor !important;
          bottom: 0;
          display: block;
          position: absolute;
          height: 2px;
          width: 100%;
        }
        .route-img-hover {
          display: block;
        }
        .route-img-active,
        .route-img {
          display: none;
        }
      }
    }
  }
  .user-exit {
    display: flex;
    .userIcon {
      display: none;
    }
    p {
      font-weight: 600;
      cursor: pointer;
      @include transition($transition);
      &:hover {
        color: #be3600;
      }
    }
    span {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: 5px;

      .exit-hover,
      .exit-hover-darkMode {
        display: none;
      }

      &:hover {
        .exit-hover {
          display: block;
        }
        .exit-img {
          display: none;
        }
      }
    }
  }
}
@media all and (max-width:$s) {
  header {
    padding: 0 10px;
    position: fixed;
    width: 100%;
    z-index: 1;
    .logo {
      img {
        width: 60px;
      }
    }
    .links {
      a {
        padding: 10px 10px;
        margin: 0 5px;
        img {
          margin: 0;
        }
        &:hover {
          &:after {
            background: white !important;
          }
          .route-img-hover {
            display: none;
          }
          .route-img-active {
            display: block;
          }
        }
      }
      p {
        display: none;
      }
    }
    .user-exit {
      .userIcon {
        display: block;
      }
      p {
        display: none;
      }
    }
  }
}
</style>

<template>
  <div
    class="button"
    :class="{ 'button--disabled': disabled }"
    @click="disabled ? null  :  $emit('click') "
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: ["disabled"],
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $button;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: $px15;
  font-weight: 700;
  height: 40px;
  outline: none;
  text-align: center;
  width: 100%;
  @include tr();
  @include noselect();

  &:not(.button--disabled) {
    @include hover();
  }
  &.button--disabled {
    background-color: rgba($color: $button, $alpha: 0.2);
    cursor: default;
  }

  &.red {
    background-color: $mainError;
  }
  &.grey {
    background-color: #6a8495;
  }
}
</style>
<template>
  <Modal @close="close()">
    <form>
      <div class="form-content">
        <header>
          <div></div>
          <Button @click="close" class="close"
            ><img class="icons" src="@/assets/icons/close.svg" alt="close"
          /></Button>
        </header>
        <hr class="break" />

        <main class="inputs--wrapper">
          <section class="form-inputs">
            <div class="row">
              <Input
                placeholder="Юридическо име"
                type="text"
                icon="client-name.svg"
                :invalid="invalid.name"
                @input="invalid.name = false"
                v-model="client.name"
              />
            </div>
            <div class="row">
              <Input
                placeholder="Кратко име"
                type="text"
                icon="client-shortname.svg"
                :invalid="invalid.shortName"
                @input="invalid.shortName = false"
                v-model="client.shortName"
              />
            </div>
            <div class="row">
              <InputButtons icon="language.svg">
                <Button
                  :class="{ selected: client.language === 'BG' }"
                  @click="(client.language = 'BG'), (english = false)"
                  >БЪЛГАРСКИ</Button
                >
                <Button
                  :class="{ selected: client.language === 'EN' }"
                  @click="(client.language = 'EN'), (english = true)"
                  >АНГЛИЙСКИ</Button
                >
              </InputButtons>
            </div>

            <div class="row">
              <Input
                v-if="english || client.language === 'EN'"
                placeholder="Company name"
                type="text"
                icon="client-name.svg"
                :invalid="invalid.nameEn"
                @input="invalid.nameEn = false"
                v-model="client.nameEn"
              />
            </div>

            <div class="row">
              <Input
                v-if="english || client.language === 'EN'"
                placeholder="Short Name"
                type="text"
                icon="client-shortname.svg"
                :invalid="invalid.shortNameEn"
                @input="invalid.shortNameEn = false"
                v-model="client.shortNameEn"
              />
            </div>
          </section>
        </main>
        <p class="row error">
          <span v-if="error">{{ error }}</span>
        </p>
      </div>
      <Button @click="save">ЗАПИШИ</Button>
    </form>
  </Modal>
</template>

<script>
import Input from "@/components/Input";
import InputButtons from "@/components/InputButtons";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
export default {
  props: ["clientId"],
  components: {
    Input,
    InputButtons,
    Button,
    Modal,
  },

  data() {
    return {
      client: {
        name: "",
        nameEn: "",
        shortName: "",
        shortNameEn: "",
        language: "BG",
      },
      english: false,
      error: "",
      loading: false,
      invalid: {
        invalid: false,
      },
    };
  },
  watch: {
    $route: {
      handler: "getClient",
      immediate: true,
    },
  },
  methods: {
    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.client.name) {
        this.invalid.name = true;
        this.error = "Не сте въвели Име";
        return false;
      } else if (!this.client.shortName) {
        this.invalid.shortName = true;
        this.error = "Не сте въвели Кратко Име";
        return false;
      } else if (!this.client.language) {
        this.error = "Не сте въвели Език";
        return false;
      } else if (!this.client.nameEn && this.english) {
        this.invalid.nameEn = true;
        this.error = "Не сте въвели Company name";
        return false;
      } else if (!this.client.shortNameEn && this.english) {
        this.invalid.shortNameEn = true;
        this.error = "Не сте въвели Short Name";
        return false;
      }
      return true;
    },
    save() {
      this.error = "";
      if (this.isValid()) {
        if (this.clientId) {
          this.$apiService
            .put("/clients", this.client)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
        if (!this.clientId) {
          this.$apiService
            .post("/clients", this.client)
            .then(() => {
              this.close(true);
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        }
      }
    },
    getClient() {
      if (this.clientId) {
        this.$apiService.get(`/clients/${this.clientId}`).then((res) => {
          this.client = res.data;
        });
      }
    },
  },
};
</script>
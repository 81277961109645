<template>
  <div
    @click="$emit('click')"
    class="nav-toggle"
    :class="{ 'nav-toggle--active': active }"
  >
    <img
      v-if="icon"
      :src="`${require(`@/assets/icons/${icon}`)}`"
      alt="toggle icon"
      class="icons"
    />
    <p>{{ name }}</p>
  </div>
</template>

<script>
export default {
  props: ["name", "active", "icon"],
};
</script>

<style lang="scss" scoped>
.nav-toggle {
  align-items: center;
  background: hsl(0, 0%, 100%, 0.4);
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  margin-right: 5px;
  padding: 5px 15px;
  height: 33px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &.nav-toggle--active {
    background: #ffffff 0% 0% no-repeat padding-box;
    filter: drop-shadow(0px -3px 5px rgba(0, 0, 0, 0.151));
  }

  img {
    margin-right: 5px;
    width: 16.5px;
    object-fit: scale-down;
  }
}
</style>
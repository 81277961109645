import { ApiService, AuthService } from "@/common/api.service";

const state = {
  errors: null,
  user: {},
  isAuthenticated: undefined,
};

const getters = {
  authErrors: (state) => state.errors,
  currentUser: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
};

const actions = {
  checkAuth(context) {
    return new Promise((res) => {
      if (localStorage.getItem("token")) {
        AuthService.setAuthHeader();
        ApiService.get("/auth/validation")
          .then((response) => {
            if (response.data.user) {
              context.commit("setAuth", response.data);
            } else {
              context.commit("purgeAuth");
            }
            res();
          })
          .catch(() => {
            context.commit("purgeAuth");
            res();
          });
      } else {
        context.commit("purgeAuth");
        res();
      }
    });
  },
  login(context, user) {
    return new Promise((res, rej) => {
      ApiService.post("/auth/login", user)
        .then((response) => {
          context.commit("setAuth", response.data);
          AuthService.setAuthHeader();
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  logout(context) {
    context.commit("purgeAuth");
  },
};

const mutations = {
  setAuth(state, data) {
    localStorage.setItem("token", data.token);
    state.user = data.user;
    state.isAuthenticated = true;
    state.errors = null;
  },
  purgeAuth(state) {
    localStorage.removeItem("token");
    state.user = {};
    state.isAuthenticated = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

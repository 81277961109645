<template>
  <Modal @close="close()">
    <form>
      <div class="form-content">
        <header>
          <section>
            <img
              class="icons"
              src="@/assets/icons/contract-access-modal.svg"
              alt="group reports"
            />
            <h1>ДОСТЪП ДО ДОГОВОРИ НА {{ user.name }}</h1>
          </section>
          <Button @click="close()" class="close"
            ><img class="icons" src="@/assets/icons/close.svg" alt="close"
          /></Button>
        </header>
        <hr class="break" />

        <div class="row row-flex">
          <div class="text">
            <p>Дай достъп до всички Договори за Поддръжка</p>
          </div>

          <Slider
            @change="toggleAll($event, user._id, 'maintenance')"
            :checked="inAllMain"
          />
        </div>
        <div class="row row-flex">
          <div class="text">
            <p>Дай достъп до всички Договори за Запитване</p>
          </div>

          <Slider
            @change="toggleAll($event, user._id, 'question')"
            :checked="inAllQue"
          />
        </div>
        <div class="row row-flex">
          <div class="text">
            <p>Дай достъп до всички Договори за Разработка</p>
          </div>

          <Slider
            @change="toggleAll($event, user._id, 'development')"
            :checked="inAllDev"
          />
        </div>
        <hr class="break" />
        <main class="inputs--wrapper contract-access">
          <Table>
            <thead>
              <tr>
                <th>КЛИЕНТ</th>
                <th>ДОГОВОР</th>
                <th>ДОСТЪП</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="c in contracts" :key="`contract-${c._id}`">
                <td>{{ c.client.name }}</td>
                <td>{{ c.contractName }}</td>
                <td>
                  <Slider
                    @change="toggleAccess($event, c, user._id)"
                    :checked="c.access.includes(user._id)"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </main>
        <p class="row error">
          <span v-if="error">{{ error }}</span>
        </p>
      </div>

      <Button @click="save">ЗАПИШИ</Button>
    </form>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button";
import Slider from "@/components/Slider";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
export default {
  props: ["modalType", "user"],
  components: {
    Button,
    Modal,
    Slider,
    Table,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      error: "",
      contracts: [],
      inAllMain: false,
      inAllDev: false,
      inAllQue: false,
    };
  },
  watch: {
    $route: {
      handler: "getContracts",
      immediate: true,
    },
  },
  methods: {
    // CODE MIGHT CHANGE
    close(update) {
      this.$emit("close", update);
    },
    save() {
      this.contracts.map((contract) => {
        this.$apiService
          .put("/contracts", contract)
          .then(() => {
            this.close(true);
          })
          .catch((err) => {
            this.error = err.response.data.message;
          });
      });
    },
    checkAccess(type) {
      let size = 0;

      let filteredByType = this.contracts.filter(
        (c) => c.contractType === type
      );
      for (let c of filteredByType) {
        if (c.access.includes(this.user._id)) size++;
      }

      return size === filteredByType.length;
    },
    toggleAll(state, id, type) {
      this.contracts.map((c) => {
        if (c.contractType === type) {
          if (!c.access.includes(id) && state) {
            c.access.push(id);
          } else if (c.access.includes(id) && !state) {
            let remove = c.access.indexOf(id);
            c.access.splice(remove, 1);
          }
        }

        if (type === "maintenance") this.inAllMain = this.checkAccess(type);
        else if (type === "development") this.inAllDev = this.checkAccess(type);
        else if (type === "question") this.inAllQue = this.checkAccess(type);
      });
    },
    toggleAccess(state, contract, id) {
      if (state) {
        contract.access.push(id);
      } else {
        let remove = contract.access.indexOf(id);
        contract.access.splice(remove, 1);
      }
      let type = contract.contractType;
      if (type === "maintenance") this.inAllMain = this.checkAccess(type);
      else if (type === "development") this.inAllDev = this.checkAccess(type);
      else if (type === "question") this.inAllQue = this.checkAccess(type);
    },
    getContracts() {
      this.contracts = [];

      let url = `/contracts?active=true`;

      this.$apiService.get(url).then((res) => {
        this.contracts = res.data.results;
        this.inAllMain = this.checkAccess("maintenance");
        this.inAllDev = this.checkAccess("development");
        this.inAllQue = this.checkAccess("question");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/base.scss";
.table-wrapper {
  background: initial !important;
  box-shadow: initial !important;

  .table {
    th,
    td {
      border-bottom: none;
      height: 40px;
    }
    tbody {
      tr {
        cursor: default;
        &:hover {
          td {
            background-color: initial;
          }
        }
        &:nth-child(odd) {
          background: rgb(255, 255, 255, 0.5);
        }
      }
    }
  }
}
</style>

<template>
  <div class="active-swtich" :class="{ 'active-swtich--disabled': disabled }">
    <p
      v-if="on"
      @click="toggleSlider(null, true)"
      :class="{ 'p-active': inverted ? !checked : checked }"
    >
      {{ on }}
    </p>
    <label
      class="checkbox-slider"
      @click.stop
      :class="{
        'checkbox-slider--inverted': inverted,
        'checkbox-slider--disabled': disabled,
      }"
    >
      <input
        type="checkbox"
        :checked="inverted ? !checked : checked"
        :disabled="disabled"
        @change="toggleSlider"
      />
      <span class="slider round"></span>
    </label>
    <p
      v-if="off"
      :class="{ 'p-active': inverted ? checked : !checked }"
      @click="toggleSlider(null, false)"
    >
      {{ off }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["checked", "inverted", "disabled", "on", "off"],
  methods: {
    toggleSlider($event, v) {
      if (!this.disabled) {
        let value = this.inverted ? !v : v;
        if ($event)
          value = this.inverted
            ? !$event.target.checked
            : $event.target.checked;
        this.$emit("change", value);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.active-swtich {
  display: flex;
  gap: 10px;
  justify-content: center;

  &.active-swtich--disabled {
    p {
      cursor: not-allowed;
    }
  }
  p {
    cursor: pointer;
    font-weight: 100;
    opacity: 0.5;
    @include tr();
    &.p-active {
      font-weight: bold;
      opacity: 1;
    }
  }
  .checkbox-slider {
    display: inline-block;
    height: 20px;
    min-width: 40px;
    position: relative;
    width: 40px;

    &.checkbox-slider--disabled {
      .slider {
        cursor: not-allowed;
      }
    }
    &.checkbox-slider--inverted {
      input {
        &:checked + .slider {
          background-color: $mainError;
        }
      }
      .slider {
        background-color: $success;
      }
    }
    input {
      height: 0;
      opacity: 0;
      width: 0;
      @include hover();

      &:checked + .slider {
        background-color: $success;

        &::before {
          @include transform(translateX(19px));
        }
      }

      &:focus + .slider {
        box-shadow: 0 0 1px $success;
      }
    }

    .slider {
      background-color: $mainError;
      border-radius: 20px;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      @include transition($transition);
      @include hover();

      &::before {
        background-color: white;
        border-radius: 50%;
        bottom: 3px;
        content: "";
        height: 14px;
        left: 4px;
        position: absolute;
        @include transition($transition);
        width: 14px;
      }
    }
  }
}
</style>

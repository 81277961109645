<template>
  <div
    class="checkbox-container"
    :class="{
      'checkbox-container--disabled': disabled,
      'checkbox-container--selected': value,
    }"
    @click="onClick"
  >
    <span v-if="checked && notRadio" class="icon check"
      ><img src="@/assets/icons/checked.svg" alt=""
    /></span>
    <label class="checkbox-wrapper" v-if="!notRadio">
      <input
        type="checkbox"
        :disabled="disabled"
        :checked="value"
        @change="
          $emit('input', $event.target.checked),
            $emit($event.target.checked ? 'activated' : 'deactivated')
        "
      />
      <span class="checkmark"></span>
      <p v-if="label">{{ label }}</p>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
  props: ["checked", "value", "disabled", "label", "notRadio"],
};
</script>

<style lang="scss">
// @import "@/scss/base.scss";
.checkbox-container {
  align-items: flex-start;
  display: flex;

  &.checkbox-container--disabled {
    pointer-events: none;
  }
}
.checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 18px;
  padding-left: 5px;
  position: relative;
  @include noselect();

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  p {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 15px;
    white-space: nowrap;
  }
}

/* Create a custom checkbox */
.checkmark {
  border-radius: 50%;
  border: 2px solid #212121;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-wrapper .checkmark:after {
  background: #212121;
  border-radius: 50%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.checkbox-container--disabled {
  .checkbox-wrapper {
    cursor: default;
    input {
      cursor: default;
    }
  }
}
</style>
